import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./Departments.module.scss";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { UserContext } from "../../context/userContext";
import { DialogContext } from "../../context/dialogContext";
import { isAuthed } from "../../utils/authorization";
import {
  Container,
  Grid,
  Card,
  Typography,
  CardContent,
  Hidden,
  Button,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import Menu from "../../components/Menu/Menu";
import Loading from "../../components/Loading/Loading";
import EditPlanDialog from "./EditPlanDialog";
import PlanPill from "../../components/PlanPill/PlanPill";

const spacing = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: spacing * 2,
  margin: spacing,
  minWidth: 250,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  display: "flex",
  padding: spacing,
  overflow: "auto",
  background: "rgba(0, 0, 0, 0.12)",
  border: "1px solid rgba(0, 0, 0, 0.12)",
});

const Departments = ({ params }) => {
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { user } = useContext(UserContext);
  const { dialog: contextDialog, setDialog: setContextDialog } = useContext(DialogContext);
  const [editPlanDialog, setEditPlanDialog] = useState({
    open: false,
    departmentName: null,
    oneYear: {
      id: null,
      theme: "",
      useDepartmentTheme: false,
    },
    threeYear: {
      id: null,
      theme: "",
      useDepartmentTheme: false,
    },
    color: undefined,
    shortName: undefined,
  });
  const [confirmDelete, setConfirmDelete] = useState({ open: false, callback: null, text: "" });
  const [planOrderingEnabled, setPlanOrderingEnabled] = useState(false);
  const [mergedPlans, setMergedPlans] = useState([]);

  const { loading, data, refetch } = useQuery(GET_ORGANIZATION, {
    variables: { id: params.org, plan: user.departmentFilter.id },
  });

  const [deletePlan] = useMutation(DELETE_PLAN);
  const [reorderPlans] = useMutation(REORDER_PLANS);

  const handleEditPlanDialog = (departmentName, oneYear, threeYear, color, shortName) => () => {
    if (departmentName) {
      setEditPlanDialog({
        open: true,
        departmentName,
        oneYear: _.pick(oneYear, ["id", "useDepartmentTheme", "theme"]),
        threeYear: _.pick(threeYear, ["id", "useDepartmentTheme", "theme"]),
        color,
        shortName,
      });
    } else {
      setEditPlanDialog((prev) => ({ ...prev, open: false }));
    }

    requestFetch();
  };

  const handleOpenPlanDialogOneYear = () => {
    setContextDialog({ ...contextDialog, addPlanDialog: true });
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = _.cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEndPlan = async ({ draggableId, destination, source }) => {
    if (!destination) return;

    setMergedPlans(reorder(mergedPlans, source.index, destination.index));

    const ok = await reorderPlans({
      variables: {
        sharedPlanId: draggableId,
        destinationIndex: _.get(destination, "index", 0),
      },
    });

    if (ok.data.reorderPlans) {
      snack("Updated department order");
      requestFetch();
    }
  };

  const handleDeletePlan = (id) => async () => {
    snack("Deleting department...");
    const ok = await deletePlan({ variables: { id } });

    if (ok.data.deletePlan) {
      snack("Department deleted");
      requestFetch();
    }
  };

  const handleConfirmDelete =
    (open, callback = null, text = "") =>
    () => {
      setConfirmDelete({ open, callback, text });
    };

  useEffect(() => {
    refetch();
  }, [fetch, user.departmentFilter]);

  useEffect(() => {
    if (data) {
      const plansOrder = _.get(data, "organization.plansOrder", []);
      const orgPlans = _.get(data, "plans", []);
      const groupedPlans = _.groupBy(orgPlans, "sharedPlanId");

      let mergedPlansTemp = [];
      for (const spid in groupedPlans) {
        const groupArr = groupedPlans[spid];
        mergedPlansTemp.push({
          number: _.indexOf(plansOrder, spid),
          departmentName: _.get(_.first(groupArr), "departmentName"),
          oneYear: _.find(groupArr, ["category", "1 year"]),
          threeYear: _.find(groupArr, ["category", "3 year"]),
          color: _.last(groupArr).color,
          shortName: _.last(groupArr).shortName,
        });
      }

      mergedPlansTemp = _.sortBy(mergedPlansTemp, ["number"]);
      setMergedPlans(mergedPlansTemp);
    }
  }, [data]);

  if (loading) return <Loading />;

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5" className={styles.label}>
                  Departments
                </Typography>
              </Grid>

              <Grid item xs={12} sm={9}>
                {planOrderingEnabled ? (
                  <DragDropContext onDragEnd={handleDragEndPlan}>
                    <Droppable droppableId="droppablePlan" direction="horizontal">
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                          {mergedPlans &&
                            mergedPlans.map(({ departmentName, oneYear: { sharedPlanId }, color, shortName }, index) => (
                              <Draggable key={sharedPlanId} draggableId={sharedPlanId} index={index}>
                                {(provided, snapshot) => (
                                  <Card
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  >
                                    <CardContent className={styles.flexBetween}>
                                      <Typography variant="h5">{departmentName}</Typography>
                                      <PlanPill plan={{ departmentName, color, shortName }} />
                                    </CardContent>
                                  </Card>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <Grid container spacing={3}>
                    {mergedPlans &&
                      mergedPlans.map(({ departmentName, oneYear, threeYear, color, shortName }, idx) => {
                        return (
                          <Grid item sm={6} md={4} key={idx}>
                            <Card>
                              <CardContent className={styles.flexBetween}>
                                <Typography variant="h5">{departmentName}</Typography>
                                <div className={styles.flexBetween}>
                                  <PlanPill plan={{ departmentName, color, shortName }} />
                                  {isAuthed(user.user, "department facilitator") && (
                                    <Menu>
                                      <MenuItem onClick={handleEditPlanDialog(departmentName, oneYear, threeYear, color, shortName)}>
                                        Edit
                                      </MenuItem>
                                      {departmentName !== "Corporate" && (
                                        <MenuItem
                                          className={styles.delete}
                                          onClick={handleConfirmDelete(
                                            true,
                                            handleDeletePlan(oneYear.id),
                                            `Are you sure you want to delete ${departmentName}? Deleting this Department will reassign all users, rocks, and objectives associated with it to the Corporate department.`
                                          )}
                                        >
                                          Delete
                                        </MenuItem>
                                      )}
                                    </Menu>
                                  )}
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} sm={1}>
                <div className={styles.flexEnd} style={{ flexDirection: "column", gap: 8 }}>
                  <Button variant="outlined" onClick={handleOpenPlanDialogOneYear} disabled={!isAuthed(user.user, "company admin")} data-test='button-new-department'>
                    New Department
                  </Button>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={planOrderingEnabled}
                        onChange={(e) => {
                          setPlanOrderingEnabled(e.target.checked);
                        }}
                      />
                    }
                    label="ORDER MODE"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={confirmDelete.open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleConfirmDelete(false)();
          }
        }}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmDelete.text}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleConfirmDelete(false)}>Dismiss</Button>
          <Button
            onClick={() => {
              confirmDelete.callback();
              handleConfirmDelete(false)();
            }}
            className={styles.delete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <EditPlanDialog open={editPlanDialog.open} handleClose={handleEditPlanDialog()} initForm={editPlanDialog} />
    </>
  );
};

export default Departments;

const GET_ORGANIZATION = gql`
  query TheCore_GetOrg($id: ID!, $plan: ID) {
    organization(id: $id, plan: $plan) {
      id
      name
      fiscalYear
      profilePicture
      plansOrder
      core {
        purpose
        value
        competency
        market
        offering
        valueProposition
        differentiation
        process
      }
      createdAt
      bhag {
        goal
        targetDate
      }
      viewPreferences {
        name
        position
        show
      }
      notes {
        bhag {
          id
          date
        }
        purpose {
          id
          date
        }
        value {
          id
          date
        }
        competency {
          id
          date
        }
        market {
          id
          date
        }
        offering {
          id
          date
        }
        valueProposition {
          id
          date
        }
        process {
          id
          date
        }
        differentiation {
          id
          date
        }
      }
    }

    plans(organization: $id, closed: false) {
      id
      departmentName
      theme
      year
      useDepartmentTheme
      sharedPlanId
      category
      color
      shortName
      targetDate
    }

    projects(organization: $id) {
      id
      value
      plan {
        id
        departmentName
      }
      notes {
        id
        date
      }
    }
  }
`;

const DELETE_PLAN = gql`
  mutation TheCore_DeletePlan($id: ID!) {
    deletePlan(id: $id)
  }
`;

const REORDER_PLANS = gql`
  mutation TheCore_ReorderPlans($sharedPlanId: ID!, $destinationIndex: Int!) {
    reorderPlans(sharedPlanId: $sharedPlanId, destinationIndex: $destinationIndex)
  }
`;
