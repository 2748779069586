import React, { useContext } from "react";
import styles from "./Projects.module.scss";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Grid, Card, CardContent, Typography, IconButton } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiTrashCanOutline } from "@mdi/js";
import { isNil } from "lodash";
import { isAuthed } from "../../utils/authorization";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { UserContext } from "../../context/userContext";
import NotesButton from "../../components/Notes/NotesButton";
import _ from "lodash";

const Project = ({ project, handleConfirmDelete }) => {
  const { id, value, plan } = project;
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { user } = useContext(UserContext);
  const [deleteProject] = useMutation(DELETE_PROJECT);

  const handleDeleteProject = (id) => async () => {
    const ok = await deleteProject({
      variables: {
        id,
      },
    });
    if (ok.data.deleteProject) {
      snack(`Deleted ${value} project`);
      requestFetch();
    }
  };

  return (
    <Grid item xs={12} sm={4}>
      <Card>
        <CardContent>
          <Typography variant="subtitle1" gutterBottom className={styles.label}>
            {_.get(plan, "departmentName")}
          </Typography>

          <div className={styles.flexBetween}>
            <Typography variant="h5">{value}</Typography>
            <div className={styles.flex}>
              <NotesButton id={id} model="project" size={1.25} doc={project} />
              {isAuthed(user.user, "company admin") && (
                <IconButton
                  size="small"
                  onClick={
                    !isNil(handleConfirmDelete)
                      ? handleConfirmDelete(true, handleDeleteProject(id), `Are you sure you want to delete ${value} project?`)
                      : handleDeleteProject(id)
                  }
                >
                  <Icon path={mdiTrashCanOutline} size={1} color="#ef5350" />
                </IconButton>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Project;

const DELETE_PROJECT = gql`
  mutation ($id: ID!) {
    deleteProject(id: $id)
  }
`;
