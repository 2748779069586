import { IconButton, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import _ from "lodash";
import PlanPill from "../PlanPill/PlanPill";
import Icon from "@mdi/react";
import { mdiMenuDown } from "@mdi/js";

const EditablePlan = ({ plan, plans, handleUpdatePlan, disabled }) => {
  const [planAnchorEl, setPlanAnchorEl] = useState(null);

  const handleOpenPlanMenu = (event) => {
    setPlanAnchorEl(event.currentTarget);
  };
  const handleClosePlanMenu = () => {
    setPlanAnchorEl(null);
  };

  const handleSelectPlan = (newPlan) => () => {
    if (_.get(plan, "id") !== _.get(newPlan, "id")) {
      handleUpdatePlan(newPlan);
    }
    handleClosePlanMenu();
  };

  return (
    <>
      <PlanPill plan={plan} />
      <IconButton onClick={(e) => (!disabled ? handleOpenPlanMenu(e) : {})} size="small">
        <Icon path={mdiMenuDown} size={0.75} />
      </IconButton>
      <Menu anchorEl={planAnchorEl} open={Boolean(planAnchorEl)} onClose={handleClosePlanMenu} style={{ maxHeight: "300px" }}>
        <MenuItem value={null} onClick={handleSelectPlan(null)}>
          No Department
        </MenuItem>
        {!_.isEmpty(plans) &&
          plans.map((plan) => (
            <MenuItem key={plan.id} onClick={handleSelectPlan(plan)}>
              {plan.departmentName}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
};

export default EditablePlan;
