import React, { useEffect, useContext } from "react";
import styles from "./Scorecard.module.scss";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import { FetchContext } from "../../context/fetchContext";
import { Container, Grid, Typography } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import WeeklyTargets from "../../components/WeeklyTargets/WeeklyTargets";
import { formatAs, getCurrentQuarter, quarterDates, addToDate } from "../../utils/dates";
import SelectCorpYear from "../../components/SelectCorpYear/SelectCorpYear";
import useCorpPlan from "../../hooks/useCorpPlan";
import { GET_WEEKLY_TARGETS } from "../../utils/query";

const Scorecard = ({ user, params, org }) => {
  const { fetch } = useContext(FetchContext);
  const fiscalYear = _.get(org, "fiscalYear");
  const plansOrder = _.get(org, "plansOrder");
  const { corpForSelectedYear, setCorpForSelectedYear, corpPlans } = useCorpPlan({ orgId: params.org, fiscalYear });
  const { loading, data, refetch } = useQuery(GET_WEEKLY_TARGETS, {
    variables: {
      organization: params.org,
      sharedPlanId: user.departmentFilter.sharedPlanId,
      oneYearCorpPlan: corpForSelectedYear.id,
      includeFormulaReferences: true
    },
  });

  useEffect(() => {
    refetch();
  }, [fetch]);

  if (loading) return <Loading />;
  const weeklyTargets = _.get(data, "weeklyTargets", []);

  const quarter = getCurrentQuarter(fiscalYear);
  const [start, end] = quarterDates(addToDate(fiscalYear, { days: 1 }), quarter);
  const currentQuarter = { quarter, start, end };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        {currentQuarter && (
          <Grid item xs={12}>
            <Typography variant="h5">
              <span className={styles.label}>Current Quarter: </span>
              {currentQuarter.quarter} ({formatAs(currentQuarter.start, "MMM d")} - {formatAs(currentQuarter.end, "MMM d")})
            </Typography>
            <SelectCorpYear
              corporatePlans={corpPlans}
              fiscalYear={fiscalYear}
              corpForSelectedYear={corpForSelectedYear}
              setCorpForSelectedYear={setCorpForSelectedYear}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <WeeklyTargets
            weeklyTargets={weeklyTargets}
            planId={_.get(corpForSelectedYear, "id")}
            plansOrder={plansOrder}
            variables={{
              organization: params.org,
              sharedPlanId: user.departmentFilter.sharedPlanId,
              oneYearCorpPlan: corpForSelectedYear.id,
              includeFormulaReferences: true
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Scorecard;
