import React, { useContext, useState } from "react";
import styles from "./Settings.module.scss";
import _ from "lodash";
import { v4 } from "uuid";
import imageCompression from "browser-image-compression";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Container, Grid, Typography, Hidden, TextField, Avatar, Button, MenuItem, Switch, Select } from "@material-ui/core";
import firebase from "../../utils/firebase";
import { SnackbarContext } from "../../context/snackbarContext";
import { UserContext } from "../../context/userContext";
import Menu from "../../components/Menu/Menu";
import AvatarEditDialog from "../../components/AvatarEditDialog/AvatarEditDialog";
import useForm from "../../hooks/useForm";
import { removeTypenameDeep } from "../../utils/misc";
import { NOTIFICATION_FREQUENCY } from "../../utils/const";

const initErrorForm = {
  firstName: ["required"],
  lastName: ["required"],
  email: ["required"],
};

const Settings = ({
  user: {
    user: { id, name, email, position, profilePicture, notificationSettings },
  },
}) => {
  const { snack } = useContext(SnackbarContext);
  const { refreshUser } = useContext(UserContext);
  const [updateUser] = useMutation(UPDATE_USER);
  const [avatarEditDialog, setAvatarEditDialog] = useState(null);

  const notificationsWithoutTypename = removeTypenameDeep(notificationSettings);

  const initForm = {
    firstName: name.first,
    lastName: name.last,
    email,
    position,
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    notificationSettings: notificationsWithoutTypename,
  };

  const { form, formErrors, handleChange, handleToggleCheckBox, resetForm, validateForm } = useForm({ initForm, initErrorForm });

  const handleUpdateUser = async () => {
    if (!validateForm()) return;

    const { firstName, lastName, email, position } = form;
    const ok = await updateUser({ variables: { id, firstName, lastName, position, email } });

    if (ok.data.updateUser) {
      snack("Updated user information");
      refreshUser(id);
    }
  };

  const handleUpdateNotification = async () => {
    const { notificationSettings } = form;
    const ok = await updateUser({ variables: { id, notificationSettings } });

    if (ok.data.updateUser) {
      snack("Updated notification settings");
      refreshUser(id);
    } else {
      snack("Unable to update notification due to an error", "error");
    }
  };

  const handleUpdatePassword = () => {
    const { currentPassword, newPassword } = form;
    const user = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);

    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        user
          .updatePassword(newPassword)
          .then(() => {
            snack("Password update successfully");
            resetForm();
          })
          .catch((err) => snack(`Could not update password: ${err.message}`, "error"));
      })
      .catch((err) => snack(`Could not update password: ${err.message}`, "error"));
  };

  const handleUploadImage = async (e) => {
    if (!_.isEmpty(e.target.files) && e.target.validity.valid) {
      const file = e.target.files[0];
      setAvatarEditDialog(file);
    }
  };

  const handleSaveImage = (ref) => () => {
    snack("Uploading profile picture...");

    if (profilePicture) {
      deleteImage();
    }

    const canvas = ref.current.getImage();
    canvas.toBlob(async (blob) => {
      const filename = v4();
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`user-images/${filename}`);

      const compressionOptions = { maxSizeMB: 1 };
      const compressedFile = await imageCompression(blob, compressionOptions);

      fileRef.put(compressedFile).then(async () => {
        const url = await fileRef.getDownloadURL();
        const ok = await updateUser({ variables: { id, profilePicture: url } });

        if (ok.data.updateUser) {
          snack("Updated profile picture");
          refreshUser(id);
          setAvatarEditDialog(null);
        }
      });
    });
  };

  const handleDeleteImage = async () => {
    snack(`Deleting image...`);
    deleteImage();

    const ok = await updateUser({ variables: { id, profilePicture: "" } });

    if (ok.data.updateUser) {
      snack("Deleted profile picture");
      refreshUser(id);
    }
  };

  const deleteImage = () => {
    const storageRef = firebase.storage().refFromURL(profilePicture);
    storageRef.delete();
  };

  const handleAvatarEditDialog = (open) => () => {
    setAvatarEditDialog(open);
  };

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12} className={styles.marginBottom}>
              <Typography variant="h6" className={styles.label}>
                Settings
              </Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="h4" className={styles.label}>
                  User
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                <div className={styles.avatarWrapper}>
                  <Avatar className={styles.avatar} src={profilePicture}>
                    {form.firstName[0] || ""}
                  </Avatar>
                  <Menu className={styles.editAvatar} button="Edit">
                    <input
                      name="file"
                      onChange={handleUploadImage}
                      accept="image/*"
                      id="file-upload"
                      type="file"
                      className={styles.input}
                    />
                    <label htmlFor="file-upload">
                      <MenuItem component="span">Upload New</MenuItem>
                    </label>
                    {profilePicture && (
                      <MenuItem className={styles.delete} onClick={handleDeleteImage}>
                        Delete
                      </MenuItem>
                    )}
                  </Menu>
                </div>

                <Typography variant="h6" className={styles.label} paragraph>
                  Name
                </Typography>
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  value={form.firstName || ""}
                  onChange={handleChange}
                  helperText={formErrors.firstName}
                  error={Boolean(formErrors.firstName)}
                  className={styles.textField}
                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  value={form.lastName || ""}
                  onChange={handleChange}
                  helperText={formErrors.lastName}
                  error={Boolean(formErrors.lastName)}
                  className={styles.textField}
                />

                <Typography variant="h6" className={styles.label} paragraph>
                  Position
                </Typography>

                <TextField
                  label="Position"
                  variant="outlined"
                  fullWidth
                  name="position"
                  value={form.position || ""}
                  onChange={handleChange}
                  className={styles.textField}
                />

                <Typography variant="h6" className={styles.label} paragraph>
                  Email
                </Typography>

                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={form.email || ""}
                  onChange={handleChange}
                  helperText={formErrors.email}
                  error={Boolean(formErrors.email)}
                  className={styles.textField}
                />

                <Button variant="contained" color="primary" onClick={handleUpdateUser} className={styles.saveButton}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="h4" className={styles.label}>
                  Authentication
                </Typography>
              </Grid>

              <Grid item xs={12} sm={9}>
                <Typography variant="h6" className={styles.label} paragraph>
                  Change Password
                </Typography>

                <div>
                  <TextField
                    type="password"
                    label="Current Password"
                    variant="outlined"
                    fullWidth
                    name="currentPassword"
                    value={form.currentPassword || ""}
                    onChange={handleChange}
                    className={styles.textField}
                  />
                </div>

                <TextField
                  type="password"
                  label="New Password"
                  variant="outlined"
                  fullWidth
                  name="newPassword"
                  value={form.newPassword || ""}
                  onChange={handleChange}
                  className={styles.textField}
                />
                <TextField
                  type="password"
                  label="Confirm New Password"
                  variant="outlined"
                  fullWidth
                  name="confirmNewPassword"
                  value={form.confirmNewPassword || ""}
                  onChange={handleChange}
                  helperText={form.newPassword !== form.confirmNewPassword ? "Passwords do not match" : null}
                  error={form.newPassword !== form.confirmNewPassword}
                  className={styles.textField}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUpdatePassword}
                  disabled={form.newPassword === "" || form.newPassword !== form.confirmNewPassword}
                  className={styles.saveButton}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <Typography variant="h4" className={styles.label}>
                  Notification
                </Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                {Object.keys(form.notificationSettings).map((setting, idx) => (
                  <div className={styles.notifications} key={idx}>
                    <Typography variant="subtitle1">{_.startCase(setting)}</Typography>
                    {_.get(form, `notificationSettings.${setting}.frequency`, "N/A") !== "N/A" && (
                      <Select
                        value={_.get(form, `notificationSettings.${setting}.frequency`, "N/A")}
                        onChange={handleChange}
                        name={`notificationSettings.${setting}.frequency`}
                        label={"Frequency"}
                      >
                        {NOTIFICATION_FREQUENCY.map((value) => {
                          return (
                            <MenuItem key={value} value={value}>
                              {_.startCase(value)}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                    <Switch
                      checked={_.get(form, `notificationSettings.${setting}.enabled`, false)}
                      onChange={handleToggleCheckBox}
                      name={`notificationSettings.${setting}.enabled`}
                    />
                  </div>
                ))}

                <Button variant="contained" color="primary" onClick={handleUpdateNotification} className={styles.saveButton}>
                  Save
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <AvatarEditDialog
        open={Boolean(avatarEditDialog)}
        handleClose={handleAvatarEditDialog(false)}
        handleSave={handleSaveImage}
        src={avatarEditDialog}
      />
    </>
  );
};

export default Settings;

const UPDATE_USER = gql`
  mutation (
    $id: ID!
    $firstName: String
    $lastName: String
    $position: String
    $email: String
    $profilePicture: String
    $notificationSettings: NotificationsInput
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      position: $position
      email: $email
      profilePicture: $profilePicture
      notificationSettings: $notificationSettings
    )
  }
`;
