import React, { useEffect, useState, useContext, useMemo } from "react";
import styles from "./ThreeYear.module.scss";
import _, { update } from "lodash";
import { useLazyQuery, useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { fullDate, fiscalYearStart, subToDate, addToDate} from "../../utils/dates";
// import { getOneYearObjectives } from "../../utils/graphql";
import { isAuthed } from "../../utils/authorization";

import { FetchContext } from "../../context/fetchContext";
// import { LoadingContext } from "../../context/loadingContext";

import { Container, Grid, Typography, IconButton } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import EditDialog from "./EditDialog";
import Loading from "../../components/Loading/Loading";
import Objectives from "../../components/Objectives/Objectives";
import Metrics from "../../components/Metrics/Metrics";
import NotesButton from "../../components/Notes/NotesButton";
import { GET_METRICS, GET_OBJECTIVES } from "../../utils/query";

const ThreeYear = ({ user, params, org, tab }) => {
  const isAdmin = isAuthed(user.user, "department facilitator");
  const { fetch } = useContext(FetchContext);

  // const { updateLoading } = useContext(LoadingContext);
  const fiscalYear = org.fiscalYear;

  const [editDialog, setEditDialog] = useState(false);

  // const [objectiveIds, setObjectiveIds] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);

  const {
    loading: threeYear_plansLoading,
    data,
    refetch: refetchPlans,
  } = useQuery(GET_3_YEAR_PLANS, {
    variables: { organization: params.org, sharedPlanId: user.departmentFilter.sharedPlanId },
  });

  const [getThreeYearObjectives, { data: objectivesData, loading: objectivesLoading, refetch: refetchObjectives }] = useLazyQuery(GET_OBJECTIVES);
  const [getThreeYearMetrics, { data: metricsData, loading: metricsLoading, refetch: refetchMetrics }] = useLazyQuery(GET_METRICS);

  const handleEditDialog = (open) => () => {
    setEditDialog(open);
  };

  const initForm = useMemo(
    () => ({
      useDepartmentTheme: _.get(selectedPlan, "useDepartmentTheme"),
      theme: _.get(selectedPlan, "theme"),
      color: _.get(selectedPlan, "color"),
      shortName: _.get(selectedPlan, "shortName"),
    }),
    [selectedPlan]
  );

  useEffect(() => {
    if (!_.isEmpty(selectedPlan)) {
      refetchPlans();
      if (refetchObjectives) {
        refetchObjectives();
      }
      if (refetchMetrics) {
        refetchMetrics();
      }
    }
  }, [fetch]);

  // useEffect(() => {
  //   updateLoading({ loadingObj: { threeYear_plansLoading } });
  // }, [threeYear_plansLoading]);

  // useEffect(() => {
  //   // Get 1 year objectives to calculate total 3 year progress
  //   const getObjectiveIds = async () => {
  //     const res = await getOneYearObjectives({
  //       organization: params.org,
  //       ids: _.flatten(_.get(data, "plan.objectives", []).map((o) => o.objectives)),
  //     });
  //     return _.map(res.data.objectives, "id");
  //   };

  //   if (data) {
  //     setObjectiveIds(getObjectiveIds());
  //   }
  // }, [data]);

  useEffect(() => {
    if (data) {
      let selPlan;
      if (_.isNil(user.departmentFilter.id)) {
        const corpPlan = _.find(data.corporatePlans, { category: "3 year" });
        selPlan = _.pick(corpPlan, ["id", "theme", "targetDate", "notes", "color", "shortName", "departmentName", "useDepartmentTheme"]);
      } else {
        selPlan = _.pick(_.first(data.plans), [
          "id",
          "theme",
          "targetDate",
          "useDepartmentTheme",
          "notes",
          "color",
          "shortName",
          "departmentName",
          "useDepartmentTheme",
        ]);
      }
      setSelectedPlan(selPlan);

      setLoadedFirstTime(true);
    }
  }, [data]);

  useEffect(() => {
    if (!_.isEmpty(selectedPlan)) {
      getThreeYearObjectives({
        variables: {
          organization: params.org,
          planId: selectedPlan.id,
          sharedPlanId: user.departmentFilter.sharedPlanId,
        },
      });
      getThreeYearMetrics({
        variables: {
          organization: params.org,
          planId: selectedPlan.id,
          sharedPlanId: user.departmentFilter.sharedPlanId,
        },
      });
    }
  }, [selectedPlan, user.departmentFilter.sharedPlanId]);

  if (threeYear_plansLoading || objectivesLoading || metricsLoading ) return <Loading />;
  const plansOrder = _.get(data, "organization.plansOrder", []);

  const corpPlans = _.get(data, "corporatePlans", []);
  const currentOneYearCorpPlan = _.find(corpPlans, { category: "1 year", year: fiscalYear });
  const threeYearCorp = _.find(corpPlans, { category: "3 year" }) || {};
  const openYear = _.get(currentOneYearCorpPlan, "year");
  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {/* <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h6" className={styles.label}>
                3 Year Plan
              </Typography>
            </Grid>
          </Hidden> */}
          <Grid item xs={12}>
            <div className={styles.flexBetween}>
              <div className={styles.container}>
                <div>
                  <div className={styles.flex}>
                    <Typography variant="h5">
                      <span className={styles.label}>Theme: </span>
                      {selectedPlan.useDepartmentTheme ? selectedPlan.theme : threeYearCorp.theme}
                    </Typography>
                    <NotesButton id={selectedPlan.id} model="plan" doc={selectedPlan} />
                    {isAdmin && (
                      <IconButton onClick={handleEditDialog(true)} size="small">
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </div>
                  <div className={styles.flex}>
                    {/*<Typography variant="subtitle1">
                      <div className={`${styles.label} ${styles.flex}`}>
                        <span>Year: {fullDate( fiscalYearStart(subToDate(fiscalYear,{years: 2}))   )} - {fullDate(addToDate(fiscalYear,{years: 2}))}</span>
                      </div>
                    </Typography>*/}

                  </div>
                </div>
              </div>
              <div className={styles.flexCenter}>
                      {/*Add top right buttons here*/}
              </div>
            </div>
          </Grid>
          {tab === "objectives" && (
            <Grid item xs={12}>
              <Objectives
                objectives={_.get(objectivesData, "objectives", [])}
                fiscalYear={fiscalYear}
                plansOrder={plansOrder}
                category="3 year"
                corpForSelectedYear={currentOneYearCorpPlan}
                planId={threeYearCorp.id}
                variables={{
                  organization: params.org,
                  planId: threeYearCorp.id,
                  sharedPlanId: user.departmentFilter.sharedPlanId,
                }}
              />
            </Grid>
          )}
          {tab === "metrics" && (
            <Grid item xs={12}>
              <Metrics
                metrics={_.get(metricsData, "metrics", [])}
                fiscalYear={fiscalYear}
                plansOrder={plansOrder}
                category="3 year"
                planId={threeYearCorp.id}
                yearOne={openYear}
                corpForSelectedYear={currentOneYearCorpPlan}
                variables={{
                  organization: params.org,
                  planId: threeYearCorp.id,
                  sharedPlanId: user.departmentFilter.sharedPlanId,
                }}
              />
            </Grid>
          )}
        </Grid>
      </Container>

      <EditDialog
        open={editDialog}
        handleClose={handleEditDialog(false)}
        planId={selectedPlan.id}
        initForm={initForm}
        isCorporate={selectedPlan.departmentName === "Corporate"}
      />

    </>
  );
};

export default ThreeYear;

const GET_3_YEAR_PLANS = gql`
  query ThreeYear_GetThreeYear($organization: ID!, $sharedPlanId: ID) {
    plans(organization: $organization, category: "3 year", sharedPlanId: $sharedPlanId) {
      id
      theme
      useDepartmentTheme
      targetDate
      departmentName
      sharedPlanId
      color
      shortName
      notes {
        id
        date
      }
    }

    corporatePlans: plans(organization: $organization, departmentName: "Corporate", closed: false) {
      id
      theme
      targetDate
      color
      shortName
      departmentName
      useDepartmentTheme
      notes {
        id
        date
      }
      category
      year
    }

    organization(id: $organization) {
      id
      fiscalYear
      plansOrder
    }
  }
`;

