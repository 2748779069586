import React, { useState, useEffect, useContext } from "react";
import styles from "./Vision.module.scss";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";

const initErrorForm = {
  name: ["required"],
};

const EditDialog = ({ id, open, handleClose, initForm, name, plan, minYear }) => {
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [count, setCount] = useState(0);
  const [updateCore] = useMutation(UPDATE_CORE);

  const { form, handleChange, resetForm } = useForm({
    initForm,
    initErrorForm,
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const handleDragEndCore = ({ draggableId, destination }) => {
    if (!destination) return;

    const clonedForm = _.cloneDeep(form);
    const val = clonedForm.values.splice(draggableId, 1)[0];
    clonedForm.values.splice(destination.index, 0, val);

    resetForm(clonedForm);
  };

  const handleSubmitCore = async () => {
    const { values } = form;
    const ok = await updateCore({
      variables: {
        id,
        name,
        plan,
        values: _.compact(values),
      },
    });

    if (ok.data.updateCore) {
      snack("Updated core details");
      requestFetch();
      handleClose();
    }
  };

  const handleDeleteEntry = (value) => () => {
    const clonedForm = _.cloneDeep(form);
    const index = clonedForm.values.indexOf(value);
    if (index > -1) {
      clonedForm.values.splice(index, 1);
    }

    resetForm(clonedForm);
    if (count > 1) {
      setCount(count - 1);
    }
  };

  useEffect(() => {
    const values = _.get(initForm, "values", []);
    resetForm(initForm);
    setCount(values.length);
  }, [open]);

  return (
    <>
      <Dialog
        open={Boolean(open.core)}
        fullWidth
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullScreen={fs}
      >
        <DialogTitle>Edit Core {_.startCase(name)}</DialogTitle>
        <DialogContent>
          <DragDropContext onDragEnd={handleDragEndCore}>
            <Droppable droppableId={"0"}>
              {(provided, snapshot) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {Array.from(Array(count), (_, i) => (
                      <Draggable draggableId={i.toString()} index={i} key={i}>
                        {(provided, snapshot) => {
                          return (
                            <TextField
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              key={i}
                              label={`Core ${name} ${i + 1}`}
                              name={`values[${i}]`}
                              fullWidth
                              multiline
                              margin="normal"
                              variant="outlined"
                              value={form.values[i] || ""}
                              onChange={handleChange}
                              className={styles.placeholder}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <MenuIcon className={styles.draggable} />
                                    <IconButton onClick={handleDeleteEntry(form.values[i])}>
                                      <CloseIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
          <IconButton onClick={() => setCount(count + 1)}>
            <AddIcon />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" onClick={handleSubmitCore} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialog;

const UPDATE_CORE = gql`
  mutation ($id: ID!, $values: [String], $name: String, $plan: ID) {
    updateCore(id: $id, values: $values, name: $name, plan: $plan)
  }
`;

