import React, { useContext, useState } from "react";
import styles from "./Signup.module.scss";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { Typography, TextField, Button } from "@material-ui/core";
import { SnackbarContext } from "../../context/snackbarContext";
import { useForm, useSearch } from "../../hooks";
import Loading from "../../components/Loading/Loading";
import Version from "../../components/Version/Version";

import logo from "../../assets/img/icon-white.png";
import splash from "../../assets/img/splash.svg";

const initForm = {
  email: null,
  name: {
    first: null,
    last: null,
  },
  password: null,
  position: null,
};

const Signup = () => {
  const history = useHistory();
  const search = useSearch();
  const org = search.get("org");
  const { snack } = useContext(SnackbarContext);
  const [errorMesasge, setErrorMessage] = useState();
  const { loading, data } = useQuery(GET_ORG, { variables: { id: org } });
  const [createUser] = useMutation(CREATE_USER);
  const { form, handleChange } = useForm({
    initForm,
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email, name, password, position } = form;
    const { data } = await createUser({
      variables: { organization: org, email, firstName: name.first, lastName: name.last, password, position },
    });

    if (data.createUser.ok) {
      snack("Registration successful");
      history.push("/");
    } else {
      setErrorMessage(data.createUser.reason);
    }
  };

  if (loading) return <Loading />;

  const organization = _.get(data, "organization", null);
  return (
    <div className={styles.container} maxWidth={false}>
      <div className={styles.splash}>
        <div className={styles.splashOverlay}>
          <img src={logo} alt="Ventrek Logo" className={styles.img} />
          <Typography variant="h3" className={styles.splashTitle}>
            Welcome to Ventrek
          </Typography>
        </div>
        <img src={splash} alt="splash" className={styles.svg} />
      </div>
      <div className={styles.content}>
        <Typography variant="h2" gutterBottom>
          Sign Up
        </Typography>

        {_.isNil(organization) ? (
          <Typography variant="body1" align="center">
            Invalid organization
          </Typography>
        ) : (
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="First Name"
              name="name.first"
              value={form.name.first || ""}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Last Name"
              name="name.last"
              value={form.name.last || ""}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Email Address"
              name="email"
              autoFocus
              value={form.email || ""}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Position"
              name="position"
              autoFocus
              value={form.position || ""}
              onChange={handleChange}
            />
            <TextField
              type="password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Password"
              name="password"
              autoFocus
              value={form.password || ""}
              onChange={handleChange}
            />
            {errorMesasge && (
              <Typography variant="body1" className={styles.error} paragraph>
                {errorMesasge}
              </Typography>
            )}

            <Button type="submit" margin="normal" fullWidth variant="contained" color="primary">
              Sign up
            </Button>
          </form>
        )}
        <Version className={styles.version} />
      </div>
    </div>
  );
};

export default Signup;

const GET_ORG = gql`
  query($id: ID!) {
    organization(id: $id) {
      id
      name
    }
  }
`;

const CREATE_USER = gql`
  mutation($organization: ID!, $firstName: String!, $lastName: String!, $email: String!, $position: String!, $password: String!) {
    createUser(
      organization: $organization
      firstName: $firstName
      lastName: $lastName
      email: $email
      position: $position
      password: $password
    ) {
      ok
      reason
    }
  }
`;

/*


*/
