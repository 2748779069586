import React, { useEffect, useRef, useState } from "react";
import styles from "./Todos.module.scss";
import useForm from "../../hooks/useForm";
import { isAuthed } from "../../utils/authorization";
import _ from "lodash";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import gql from "graphql-tag";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Tooltip,
  MenuItem,
} from "@material-ui/core";
import Icon from "@mdi/react";
import {
  mdiLinkVariantOff,
  mdiRhombus,
  mdiRhombusSplit,
  mdiPoll,
  mdiBullseyeArrow,
  mdiAlertDecagram,
  mdiCheckboxMarked,
  mdiChartTimelineVariant,
} from "@mdi/js";
import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import PlanPill from "../PlanPill/PlanPill";
import { compareDeptNames, getPlansByReferenceModel } from "../../utils/misc";
import Loading from "../Loading/Loading";
import { TODO_FIELDS } from "../../utils/fragments";

const PRIORITY = ["high", "medium", "low"];

const initErrorForm = {
  value: ["required"],
  user: ["required"],
};

const EditDialog = (props) => {
  const { open, handleClose, todo = {}, category, org, snack, requestFetch, user, planId } = props;
  const { id, value, referenceId, referenceModel, user: todoUser, priority, plan, done } = todo;

  const isAdmin = isAuthed(user.user, "department facilitator");
  const { sharedPlanId } = _.get(user, "departmentFilter");
  const [menuItems, setMenuItems] = useState([]);
  const [submitFormAfterChange, setSubmitFormAfterChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const [updateTodo] = useMutation(UPDATE_TODO);

  const initForm = {
    id: id,
    value: value,
    referenceId: referenceId,
    referenceModel: referenceModel,
    user: _.get(todoUser, "id"),
    priority: priority,
    plan: _.get(plan, "id", null),
    done: done || false,
  };
  const { form, formErrors, handleChange, handleChangeManual, handleChangeToggleButton, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleChangeReferenceId = (item) => {
    // change tied in reference id
    const newForm = _.cloneDeep(form);
    _.set(newForm, "referenceId", _.get(item, "id", null));

    // also change department based on tied in selected
    const plans = getPlansByReferenceModel(form.referenceModel, item);
    const firstPlan = _.first(plans);
    if (!_.isNil(firstPlan) && _.isNil(form.plan)) {
      const sharedPlanId = _.get(firstPlan, "sharedPlanId");
      const targetPlan = _.find(userPlanData.plans || [], (plan) => plan.sharedPlanId === sharedPlanId);

      if (targetPlan) {
        _.set(newForm, "plan", _.get(targetPlan, "id", null));
      }
    }
    resetForm(newForm);
  };

  const isTodoReference = ["Todo", "Issue"].includes(form.referenceModel);

  // for fetching referenced todos that are not loaded yet
  const [getReferencedTodo, { data: referencedTodoData, loading: referenceLoading }] = useLazyQuery(GET_REFERENCED_TODO);
  const [searchTerm, setSearchTerm] = useState("");
  const [inputSearchTerm, setInputSearchTerm] = useState("");

  const debouncedSetSearchTermRef = useRef(_.debounce((newValue) => setSearchTerm(newValue), 700));
  useEffect(() => {
    if (debouncedSetSearchTermRef.current) {
      debouncedSetSearchTermRef.current.cancel();
      debouncedSetSearchTermRef.current(inputSearchTerm);
    }
  }, [inputSearchTerm]);

  const {
    data,
    refetch,
    loading: todoEditDialogLoading,
  } = useQuery(GET_SC_ROCKS_OBJECTIVES_METRICS, {
    variables: { organization: org, searchTerm, corpPlan: planId },
  });

  const {
    data: userPlanData,
    refetch: userPlanDataRefetch,
    loading: userPlanDataLoading,
  } = useQuery(GET_USERS_PLANS, {
    variables: { organization: org, corpPlan: planId },
  });

  const {
    data: todosData,
    loading: todosLoading,
    fetchMore: fetchMoreTodos,
  } = useQuery(GET_MORE_TODOS, {
    variables: { organization: org, sharedPlanId, limit: 25, cursor: null, searchTerm, oneYearCorpPlan: planId },
  });

  const {
    data: issueData,
    loading: issuesLoading,
    fetchMore: fetchMoreIssues,
  } = useQuery(GET_MORE_ISSUES, {
    variables: { organization: org, sharedPlanId, limit: 25, cursor: null, searchTerm, oneYearCorpPlan: planId },
  });

  const loadMoreItems = (event, form) => {
    const userScrolledToTheBottom = Math.abs(event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop) < 1;
    if (isTodoReference && userScrolledToTheBottom) {
      //user is at the end of the list so load more items
      if (form.referenceModel === "Todo" && !_.isNil(_.get(todosData, "results.nextCursor"))) {
        fetchMoreTodos({
          variables: {
            organization: org,
            sharedPlanId,
            limit: 25,
            cursor: todosData.results.nextCursor,
            currentReferenceId: null,
            searchTerm,
          },
        });
      }

      if (form.referenceModel === "Issue" && !_.isNil(_.get(issueData, "results.nextCursor"))) {
        fetchMoreIssues({
          variables: {
            organization: org,
            sharedPlanId,
            limit: 25,
            cursor: issueData.results.nextCursor,
            currentReferenceId: null,
            searchTerm,
          },
        });
      }
    }
  };

  const handleChangeComplete = () => {
    setSubmitFormAfterChange(true);
    handleChangeManual({ name: "done", value: !form.done });
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);
    const { value, done, user, referenceId, referenceModel, priority, plan } = form;

    const ok = await updateTodo({
      variables: { id, value, done, user, referenceId, referenceModel, priority, plan },
    });

    if (ok.data.updateTodo) {
      snack(`Updated "${value}" ${category}`);
      // requestFetch();
      handleClose();
    }
    setLoading(false);
  };

  useEffect(() => {
    if (submitFormAfterChange) {
      setSubmitFormAfterChange(false);
      handleSubmit();
    }
  }, [submitFormAfterChange]);

  useEffect(() => {
    switch (form.referenceModel) {
      case "SuccessCriteria":
        setMenuItems(_.get(data, "successCriterias", []));
        break;
      case "Rock":
        setMenuItems(_.get(data, "rocks", []));
        break;

      case "Objective":
        setMenuItems(_.get(data, "objectives", []));
        break;

      case "Metric":
        const metrics = _.get(data, "metrics", []);
        const uniqueMetrics = [];
        metrics.forEach((metric) => {
          if ( (metric.status !== "complete") && (!_.find(uniqueMetrics, (m) => m.value === metric.value))) {
            uniqueMetrics.push(metric);
          }
        });
        setMenuItems(uniqueMetrics);
        break;

      case "WeeklyTarget":
        setMenuItems(_.get(data, "weeklyTargets", []));
        break;

      case "Issue":
        setMenuItems(_.get(issueData, "results.todos", []));
        break;

      case "Todo":
        setMenuItems(_.get(todosData, "results.todos", []));
        break;
      default:
        setMenuItems([]);
        break;
    }
  }, [form.referenceModel, todoEditDialogLoading, issueData, todosData, data]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [open]);

  useEffect(() => {
    resetForm(initForm);
  }, [id]);

  useEffect(() => {
    if (isTodoReference) {
      getReferencedTodo({
        variables: {
          id: referenceId,
        },
      });
    }
  }, [isTodoReference]);

  const combinedMenuItems =
    !_.isEmpty(referenceId) &&
    isTodoReference &&
    form.referenceModel === referenceModel &&
    menuItems &&
    menuItems.every((item) => item.id !== referenceId) &&
    !_.isNil(referencedTodoData?.todo)
      ? [referencedTodoData.todo, ...menuItems]
      : menuItems;

  const selectedItem = combinedMenuItems.find((item) => item.id === form.referenceId);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
    >
      <DialogTitle>Edit {category === "todo" ? "To Do" : "Issue"}</DialogTitle>
      <DialogContent>
        <Typography className={styles.label}>
          Status: <span className={form.done ? styles.complete : styles.incomplete}>{form.done ? "Complete" : "Incomplete"}</span>
        </Typography>
        <TextField
          autoFocus
          label={`${category === "todo" ? "Twopo Do" : "Issue"} Details`}
          name="value"
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          value={form.value || ""}
          onChange={handleChange}
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />
        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Priority</InputLabel>
          <Select value={form.priority} onChange={handleChange} name="priority" label={"Priority"}>
            {PRIORITY.map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  {_.startCase(value)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <div className={styles.modelButtons}>
          <ToggleButtonGroup
            exclusive
            value={form.referenceModel}
            onChange={(e, value) => {
              handleChangeToggleButton("referenceModel")(e, value);
              setInputSearchTerm("");
            }}
            className={form.referenceModel ? undefined : styles.marginBottom}
          >
            <ToggleButton value={null}>
              <Tooltip title="No tie in">
                <Icon path={mdiLinkVariantOff} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value="SuccessCriteria">
              <Tooltip title="Success criteria tie in">
                <Icon path={mdiRhombusSplit} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value="Rock">
              <Tooltip title="Rock tie in">
                <Icon path={mdiRhombus} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value="Objective">
              <Tooltip title="Objective tie in">
                <Icon path={mdiChartTimelineVariant} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value="Metric">
              <Tooltip title="Metric tie in">
                <Icon path={mdiPoll} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value="WeeklyTarget">
              <Tooltip title="KPI tie in">
                <Icon path={mdiBullseyeArrow} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value="Issue">
              <Tooltip title="Issue tie in">
                <Icon path={mdiAlertDecagram} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>

            <ToggleButton value="Todo">
              <Tooltip title="Todo tie in">
                <Icon path={mdiCheckboxMarked} size={1.25} color="rgba(0, 0, 0, 0.54)" />
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        {form.referenceModel && (
          <Autocomplete
            options={combinedMenuItems}
            value={selectedItem || inputSearchTerm}
            getOptionLabel={(option) => (typeof option === "string" ? option || "" : option.value || "")}
            renderOption={(option, state) => {
              const { id, value } = option;
              const plans = getPlansByReferenceModel(form.referenceModel, option);
              const uniqPlans = _.uniqBy(plans, (plan) => plan.sharedPlanId);
              return (
                <div className={styles.tieInItem}>
                  {uniqPlans.map((plan, idx) => (
                    <PlanPill plan={plan} key={idx} />
                  ))}
                  {_.isEmpty(uniqPlans) && <PlanPill plan={null} />}
                  <Typography className={styles.tieInItemText}>{value}</Typography>
                </div>
              );
            }}
            ListboxProps={{
              onScroll: (e) => loadMoreItems(e, form),
            }}
            fullWidth
            onChange={(e, newValue) => handleChangeReferenceId(newValue)}
            onInputChange={(e, newInputValue) => setInputSearchTerm(newInputValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ marginTop: "16px" }}
                variant="outlined"
                label={`Tied In ${_.startCase(form.referenceModel)}`}
              />
            )}
          />
        )}

        {/* {form.referenceModel && (
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Tied In {_.startCase(form.referenceModel)}</InputLabel>
            <Select
              value={form.referenceId}
              onChange={handleChange}
              name="referenceId"
              label={`Tied In ${_.startCase(form.referenceModel)}`}
              MenuProps={{
                PaperProps: {
                  onScroll: (e) => loadMoreItems(e, form),
                },
              }}
            >
              {!_.isEmpty(todo) &&
                isTodoReference &&
                form.referenceModel === todo.referenceModel &&
                menuItems &&
                menuItems.every((item) => item.id !== todo.referenceId) &&
                !_.isNil(referencedTodoData?.todo) && (
                  <MenuItem value={referencedTodoData.todo.id}>
                    {<PlanPill plan={referencedTodoData.todo.plan} />}
                    {_.isNil(referencedTodoData.todo.plan) && <PlanPill plan={null} />}
                    {referencedTodoData.todo.value}
                  </MenuItem>
                )}
              {menuItems &&
                menuItems
                  .map((item) => {
                    let plans = getPlansByReferenceModel(form.referenceModel, item);
                    return { ...item, plans };
                  })
                  .filter(({ plans }) => {
                    return _.isNil(sharedPlanId)
                      ? true
                      : !_.isEmpty(plans)
                      ? plans.map((plan) => plan.sharedPlanId).includes(sharedPlanId)
                      : false;
                  })
                  .sort((a, b) => (isTodoReference ? 0 : compareDeptNames(a.plans, b.plans)))
                  .map(({ id, value, plans }) => {
                    const uniqPlans = _.uniqBy(plans, (plan) => plan.sharedPlanId);
                    return (
                      <MenuItem key={id} value={id}>
                        {uniqPlans.map((plan, idx) => (
                          <PlanPill plan={plan} key={idx} />
                        ))}
                        {_.isEmpty(uniqPlans) && <PlanPill plan={null} />}
                        {value}
                      </MenuItem>
                    );
                  })}
            </Select>
          </FormControl>
        )} */}

        <SelectDepartment
          plans={_.get(userPlanData, "plans")}
          name="plan"
          handleChange={handleChange}
          value={form.plan}
          helperText={formErrors.plan}
          error={Boolean(formErrors.plan)}
          showAll
        />

        {isAdmin && !userPlanDataLoading && (
          <SelectUsers
            name="user"
            users={_.get(userPlanData, "users")}
            handleChange={handleChange}
            values={form.user}
            helperText={`Who is accountable for this ${category === "todo" ? "to do" : "issue"}?`}
            plan={form.plan}
            allPlans={_.get(userPlanData, "plans")}
            error={Boolean(formErrors.user)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleChangeComplete} color="primary" style={{ marginRight: "auto" }}>
          {submitFormAfterChange || loading ? <Loading size={24} color="primary" /> : `Mark as ${form.done ? "Incomplete" : "Complete"}`}
        </Button>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit}>
          {loading ? <Loading size={24} color="primary" /> : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;

const GET_USERS_PLANS = gql`
  query TodosEditDialog_GetUsersPlans($organization: ID!, $corpPlan: ID) {
    users(organization: $organization) {
      id
      name {
        first
        last
      }
      profilePicture
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $corpPlan) {
      id
      departmentName
      sharedPlanId
      year
    }
  }
`;

const GET_SC_ROCKS_OBJECTIVES_METRICS = gql`
  query TodosEditDialog_GetScRocksObjsMetrics($organization: ID!, $sharedPlanId: ID, $searchTerm: String, $corpPlan: ID) {
    successCriterias(organization: $organization, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan) {
      id
      value
      rock {
        id
        plan {
          id
          departmentName
          color
          shortName
          sharedPlanId
        }
      }
    }

    rocks(organization: $organization, sharedPlanId: $sharedPlanId, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan) {
      id
      value
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }

    objectives(
      organization: $organization
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      corpPlan: $corpPlan
      includeThreeYear: true
    ) {
      id
      value
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }

    metrics(
      organization: $organization
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      corpPlan: $corpPlan
      includeThreeYear: true
    ) {
      id
      value
      status
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }

    weeklyTargets(organization: $organization, sharedPlanId: $sharedPlanId, searchTerm: $searchTerm, oneYearCorpPlan: $corpPlan) {
      id: _id
      value
      plans {
        shortName
        color
        departmentName
        sharedPlanId
      }
    }
  }
`;

const GET_MORE_TODOS = gql`
  query TodosEditDialog_GetMoreTodos(
    $organization: ID!
    $sharedPlanId: ID
    $cursor: String
    $limit: Int
    $searchTerm: String
    $oneYearCorpPlan: ID
  ) {
    results: moreTodos(
      organization: $organization
      category: "todo"
      cursor: $cursor
      limit: $limit
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      oneYearCorpPlan: $oneYearCorpPlan
    ) {
      todos {
        id: _id
        value
        plan {
          id
          shortName
          color
          departmentName
          sharedPlanId
        }
      }
      nextCursor
    }
  }
`;

const GET_MORE_ISSUES = gql`
  query TodosEditDialog_GetMoreIssues(
    $organization: ID!
    $sharedPlanId: ID
    $cursor: String
    $limit: Int
    $searchTerm: String
    $oneYearCorpPlan: ID
  ) {
    results: moreTodos(
      organization: $organization
      category: "issue"
      cursor: $cursor
      limit: $limit
      sharedPlanId: $sharedPlanId
      searchTerm: $searchTerm
      oneYearCorpPlan: $oneYearCorpPlan
    ) {
      todos {
        id: _id
        value
        plan {
          id
          shortName
          color
          departmentName
          sharedPlanId
        }
      }
      nextCursor
    }
  }
`;

const GET_REFERENCED_TODO = gql`
  query TodosEditDialog_GetRefTodo($id: ID) {
    todo(id: $id) {
      id: _id
      value
      plan {
        id
        shortName
        color
        departmentName
        sharedPlanId
      }
    }
  }
`;

const UPDATE_TODO = gql`
  ${TODO_FIELDS}
  mutation TodosEditDialog_UpdateTodo(
    $id: ID!
    $value: String
    $done: Boolean
    $user: ID!
    $referenceId: ID
    $referenceModel: String
    $priority: String
    $plan: ID
  ) {
    updateTodo(
      id: $id
      value: $value
      done: $done
      user: $user
      referenceId: $referenceId
      referenceModel: $referenceModel
      priority: $priority
      plan: $plan
    ) {
      todo {
        ...TodoFields
      }
      successCriterias {
        id
        todos {
          id: _id
        }
      }
      rocks {
        id
        todos {
          id: _id
        }
      }
      metrics {
        id
        todos {
          id: _id
        }
      }
      objectives {
        id
        todos {
          id: _id
        }
      }
    }
  }
`;
