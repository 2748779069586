import React, { useEffect, useState } from "react";
import _ from "lodash";
import styles from "./QuarterSummary.module.scss";
import { Dialog, DialogTitle, DialogContent, DialogActions, Chip, Button, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import RichText from "../RichText/RichText";
import { getDeptShortName } from "../../utils/misc";

const QSNotesDialog = ({
  editMode,
  plan,
  // plans,
  additionalProps,
  handleCreate,
  handleUpdate,
  handleChange,
  handleClose,
}) => {
  /* FOR MULTIPLE PLANS PER NOTE (uncomment plans prop and Autocomplete component)
  const [selectedPlans, setSelectedPlans] = useState([]);

  const handleAddPlan = (event, plans) => {
    setSelectedPlans(plans.map((plan) => plan.id));
  };

  useEffect(() => {
    console.log({ selectedPlans });
  }, [selectedPlans]);
  */

  const { open, id, text } = editMode;

  return (
    <Dialog
      open={Boolean(open)}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{_.isNil(id) ? "Add" : "Edit"} Note</DialogTitle>
      <DialogContent>
        <RichText value={text} readOnly={false} onChange={(value) => handleChange({ target: { name: "text", value } })} />
        {/* <Autocomplete
          fullWidth
          multiple
          onChange={handleAddPlan}
          id="plans-autocomplete"
          options={plans}
          getOptionLabel={(plan) => plan.departmentName}
          style={{ marginTop: 16 }}
          renderInput={(params) => <TextField {...params} label="Plans" variant="outlined" />}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const shortName = getDeptShortName(option.departmentName, option.shortName);
              return (
                <Chip
                  variant="outlined"
                  label={shortName}
                  {...getTagProps({ index })}
                  style={{ backgroundColor: "#7E57C2", color: "white" }}
                />
              );
            })
          }
        /> */}
        <div style={{ marginTop: 16 }}>
          <label htmlFor="file-upload" style={{ display: "inline-block" }}>
            <Button color="primary" variant="contained" component="span">
              Upload File
            </Button>
          </label>
          <input
            name="file"
            onChange={handleChange}
            accept="image/*, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
            id="file-upload"
            type="file"
            className={styles.input}
          />
          <Typography variant="subtitle2" display="inline" className={styles.filename}>
            {_.get(editMode, "file.name", _.get(editMode, "filename"))}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        {open && (
          <>
            {_.isNil(id) ? (
              <Button variant="contained" onClick={handleCreate(plan, "plan", "", additionalProps)} color="primary">
                Create
              </Button>
            ) : (
              <Button variant="contained" onClick={handleUpdate({ id, model: "plan", additionalProps })} color="primary">
                Update
              </Button>
            )}
          </>
        )}
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default QSNotesDialog;
