// To be removed
import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";

import styles from "./FiveYearMission.module.scss";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { UserContext } from "../../context/userContext";

import { isAuthed } from "../../utils/authorization";

import { Container, Grid, Typography, Divider, Hidden, Button } from "@material-ui/core";

import Icon from "@mdi/react";
import { mdiCalendarAlert, mdiCalendarStar } from "@mdi/js";
import Loading from "../../components/Loading/Loading";
import NotesButton from "../../components/Notes/NotesButton";
import MultiYearProgressDisplay from "../../components/MultiYearProgressDisplay/MultiYearProgressDisplay";

import EditDialog from "./EditDialog";
import { bhagTimeRemaining, formatAs } from "../../utils/dates";

const FiveYearMission = ({ params }) => {
  const { fetch } = useContext(FetchContext);
  const { user } = useContext(UserContext);
  const [dialog, setDialog] = useState({ org: null, core: null, bhag: null });
  const [initForm, setInitForm] = useState({});

  const { loading, data, refetch } = useQuery(GET_ORGANIZATION, {
    variables: { id: params.org, plan: user.departmentFilter.id },
  });

  const handleEditBhag = () => {
    setInitForm({ goal: bhag.goal, targetDate: parseInt(bhag.targetDate) });
    setDialog({ ...dialog, bhag: true });
  };

  const handleEditClose = () => {
    setDialog({ org: null, core: null, bhag: null });
  };

  useEffect(() => {
    refetch();
  }, [fetch, user.departmentFilter]);

  if (loading) return <Loading />;

  if (!data.organization) return null; //TODO remove this check
  const { bhag, notes } = data.organization;

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5" className={styles.label}>
                  5 Year Mission
                </Typography>
                <div className={styles.flexParagraph}>
                  <Typography variant="h5" gutterBottom>
                    {bhag.goal}
                  </Typography>
                  {bhag.goal ? (
                    <>
                      <NotesButton id={params.org} core="bhag" model="organization" size={1.25} doc={{ notes: notes["bhag"] }} />
                      {isAuthed(user.user, "company admin") && (
                        <Button onClick={handleEditBhag} variant="outlined" className={styles.editButton}>
                          Edit
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button onClick={handleEditBhag} color="primary" disabled={!isAuthed(user.user, "company admin")}>
                      Create 5 Year Mission
                    </Button>
                  )}
                </div>

                <Typography variant="h6" className={styles.label}>
                  Target Date
                </Typography>
                <div className={styles.flexParagraph}>
                  <Icon path={mdiCalendarStar} size={1.5} color="rgba(0, 0, 0, 0.65)" className={styles.icon} />
                  <Typography variant="h5">{formatAs(bhag.targetDate, "MMM d, y")}</Typography>
                </div>

                <Typography variant="h6" className={styles.label}>
                  Time Remaining
                </Typography>
                <div className={styles.flexParagraph}>
                  <Icon path={mdiCalendarAlert} size={1.5} color="rgba(0, 0, 0, 0.65)" className={styles.icon} />
                  <Typography variant="h5">{bhagTimeRemaining(bhag.targetDate)}</Typography>
                </div>
                <Divider className={styles.divider} />
                <Typography variant="h6" className={styles.label}>
                  Visual Representation
                </Typography>
                <div className={styles.flexParagraph}>
                  <MultiYearProgressDisplay planTargetDate={bhag.targetDate} planLengthYears={5} />
                </div>
                <Divider className={styles.divider} />                
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <EditDialog id={params.org} open={dialog} handleClose={handleEditClose} initForm={initForm} />
    </>
  );
};

export default FiveYearMission;

const GET_ORGANIZATION = gql`
  query TheCore_GetOrg($id: ID!, $plan: ID) {
    organization(id: $id, plan: $plan) {
      id
      name
      fiscalYear
      profilePicture
      plansOrder
      core {
        purpose
        value
        competency
        market
        offering
        valueProposition
        differentiation
        process
      }
      createdAt
      bhag {
        goal
        targetDate
      }
      viewPreferences {
        name
        position
        show
      }
      notes {
        bhag {
          id
          date
        }
        purpose {
          id
          date
        }
        value {
          id
          date
        }
        competency {
          id
          date
        }
        market {
          id
          date
        }
        offering {
          id
          date
        }
        valueProposition {
          id
          date
        }
        process {
          id
          date
        }
        differentiation {
          id
          date
        }
      }
    }

    plans(organization: $id, closed: false) {
      id
      departmentName
      theme
      year
      useDepartmentTheme
      sharedPlanId
      category
      color
      shortName
      targetDate
    }

    projects(organization: $id) {
      id
      value
      plan {
        id
        departmentName
      }
      notes {
        id
        date
      }
    }
  }
`;
