import React, { useEffect, useState } from "react";
import _ from "lodash";
import useForm from "../../hooks/useForm";
import styles from "./Rock.module.scss";
import { isDate, startOfToday } from "date-fns";
import { before } from "../../utils/dates";
import { formatAs } from "../../utils/dates";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import { Icon as MdiIcon } from "@mdi/react";
import { mdiDrag } from "@mdi/js";

import { DatePicker } from "@material-ui/pickers";
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Icon,
  Divider,
  IconButton,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  Tooltip,
  DialogTitle,
} from "@material-ui/core";

function SuccessCriteriaList({ successCriterias = [], onChange, error, handleAddIssueDialog, handleToBeDeleted }) {
  const [formOpen, setFormOpen] = useState(false);
  const [indexToEdit, setIndexToEdit] = useState(null);
  const [successCriteriaList, setSuccessCriteriaList] = useState(successCriterias);

  const handleRemove = (i) => {
    let newSCs = _.cloneDeep(successCriteriaList);
    const [removedSc] = newSCs.splice(i, 1);
    if (handleToBeDeleted && _.get(removedSc, "id")) {
      handleToBeDeleted(removedSc);
    }

    setSuccessCriteriaList(newSCs);
    onChange({ name: "successCriterias", value: newSCs });
  };

  const handleAddEdit = (values) => {
    let newSCs = _.cloneDeep(successCriteriaList);
    if (_.isNil(indexToEdit)) {
      newSCs.push(values);
    } else {
      let scToUpdate = newSCs[indexToEdit];
      _.set(newSCs, [indexToEdit], { ...scToUpdate, ...values });
    }
    setSuccessCriteriaList(newSCs);
    onChange({ name: "successCriterias", value: newSCs });
    setFormOpen(false);
  };

  const handleEditOpen = (index) => {
    setFormOpen(true);
    setIndexToEdit(index);
  };

  const handleCreateOpen = (index) => {
    setFormOpen(true);
    setIndexToEdit(null);
  };

  const handleDragEnd = ({ draggableId, destination, source }) => {
    if (_.isEqual(source, destination)) return;

    let newSCs = _.cloneDeep(successCriteriaList);

    const [srcItem] = newSCs.splice(source.index, 1);
    newSCs.splice(destination.index, 0, srcItem);

    setSuccessCriteriaList(newSCs);
    onChange({ name: "successCriterias", value: newSCs });
  };

  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error} className={styles.scContainer}>
      <div className={styles.inputLabel}>Success Criteria</div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="SCF" type="successCriteriaForm">
          {(provided, snapshot) => (
            <List {...provided.droppableProps} ref={provided.innerRef}>
              {_.isEmpty(successCriteriaList) ? (
                <ListItem className={styles.scListItem}>
                  <ListItemText>No success criteria at the moment</ListItemText>
                </ListItem>
              ) : (
                successCriteriaList.map((sc, index) => {
                  const targetDate = sc.targetDate;
                  return (
                    <Draggable key={sc.value} draggableId={`SCF_${sc.id}_${sc.value}`} index={index}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.draggableProps} style={{ ...provided.draggableProps.style }}>
                          <ListItem className={styles.scListItem}>
                            <ListItemIcon className={styles.listIcon}>
                              <div {...provided.dragHandleProps}>
                                <MdiIcon path={mdiDrag} size={1} />
                              </div>
                            </ListItemIcon>
                            <ListItemText style={{ maxWidth: "80%" }}>
                              <div style={{ textDecoration: _.get(sc, "done", false) ? "line-through" : "none" }}>
                                {_.get(sc, `value`, "")}
                              </div>
                              <div className={before(targetDate, startOfToday()) ? styles.targetDatePast : styles.targetDate}>
                                {targetDate && `(${formatAs(targetDate, "MMM dd, yyyy")})`}
                              </div>
                            </ListItemText>
                            <ListItemSecondaryAction>
                              <IconButton onClick={() => handleEditOpen(index)}>
                                <Icon>edit</Icon>
                              </IconButton>
                              <IconButton onClick={() => handleRemove(index)}>
                                <Icon>delete</Icon>
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                          <Divider />
                        </div>
                      )}
                    </Draggable>
                  );
                })
              )}
              {provided.placeholder}
              <Button variant="outlined" color="primary" style={{ width: "100%", marginTop: 8 }} onClick={handleCreateOpen}>
                <Icon>add_circle</Icon> Add
              </Button>
            </List>
          )}
        </Droppable>
      </DragDropContext>
      {formOpen && (
        <SuccessCriterionForm
          successCriterion={_.get(successCriterias, `[${indexToEdit}]`, null)}
          open={formOpen}
          onClose={() => setFormOpen(false)}
          onSubmit={handleAddEdit}
          handleAddIssueDialog={handleAddIssueDialog}
        />
      )}
    </FormControl>
  );
}

const initErrorForm = {
  value: ["required"],
};

function SuccessCriterionForm({ successCriterion, open, onClose, onSubmit, handleAddIssueDialog }) {
  const id = _.get(successCriterion, "id");
  const value = _.get(successCriterion, "value", "");
  const targetDate = _.get(successCriterion, "targetDate", null);
  const [submitFormAfterChange, setSubmitFormAfterChange] = useState(false);

  const initForm = {
    value,
    targetDate: isDate(targetDate) || _.isNil(targetDate) ? targetDate : new Date(parseInt(targetDate)),
    done: _.get(successCriterion, "done", false),
  };

  const { form, formErrors, handleChange, handleChangeDate, handleChangeManual, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleChangeComplete = () => {
    setSubmitFormAfterChange(true);
    handleChangeManual({ name: "done", value: !form.done });
  };

  const handleSubmit = () => {
    if (!validateForm()) return;
    onSubmit(form);
  };

  useEffect(() => {
    if (submitFormAfterChange) {
      setSubmitFormAfterChange(false);
      handleSubmit();
    }
  }, [form]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{_.isEmpty(value) ? "Add" : "Edit"} Success Criteria</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          label="Success Criteria Description"
          name="value"
          value={form.value}
          onChange={handleChange}
          style={{ marginTop: 8, marginBottom: 8 }}
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />
        <DatePicker
          autoOk
          clearable
          value={form.targetDate}
          onChange={handleChangeDate("targetDate")}
          fullWidth
          inputVariant="outlined"
          format="dd/MM/yyyy"
          margin="normal"
          label="Target Date (optional)"
        />
      </DialogContent>
      <DialogActions>
        {handleAddIssueDialog && (
          <Tooltip
            placement="bottom"
            title={_.isNil(id) ? "Issue cannot be attached to pending success criteria, please save rock changes to do so" : ""}
          >
            <div>
              <Button disabled={_.isNil(id)} onClick={handleAddIssueDialog(id, "SuccessCriteria", form.value)} color="primary">
                Add Issue
              </Button>
            </div>
          </Tooltip>
        )}
        <Button onClick={handleChangeComplete} color="primary" style={{ marginRight: "auto" }}>
          Mark as {form.done ? "Incomplete" : "Complete"}
        </Button>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SuccessCriteriaList;
