import React, { useState } from "react";
import _ from "lodash";

import SavedEmails from "../../components/SavedEmails/SavedEmails";

// import ToggleButton from '@material-ui/lab/ToggleButton';
// import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';

function SavedNotifications() {
  const [view, setView] = useState("emails");

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "calc(100vh - 120px)", maxHeight: "calc(100vh - 120px)" }}>
      {/* <div style={{ padding: "32px", display: "flex", justifyContent: "center", flex: "0 0 auto" }}>
        <ToggleButtonGroup value={view} exclusive onChange={(e, name) => setView(_.isNil(name) ? view : name)}>
                    <ToggleButton value="emails" data-cy="saved-notifications-emails-tab">
                        Emails
                    </ToggleButton>
                    <ToggleButton value="sms" data-cy="saved-notifications-sms-tab">
                        SMS
                    </ToggleButton>
                    <ToggleButton value="push" data-cy="saved-notifications-push-tab">
                        App
                    </ToggleButton>
                </ToggleButtonGroup>
      </div> */}
      {view === "emails" && <SavedEmails />}
      {/* {view === 'sms' && <SavedSMS />}
            {view === 'push' && <SavedPush />} */}
    </div>
  );
}

export default SavedNotifications;
