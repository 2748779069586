import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";
import styles from "../WeeklyReview.module.scss";
import { Typography, Button } from "@material-ui/core";
import { yellow } from "@material-ui/core/colors";
import { Rating } from "@material-ui/lab";
import StarIcon from "@material-ui/icons/Star";
import NotesButton from "../../../components/Notes/NotesButton";
import UserAvatar from "../../../components/UserAvatar/UserAvatar";

const WrapUp = ({ handleExit, isAdmin, usersAttending, data }) => {
  const params = useParams();
  const [rating, setRating] = useState(new Array(usersAttending.length).fill(0));
  const [hoverRating, setHoverRating] = useState([null, -1]);

  const usersAllowedToRate = useMemo(() => _.get(data, "meeting.users", []).filter(({ id }) => usersAttending.includes(id)), [data]);
  const overallRating = useMemo(() => {
    const total = rating.reduce((sum, val) => sum + val, 0);
    return Math.round((total / rating.length) * 10) / 10;
  }, [rating]);

  const handleChange = (e, val) => {
    const { name } = e.target;
    const clonedRating = _.cloneDeep(rating);
    clonedRating[name] = val;

    setRating(clonedRating);
  };

  const handleHoverStar = (id) => (e, val) => {
    setHoverRating([id, val]);
  };

  return (
    <div>
      <Typography variant="h4" align="center">
        How was this meeting?
      </Typography>
      {isAdmin &&
        usersAllowedToRate.map((user, i) => {
          const showHover = hoverRating[0] === user.id && hoverRating[1] > 0;
          return (
            <div className={styles.rating} key={i}>
              <div className={styles.flexSpaceBetween}>
                <UserAvatar user={user} />
                <Rating
                  size="large"
                  value={rating[i]}
                  onChange={handleChange}
                  name={`${i}`}
                  max={10}
                  onChangeActive={handleHoverStar(user.id)}
                />

                {showHover && <div className={styles.hoverRating}>{hoverRating[1]}</div>}
              </div>
            </div>
          );
        })}

      {
        <div className={styles.overallRating}>
          <span>
            {overallRating} <StarIcon color={yellow[400]} />
          </span>
        </div>
      }

      <Typography variant="h5" align="center" className={styles.flexCenter}>
        Notes:
        <NotesButton id={params.meeting_id} core="wrapUp" model="meeting" size={1.25} />
      </Typography>

      <div className={styles.newMeeting}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleExit(rating)}
          className={styles.divider}
          disabled={isAdmin && !rating}
        >
          Exit
        </Button>
      </div>
    </div>
  );
};

export default WrapUp;
