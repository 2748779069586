import React, { createContext, useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

export const SnackbarContext = createContext();

export const SnackbarContextProvider = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [snackbar, setSnackbar] = useState();
  const [severity, setSeverity] = useState();

  const handleClose = () => {
    setOpen(false);
  };

  const handleExited = () => {
    setSnackbar(undefined);
  };

  const snack = (message, variant = "success") => {
    // Possible Variants: success | error | info | warning
    setSeverity(variant);
    setSnackbar(message);
    setOpen(true);
  };

  return (
    <SnackbarContext.Provider value={{ snack }}>
      <Snackbar
        open={open}
        onClose={handleClose}
        TransitionProps={{
          onExited: handleExited,
        }}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert variant="filled" severity={severity} elevation={5} onClose={handleClose}>
          {snackbar}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};
