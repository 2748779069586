import React from "react";
import styles from "./Reports.module.scss";
import { has } from "lodash";
import { MenuItem, Typography, FormControl, InputLabel, Select } from "@material-ui/core";

const QUARTER_MENU = [
  { name: "Q1", value: 1 },
  { name: "Q2", value: 2 },
  { name: "Q3", value: 3 },
  { name: "Q4", value: 4 },
];
const STATUS_MENU = [
  { name: "Complete", value: "complete" },
  { name: "Incomplete", value: "incomplete" },
  { name: "On Track", value: "on track" },
  { name: "Off Track", value: "off track" },
];

const FilterMenus = ({ filters, handleChange, users, objectives }) => {
  return (
    <div>
      <FormControl variant="outlined" className={styles.formControl} size="small">
        <InputLabel>Quarter</InputLabel>
        <Select
          value={filters.quarter}
          onChange={handleChange}
          name="quarter"
          variant="outlined"
          label="Quarter"
          className={styles.select}
          multiple
        >
          {QUARTER_MENU.map(({ name, value }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={styles.formControl} size="small">
        <InputLabel>Status</InputLabel>
        <Select
          value={filters.status}
          onChange={handleChange}
          name="status"
          variant="outlined"
          label="Status"
          className={styles.select}
          multiple
        >
          {STATUS_MENU.map(({ name, value }) => (
            <MenuItem key={value} value={value}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={styles.formControl} size="small">
        <InputLabel>Owner</InputLabel>
        <Select
          value={filters.owner}
          onChange={handleChange}
          name="owner"
          variant="outlined"
          label="Owner"
          className={styles.selectLong}
          multiple
          renderValue={() => `${filters.owner.length} User${filters.owner.length === 1 ? "" : "s"} Selected`}
        >
          {users &&
            users.map(({ id, name, plan }) => {
              return (
                <MenuItem key={id} value={id}>
                  {name.first} {name.last} {has(plan, "departmentName") && <span className={styles.label}>({plan.departmentName})</span>}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <FormControl variant="outlined" className={styles.formControl} size="small">
        <InputLabel>Objective</InputLabel>
        <Select
          value={filters.objective}
          onChange={handleChange}
          name="objective"
          variant="outlined"
          label="Objective"
          className={styles.selectLong}
          multiple
          renderValue={() => `${filters.objective.length} Objective${filters.objective.length === 1 ? "" : "s"} Selected`}
        >
          {objectives &&
            objectives.map(({ id, value }) => {
              return (
                <MenuItem value={id} key={id} className={styles.menuItemLong}>
                  <Typography variant="inherit" noWrap>
                    {value}
                  </Typography>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterMenus;
