import React, { useState, useEffect } from "react";
import _ from "lodash";
import styles from "./Rock.module.scss";

import Icon from "@mdi/react";
import { mdiCheckboxBlankCircle, mdiDecagram } from "@mdi/js";
import { green, red } from "@material-ui/core/colors";
import { List, ListItem, ListItemText, ListItemIcon, Collapse } from "@material-ui/core";

import Menu from "../Menu/Menu";
import NotesButton from "../Notes/NotesButton";
import UserAvatars from "../UserAvatars/UserAvatars";
import PlanPill from "../PlanPill/PlanPill";
import { formatAs } from "../../utils/dates";

const RockClone = ({ isDragging, canEdit, rock }) => {
  const { id, value, users, successCriterias, status, plan } = rock;
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const done = successCriterias.reduce((sum, sc) => (sc.done ? sum + 1 : sum), 0);
    setProgress(done);
  }, [successCriterias]);

  const percentageCompleted = (progress / successCriterias.length) * 100;
  const isComplete = percentageCompleted === 100 && status === "complete";
  const showSC = JSON.parse(sessionStorage.getItem(`oneYearObjs.${id.toString()}.showSC`)) || false;

  return (
    <List
      dense
      className={`${isDragging ? styles.listDragging : styles.list} ${percentageCompleted > 0 ? styles.hasRockProgress : ""} ${
        isComplete ? styles.rockComplete : ""
      }`}
    >
      <ListItem className={styles.cursor}>
        <ListItemIcon>
          <UserAvatars users={users} />
        </ListItemIcon>
        <ListItemText>
          {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
          <span className={isComplete ? styles.complete : undefined}>{value}</span>
        </ListItemText>
        <NotesButton />
        {canEdit && <Menu />}
      </ListItem>

      <Collapse className={styles.droppable} in={showSC}>
        {_.isEmpty(successCriterias) ? (
          <ListItem>
            <ListItemText className={styles.listItemNoRock}>
              No success criteria. {canEdit && <u className={styles.cursor}>Add one</u>}
            </ListItemText>
          </ListItem>
        ) : (
          successCriterias.map(({ id: scId, value, targetDate, done }) => {
            return (
              <ListItem key={`${scId}_${id}`}>
                <ListItemIcon className={styles.listIcon}>
                  {done ? (
                    <Icon path={mdiCheckboxBlankCircle} size={1} color={green[300]} />
                  ) : (
                    <Icon path={mdiDecagram} size={1} color={red[300]} />
                  )}
                </ListItemIcon>
                <ListItemText className={done ? styles.complete : styles.listItem}>
                  <span>{value}</span>
                  <span className={styles.targetDate}>{targetDate && ` (${formatAs(targetDate, "MMM dd, yyyy")})`}</span>
                </ListItemText>
              </ListItem>
            );
          })
        )}
      </Collapse>

      <div className={`${styles.rockProgress} ${isComplete ? styles.rpComplete : ""}`} style={{ width: `${percentageCompleted}%` }}>
        {percentageCompleted > 0 && <div style={{ padding: "0 4px" }}>{Math.round(percentageCompleted * 100) / 100}%</div>}
      </div>
    </List>
  );
};

export default RockClone;
