import React, { useState, useRef, useEffect } from "react";
import styles from "./AvatarEditDialog.module.scss";
import AvatarEditor from "react-avatar-editor";

import { Dialog, DialogContent, DialogActions, Button, Slider } from "@material-ui/core";

const AvatarEditDialog = ({ open, handleClose, handleSave, src }) => {
  const imageRef = useRef(null);
  const [scale, setScale] = useState(1.2);
  const [width, setWidth] = useState(250);

  const handleScale = (e, newValue) => {
    setScale(newValue);
  };

  useEffect(() => {
    /* Need to reset the width back to the (unused) default value on image change because otherwise the AvatarEditor component might use the previous image's width to 
    display the current image (i.e. occurs when previous image is wider than current image)*/
    setWidth(250);
  }, [src])

  const onImgLoad = (img) => {
    /* The image object that's returned upon load automatically adjusts its dimensions such that it keeps its original aspect ratio but with a height of 250px as hardset 
    in the height prop of the AvatarEditor component below, hence we just need to set the width prop to the automatically adjusted width */
    setWidth(Math.floor(img.width));
  }

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }} 
      maxWidth={false}
    >
      <DialogContent>
        <AvatarEditor
          ref={imageRef}
          image={src}
          width={width}
          height={250}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={scale}
          onLoadSuccess={onImgLoad}
        />
        <Slider value={scale} onChange={handleScale} min={1} max={3} step={0.1} />
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSave(imageRef)} color="primary" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AvatarEditDialog;
