import React from "react";
import _ from "lodash";
import { getContrastText, getDeptShortName } from "../../utils/misc";
import styles from "./PlanPill.module.scss";

const PlanPill = ({ plan, number, noPlanVal, noIndent, noMargin }) => {
  if (_.isNil(plan)) {
    return (
      <span
        className={styles.pillWrapperNone}
        style={{
          backgroundColor: "#ffffff",
          color: "#757575",
          margin: noMargin ? 0 : 8,
          marginLeft: noMargin || noIndent ? 0 : 8,
          minWidth: 55,
          textAlign: "center",
        }}
      >
        <span className={styles.pillText}>{noPlanVal ? noPlanVal : "NONE"}</span>
      </span>
    );
  }

  const { departmentName, color, shortName } = plan;
  const fallbackShortName = getDeptShortName(departmentName, shortName);
  return (
    <span
      className={styles.pillWrapper}
      style={{
        backgroundColor: color ? color : "#000000",
        color: getContrastText(color),
        margin: noMargin ? 0 : 8,
        marginLeft: noMargin || noIndent ? 0 : 8,
        minWidth: 55,
        textAlign: "center",
      }}
    >
      <span className={styles.pillText}>
        {fallbackShortName}
        {!_.isNil(number) && ` - ${number}`}
      </span>
    </span>
  );
};

export default PlanPill;
