import React from "react";
import { useParams } from "react-router-dom";
import styles from "../WeeklyReview.module.scss";
import { Typography } from "@material-ui/core";
import NotesButton from "../../../components/Notes/NotesButton";
import Projects from "../../../components/Projects/Projects";

const ProjectUpdate = ({ projects }) => {
  const params = useParams();

  return (
    <div>
      <Typography variant="h4" align="center" paragraph>
        What's the status of our projects?
      </Typography>
      <Projects projects={projects} />
      <Typography variant="h5" align="center" className={styles.flexCenter}>
        Notes:
        <NotesButton id={params.meeting_id} core="projectUpdate" model="meeting" size={1.25} />
      </Typography>
    </div>
  );
};

export default ProjectUpdate;
