import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@material-ui/core";

export default function CustomConfirmDialog({ dialogData, open, onClose, onExited }) {
  const { title = "", message = "", callback, confirmButtonText = "Proceed" } = dialogData;
  return (
    <Dialog open={open} onClose={onClose} onExited={onExited}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography variant="body2">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={callback} variant="contained" color="primary" autoFocus>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
