import _ from "lodash";
import React, { useMemo } from "react";
import Tree from "react-d3-tree";
import { useToppedTree } from "./hooks/useToppedTree";
import styles from "./AccountabilityChart.module.scss";
import NodeCard from "./NodeCard";

const TreeGraph = React.forwardRef(
  (
    {
      cards,
      displayOptions,
      users,
      handleChangeNode,
      handleUpdateChartAndAddHistory,
      handleSubmit,
      handleAddChildNode,
      handleAddAssistantNode,
      handleDeleteLeafNode,
      disableEditing,
    },
    ref
  ) => {
    const treeData = useMemo(() => constructTreeFromNodes(cards), [cards]);
    const nodeSize = { x: 350, y: 300 };
    const separation = { siblings: 1.25, nonSiblings: 2 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: -175, y: -150 };

    const [translate, containerRef] = useToppedTree(); // position node to the top center position when rendered

    return (
      <div className={styles.treeContainer} ref={containerRef}>
        <div ref={ref}></div>
        <Tree
          data={treeData}
          translate={translate}
          ref={ref}
          hasInteractiveNodes={true}
          depthFactor={410}
          nodeSize={nodeSize}
          separation={separation}
          transitionDuration="1000"
          pathFunc={customPathFunc}
          orientation="vertical"
          renderCustomNodeElement={(rd3tProps) =>
            renderForeignObjectNode({
              ...rd3tProps,
              foreignObjectProps,
              users,
              handleChangeNode,
              handleUpdateChartAndAddHistory,
              handleSubmit,
              handleAddChildNode,
              handleAddAssistantNode,
              handleDeleteLeafNode,
              displayOptions,
              disableEditing,
            })
          }
        />
      </div>
    );
  }
);

export default TreeGraph;

const renderForeignObjectNode = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps,
  users,
  handleChangeNode,
  handleUpdateChartAndAddHistory,
  handleSubmit,
  handleAddChildNode,
  handleAddAssistantNode,
  handleDeleteLeafNode,
  displayOptions,
  disableEditing,
}) => {
  return (
    <>
      <foreignObject {...foreignObjectProps}>
        <NodeCard
          node={nodeDatum}
          users={users}
          toggleNode={toggleNode}
          handleChangeNode={handleChangeNode}
          handleUpdateChartAndAddHistory={handleUpdateChartAndAddHistory}
          handleSubmit={handleSubmit}
          handleAddChildNode={handleAddChildNode}
          handleAddAssistantNode={handleAddAssistantNode}
          handleDeleteLeafNode={handleDeleteLeafNode}
          displayOptions={displayOptions}
          disableEditing={disableEditing}
        />
      </foreignObject>
    </>
  );
};

const constructTreeFromNodes = (nodes = []) => {
  if (_.isNil(nodes) || _.isEmpty(nodes)) {
    return {};
  }
  // create special flag for assistant nodes
  const nodesCopy = _.cloneDeep(nodes);
  const combinedAssistantNodeIds = nodesCopy.reduce((arr, node) => arr.concat(node.assistantNodes), []);
  nodesCopy.forEach((node) => {
    if (combinedAssistantNodeIds.includes(node.cardId)) {
      _.set(node, "isAssistant", true);
    }
  });
  const nodesById = _.keyBy(nodesCopy, "cardId");
  // find root node
  let rootNode = _.find(nodesCopy, { parentNode: null });

  // recursively populate all the child nodes from ids
  const tree = replaceChildNodeIdsWithNode(nodesById, rootNode);

  return tree;
};

// recursively contruct node tree, this operation will impact performance when updating large trees
const replaceChildNodeIdsWithNode = (nodesById, node) => {
  const combinedChildren = [...node.childNodes, ...node.assistantNodes];
  if (_.isEmpty(combinedChildren)) {
    node.children = [];
    return node;
  } else {
    let children = [];
    combinedChildren.forEach((nodeId) => {
      const currentNode = nodesById[nodeId];
      const populatedNode = replaceChildNodeIdsWithNode(nodesById, currentNode);
      children.push(populatedNode);
    });
    node.children = children;
    return node;
  }
};

// slightly different from the default 'step' path func
const customPathFunc = (linkDatum, orientation) => {
  const { source, target } = linkDatum;
  const deltaY = target.y - source.y;
  const isAssistantTargetNode = _.get(target, "data.isAssistant", false);

  const verticalOffSet = 40;
  // draw a different path if it is assistant
  let customizedVerticalPath = `M${source.x},${source.y - verticalOffSet} V${source.y + deltaY / 2} H${target.x} V${
    target.y - verticalOffSet
  }`;
  if (isAssistantTargetNode) {
    // check if child is on left or right side of the parent node, use different ratio to prevent lines from intercepting
    const actualOffsetCoefficient = target.x < source.x + 143 ? 0.57 : 0.43;
    customizedVerticalPath = `M${source.x + 143},${source.y - verticalOffSet} V${source.y + deltaY * actualOffsetCoefficient} H${
      target.x
    } V${target.y - verticalOffSet}`;
  }

  return orientation === "horizontal"
    ? `M${source.y},${source.x} H${source.y + deltaY / 2} V${target.x} H${target.y}`
    : customizedVerticalPath;
};
