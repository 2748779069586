import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import styles from "../WeeklyReview.module.scss";
import { FetchContext } from "../../../context/fetchContext";
import { SnackbarContext } from "../../../context/snackbarContext";
import { NotesContext } from "../../../context/notesContext";
import Todos from "../../../components/Todos/Todos";
import { Typography, Card, CardContent, CardActions, MenuItem, Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import UserAvatar from "../../../components/UserAvatar/UserAvatar";
import Status from "../../../components/Status/Status";
import Menu from "../../../components/Menu/Menu";

import { formatAs, diffInDays } from "../../../utils/dates";
import { getTodoTieIn } from "../../../utils/graphql";

const TodoList = ({ todos, usersAttending }) => {
  const { requestFetch } = useContext(FetchContext);
  const { notes } = useContext(NotesContext);
  const { snack } = useContext(SnackbarContext);
  const [selectedTodos, setSelectedTodos] = useState([]);
  const [tieIns, setTieIns] = useState({});
  const [updateTodo] = useMutation(UPDATE_TODO);

  const handleCheckbox = (id) => () => {
    const index = selectedTodos.indexOf(id);
    const issues = _.cloneDeep(selectedTodos);
    if (index < 0) {
      if (issues.length < 3) {
        issues.push(id);
      }
    } else {
      issues.splice(index, 1);

      const oldTieIns = _.cloneDeep(tieIns);
      delete oldTieIns[id];
      setTieIns(oldTieIns);
    }
    setSelectedTodos(issues);
  };

  const handleUpdatePriority = (id, value, priority) => async () => {
    const ok = await updateTodo({ variables: { id, priority } });

    if (ok.data.updateTodo) {
      snack(`Marked "${value}" as ${priority} priority`);
      requestFetch();
    }
  };

  const handleUpdate = (id, value, done) => async () => {
    const ok = await updateTodo({ variables: { id, done: !done } });

    if (ok.data.updateTodo) {
      snack(`Marked "${value}" as ${!done ? "resolved" : "incomplete"}`);
      handleCheckbox(id)();
      requestFetch();
    }
  };

  const handleNotes = (id) => () => {
    notes(id, "todo");
  };

  useEffect(() => {
    selectedTodos.forEach(async (id) => {
      const { referenceId, referenceModel } = _.find(todos, (t) => t.id === id);
      if (referenceModel) {
        const model = referenceModel === "Issue" ? "Todo" : referenceModel;
        const tieIn = await getTodoTieIn(referenceId, model);
        const value = _.get(tieIn, `data.${_.camelCase(model)}.value`);

        setTieIns({ ...tieIns, [id]: value });
      }
    });
  }, [selectedTodos]);

  return (
    <div>
      <Todos todos={todos} filterUsers={usersAttending} handleCheckbox={handleCheckbox} checked={selectedTodos} />

      <div className={styles.issueCardContainer}>
        {_.isEmpty(selectedTodos) ? (
          <Typography align="center" variant="h3" className={styles.labelMarginTop}>
            Please select up to 3 todos to highlight
          </Typography>
        ) : (
          <div className={styles.flexCenterWrap}>
            {selectedTodos.map((id) => {
              const { value, createdAt, priority, referenceModel, user, done } = _.find(todos, (t) => t.id === id);
              const daysAgo = Math.abs(diffInDays(createdAt, new Date()));

              return (
                <Card className={styles.issueCard} key={id}>
                  <CardContent>
                    <div className={styles.flexSpaceBetween}>
                      <Typography className={styles.label} paragraph>
                        {formatAs(createdAt, "MMM d")} ({daysAgo} {daysAgo === 1 ? "day" : "days"} ago)
                      </Typography>
                      <IconButton onClick={handleCheckbox(id)}>
                        <CloseIcon />
                      </IconButton>
                    </div>

                    <Typography variant="h4" paragraph>
                      {value}
                    </Typography>

                    <Typography variant="h6" className={styles.label}>
                      Tie In
                    </Typography>
                    <Typography variant="h5" paragraph>
                      {referenceModel ? (
                        <span>
                          {_.startCase(referenceModel)}: {tieIns[id]}
                        </span>
                      ) : (
                        <span>No tie in</span>
                      )}
                    </Typography>

                    <Typography variant="h6" className={styles.label}>
                      Accountable
                    </Typography>
                    <div className={styles.parapgraph}>
                      <UserAvatar user={user} variant="h5" />
                    </div>

                    <div className={styles.flexSpaceBetween}>
                      <div>
                        <Typography variant="h6" className={styles.label}>
                          Status
                        </Typography>
                        <Typography variant="h5" paragraph>
                          <Status done={done} />
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="h6" className={styles.label}>
                          Priority
                        </Typography>
                        <Typography variant="h5" paragraph>
                          <div className={styles.flexCenter}>
                            <Status priority={priority} />
                            <Menu icon="arrow">
                              <MenuItem onClick={handleUpdatePriority(id, value, "high")} disabled={priority === "high"}>
                                Mark as on high priority
                              </MenuItem>
                              <MenuItem onClick={handleUpdatePriority(id, value, "medium")} disabled={priority === "medium"}>
                                Mark as on medium priority
                              </MenuItem>
                              <MenuItem onClick={handleUpdatePriority(id, value, "low")} disabled={priority === "low"}>
                                Mark as on low priority
                              </MenuItem>
                            </Menu>
                          </div>
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                  <CardActions className={styles.flexCenter}>
                    <Button variant="outlined" color="primary" onClick={handleNotes(id)}>
                      Notes
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleUpdate(id, value, done)}>
                      {done ? "unresolve" : "resolve"}
                    </Button>
                  </CardActions>
                </Card>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default TodoList;

const UPDATE_TODO = gql`
  mutation($id: ID!, $done: Boolean, $priority: String) {
    updateTodo(id: $id, done: $done, priority: $priority)
  }
`;
