import React from "react";
import styles from "../WeeklyReview.module.scss";
import _ from "lodash";
import {
  Typography,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  Checkbox,
  Card,
  CardContent,
  Button,
} from "@material-ui/core";
import UserAvatar from "../../../components/UserAvatar/UserAvatar";

const Start = ({ users, usersAttending, handleUsersAttending, handleStart }) => {
  return (
    <>
      <Typography variant="h5">Who's attending this meeting?</Typography>
      <Card>
        <CardContent>
          <List>
            {users &&
              users.map((user) => {
                const { id } = user;
                return (
                  <ListItem key={id} button onClick={handleUsersAttending(id)}>
                    <ListItemText>
                      <UserAvatar user={user} />
                    </ListItemText>
                    <ListItemSecondaryAction>
                      <Checkbox edge="end" color="primary" checked={usersAttending.includes(id)} onChange={handleUsersAttending(id)} />
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
          </List>
        </CardContent>
      </Card>
      <Typography align="center">
        <Button color="primary" variant="contained" onClick={handleStart} disabled={_.isEmpty(usersAttending)} className={styles.divider}>
          Begin Meeting
        </Button>
      </Typography>
    </>
  );
};

export default Start;
