import React, { useEffect, useContext, useState } from "react";
import styles from "./Dashboard.module.scss";
import gql from "graphql-tag";
import { useMutation, useQuery } from "@apollo/client";
import { Collapse, Container, Grid, IconButton, LinearProgress, MenuItem, Paper, Tooltip, Typography, withStyles } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FetchContext } from "../../context/fetchContext";
import Loading from "../../components/Loading/Loading";
import _ from "lodash";
import useCorpPlan from "../../hooks/useCorpPlan";
import { ROCK_FIELDS, TODO_FIELDS, USER_FIELDS, WEEKLY_TARGET_FIELDS } from "../../utils/fragments";

import welcomImg from "../../assets/img/welcome.png";
import Icon from "@mdi/react";
import {
  mdiAlert,
  mdiAlertDecagram,
  mdiChartTimelineVariant,
  mdiCheck,
  mdiChevronDown,
  mdiChevronUp,
  mdiEye,
  mdiHammerWrench,
  mdiMap,
  mdiRhombus,
} from "@mdi/js";
import { isAuthed } from "../../utils/authorization";
import ConfirmDeletionDialog from "../../components/ConfirmDeletionDialog/ConfirmDeletionDialog";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import Menu from "../../components/Menu/Menu";
import { default as EditRockDialog } from "../../components/Rock/EditDialog";
import { SnackbarContext } from "../../context/snackbarContext";
import { DialogContext } from "../../context/dialogContext";
import { default as EditTodoDialog } from "../../components/Todos/EditDialog";
import { isBefore } from "date-fns";
import { getPriorityValue } from "../../utils/misc";
import { fiscalYearStart, getCurrentQuarter, getQuarterProgress, year } from "../../utils/dates";
import DashboardTodo from "./DashboardTodo";

import { Pie } from "react-chartjs-2";
import { Chart, ArcElement, Tooltip as ChartTooltip, Legend } from "chart.js";
import PlanPill from "../../components/PlanPill/PlanPill";
Chart.register(ArcElement, ChartTooltip, Legend);

const CustomLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#d9d9d9",
  },
  bar: {
    transitionDuration: "1s",
    transitionTimingFunction: "ease-in-out",
    background: "linear-gradient(45deg, #4a6ec3, #e95ca7)",
    borderRadius: "4px",
  },
})(LinearProgress);

const CustomCircularProgress = withStyles({
  circle: {
    transitionDuration: "1s",
  },
})(CircularProgress);

const Dashboard = ({ user, params, org }) => {
  const { fetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { dialog, setDialog } = useContext(DialogContext);
  const { requestFetch } = useContext(FetchContext);
  const fiscalYear = org.fiscalYear;
  const { corpForSelectedYear, setCorpForSelectedYear, corpPlans } = useCorpPlan({ orgId: params.org, fiscalYear });
  const [progressOpen, setProgressOpen] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  // const [userProgress, setUserProgress] = useState(0);
  // const [teamProgress, setTeamProgress] = useState(0);

  const [itemType, setItemType] = useState(""); //<'rock' | 'todo'>
  const [selectedItem, setSelectedItem] = useState({});
  const { confirmOpen, handleConfirmOpen, handleDelete } = useConfirmDelete({
    id: _.get(selectedItem, "id"),
    value: _.get(selectedItem, "value"),
    itemType,
  });

  const [editRockDialog, setEditRockDialog] = useState(false);
  const [editTodoDialog, setEditTodoDialog] = useState(false);
  const [category, setCategory] = useState("todo"); // <'todo' | 'issue'>

  const [deleteRock] = useMutation(DELETE_ROCK, {
    update(cache, { data: { deleteRock } }) {
      const { deletedRock, deletedSuccessCriterias } = deleteRock;
      const deletedRockId = cache.identify(deletedRock);
      const deletedScIds = deletedSuccessCriterias.map((sc) => cache.identify(sc));
      cache.modify({
        fields: {
          rocks: (existingRocks) => {
            return existingRocks.filter((rockRef) => {
              const rockId = cache.identify(rockRef);
              return rockId !== deletedRockId;
            });
          },
          successCriterias: (existingScs) => {
            return existingScs.filter((scRef) => {
              const scId = cache.identify(scRef);
              return !deletedScIds.includes(scId);
            });
          },
        },
      });
    },
  });

  const [deleteTodo] = useMutation(DELETE_TODO);

  const { data, loading, refetch } = useQuery(GET_DATA, {
    variables: {
      organization: params.org,
      user: user.user.id,
      sharedPlanId: user.departmentFilter.sharedPlanId,
      oneYearCorpPlan: corpForSelectedYear.id,
    },
  });

  useEffect(() => {
    refetch();
  }, [fetch]);

  useEffect(() => {
    if (data) {
      const { organizationSetupProgress, quarterlyProgress } = data;
      const progress = _.get(organizationSetupProgress, "progress", 0) * 100;
      // show animation
      setProgressValue(0);
      setTimeout(() => setProgressValue(progress), 300);

      // const userProgress =
      //   _.get(quarterlyProgress, "userTotal", 0) !== 0
      //     ? _.round(_.get(quarterlyProgress, "userCompleted", 0) / _.get(quarterlyProgress, "userTotal", 0), 2) * 100
      //     : 100;
      // setUserProgress(0);
      // setTimeout(() => setUserProgress(userProgress), 300);

      // const teamProgress =
      //   _.get(quarterlyProgress, "teamTotal", 0) !== 0
      //     ? _.round(_.get(quarterlyProgress, "teamCompleted", 0) / _.get(quarterlyProgress, "teamTotal", 0), 2) * 100
      //     : 100;
      // setTeamProgress(0);
      // setTimeout(() => setTeamProgress(teamProgress), 300);
    }
  }, [data]);

  const handleAddIssueDialog =
    (referenceId = null, referenceModel = null, value = null, user = null) =>
    () => {
      setDialog({
        ...dialog,
        addTodoDialog: {
          open: true,
          category: "issue",
          referenceId,
          referenceModel,
          value,
          user,
          planId: corpForSelectedYear.id,
        },
      });
    };

  const handleEditRock = (open, rock) => () => {
    if (open) {
      setSelectedItem(rock);
    }
    setEditRockDialog(open);
  };
  const handleDeleteRock = (rock) => () => {
    setItemType("rock");
    setSelectedItem(rock);
    handleConfirmOpen(true)();
  };

  const handleEditTodo = (open, todo) => () => {
    if (open) {
      setCategory(todo.category);
      setSelectedItem(todo);
    }
    setEditTodoDialog(open);
  };

  const handleDeleteTodo = (todo) => () => {
    setItemType("todo");
    setCategory(todo.category);
    setSelectedItem(todo);
    handleConfirmOpen(true)();
  };

  if (loading) return <Loading />;

  const canEdit = isAuthed(user.user, "department facilitator");
  const { organizationSetupProgress, todos, rocks, quarterlyProgress, weeklyTargets, objectives } = data;
  const { vision, strategy, execution } = organizationSetupProgress;
  const { userCompleted, userTotal, teamCompleted, teamTotal } = quarterlyProgress;
  const currentTime = new Date();
  const { quarterIndex: currentQuarter, totalDays, remainingDays } = getQuarterProgress(fiscalYear);
  const userPlans = _.get(data, "plans", []);
  const currentUserPlans = userPlans.filter((plan) => plan.year === fiscalYear);
  const currentYearStart = `${year(fiscalYearStart(fiscalYear))}`;
  const currentYearEnd = `${year(fiscalYear)}`;
  const currentYearRange = currentYearStart === currentYearEnd ? currentYearStart : `${currentYearStart} - ${currentYearEnd}`;
  const myProgressChartData = {
    labels: [
      `${userTotal === 0 ? "100%" : Math.round((userCompleted / userTotal) * 100)}% Completed`,
      `${userTotal === 0 ? "0%" : Math.round((1 - userCompleted / userTotal) * 100)}% Remaining`,
    ],
    datasets: [
      {
        data: [userTotal === 0 ? 1e-10 : userCompleted, userTotal === 0 ? 0 : userTotal - userCompleted],
        backgroundColor: ["rgb(235,36,140)", "rgb(76,58,120)"],
        hoverOffset: 4,
      },
    ],
  };
  const teamProgressChartData = {
    labels: [
      `${teamTotal === 0 ? "100%" : Math.round((teamCompleted / teamTotal) * 100)}% Completed`,
      `${teamTotal === 0 ? "0%" : Math.round((1 - teamCompleted / teamTotal) * 100)}% Remaining`,
    ],
    datasets: [
      {
        data: [teamTotal === 0 ? 1e-10 : teamCompleted, teamTotal === 0 ? 0 : teamTotal - teamCompleted],
        backgroundColor: ["rgb(235,36,140)", "rgb(76,58,120)"],
        hoverOffset: 4,
      },
    ],
  };

  const myObjectives = objectives.filter((obj) => _.get(obj, ["users", "0", "id"]) === _.get(user, "user.id"));
  const teamObjectives = objectives.filter((obj) => _.get(obj, ["users", "0", "id"]) !== _.get(user, "user.id"));

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          {/* <Hidden>
          <Grid item xs={12}>
            <Typography variant="h6" className={styles.label}>
              Dashboard
            </Typography>
            <SelectCorpYear
              corporatePlans={corpPlans}
              fiscalYear={fiscalYear}
              corpForSelectedYear={corpForSelectedYear}
              setCorpForSelectedYear={setCorpForSelectedYear}
            />
          </Grid>
        </Hidden> */}
          <Grid item xs={12}>
            <Paper elevation={0} variant="outlined">
              <div className={styles.container}>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "4px" }}>
                  <Typography variant="body2" style={{ fontWeight: "bold", display: "flex", alignItems: "center" }}>
                    Setup progress{" "}
                    {progressValue !== 100 && (
                      <IconButton onClick={() => setProgressOpen(!progressOpen)} size="small">
                        <Icon path={progressOpen ? mdiChevronUp : mdiChevronDown} size={0.75} color="darkpurple" />
                      </IconButton>
                    )}
                  </Typography>
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    {_.round(progressValue)} %
                  </Typography>
                </div>
                <div>
                  <CustomLinearProgress variant="determinate" value={progressValue} className={styles.linearProgress} />
                </div>
                {progressValue !== 100 && (
                  <div>
                    <Collapse in={progressOpen}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={4}>
                          <div className={styles.containerVision}>
                            <Typography variant="subtitle1" className={styles.itemTitleProgress}>
                              <Icon path={mdiEye} size={0.75} style={{ marginRight: "4px" }} /> Vision
                            </Typography>
                            <div>
                              <Typography variant="body2" className={styles.item}>
                                Core Purpose {!vision.includes("purpose") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                Core Value {!vision.includes("value") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                Core Competency {!vision.includes("competency") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                Core Market {!vision.includes("market") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                Core Offering {!vision.includes("offering") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                Core Value Proposition{" "}
                                {!vision.includes("valueProposition") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                Core Differentiator{" "}
                                {!vision.includes("differentiation") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                Core Journey {!vision.includes("process") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div className={styles.containerStrategy}>
                            <Typography variant="subtitle1" className={styles.itemTitleProgress}>
                              <Icon path={mdiMap} size={0.75} style={{ marginRight: "4px" }} /> Strategy
                            </Typography>
                            <div>
                              <Typography variant="body2" className={styles.item}>
                                5 Year Mission {!strategy.includes("bhag") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                3 Year Objective {!strategy.includes("3 year obj") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                1 Year Objective {!strategy.includes("1 year obj") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                3 Year Metric {!strategy.includes("3 year metric") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                1 Year Metric {!strategy.includes("1 year metric") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                              <Typography variant="body2" className={styles.item}>
                                Rock {!strategy.includes("rock") && <Icon path={mdiCheck} size={0.75} color="green" />}
                              </Typography>
                            </div>
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <div className={styles.containerExecution}>
                            <Typography variant="subtitle1" className={styles.itemTitleProgress}>
                              <Icon path={mdiHammerWrench} size={0.75} style={{ marginRight: "4px" }} /> Execution
                            </Typography>
                            <div>
                              <Typography variant="body2" className={styles.itemPlaceholder}>
                                Weekly Meeting Scheduled
                              </Typography>
                              <div>
                                {currentUserPlans.map((plan) => (
                                  <Typography key={`WMS-${plan.id}`} variant="body2" className={styles.itemNested} style={{ opacity: 0.5 }}>
                                    - {plan.departmentName}
                                    {/* {!execution.includes(`weekly meeting ${plan.id}`) && <Icon path={mdiCheck} size={0.75} color="green" />} */}
                                  </Typography>
                                ))}
                              </div>
                              <Typography variant="body2" className={styles.itemPlaceholder}>
                                Quarterly Monitor Scheduled{" "}
                              </Typography>
                              <div>
                                {currentUserPlans.map((plan) => (
                                  <Typography key={`QMS-${plan.id}`} variant="body2" className={styles.itemNested} style={{ opacity: 0.5 }}>
                                    - {plan.departmentName}
                                    {/* {!execution.includes(`quarterly monitor ${plan.id}`) && <Icon path={mdiCheck} size={0.75} color="green" />} */}
                                  </Typography>
                                ))}
                              </div>
                              <Typography variant="body2" className={styles.itemPlaceholder}>
                                Annual Crafting Scheduled{" "}
                              </Typography>
                              <div>
                                {currentUserPlans.map((plan) => (
                                  <Typography key={`ACS-${plan.id}`} variant="body2" className={styles.itemNested} style={{ opacity: 0.5 }}>
                                    - {plan.departmentName}
                                    {/* {!execution.includes(`annual crafting ${plan.id}`) && <Icon path={mdiCheck} size={0.75} color="green" />} */}
                                  </Typography>
                                ))}
                              </div>
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                    </Collapse>
                  </div>
                )}
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={0} className={styles.welcomeWidget}>
              <div className={styles.containerWelcome}>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  Welcome back
                </Typography>
                <Typography variant="h6" style={{ fontWeight: "bold", marginBottom: "16px" }}>
                  {_.get(user, "user.name.first")} {_.get(user, "user.name.last")}
                </Typography>
                <Typography variant="body2">
                  Your centralized hub for insights and control. Explore data, track progress, and make smarter decisions, all in one place.
                  Empowering your journey, every step of the way.
                </Typography>
              </div>
              <div className={styles.imgContainer}>
                <img src={welcomImg} alt="Ventrek Logo" className={styles.img} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={styles.containerCard}>
              <div style={{ display: "flex", flexDirection: "column", minWidth: 264 }}>
                <div className={styles.progressTitle}>
                  My {currentYearRange} Q{currentQuarter} Rock Progress
                </div>
                {/* <div className={styles.progressContainer}>
                <span className={styles.progressUnderlay}>
                  <CircularProgress variant="static" size={100} thickness={22} color="inherit" value={100} />
                </span>
                <CustomCircularProgress variant="static" size={100} thickness={22} color="inherit" value={userProgress} />
                <Typography variant="caption" className={styles.progressLabel}>
                  {_.round(teamProgress)} %
                </Typography>
              </div> */}
                <div className={styles.progressContainer}>
                  <Pie data={myProgressChartData} options={{ plugins: { legend: { position: "bottom" } } }} />
                </div>
              </div>
              <div className={styles.progressLegend}>
                <div>
                  <div className={styles.legendText}>Days</div>
                  <div className={styles.legendText}>Gone</div>
                  <div className={styles.valueCompleted}>{totalDays - remainingDays}</div>
                </div>
                <div>
                  <div className={styles.legendText}>Days</div>
                  <div className={styles.legendText}>Remaining</div>
                  <div className={styles.valueTotal}>{remainingDays}</div>
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div className={styles.containerCard}>
              <div style={{ display: "flex", flexDirection: "column", minWidth: 264 }}>
                <div className={styles.progressTitle}>
                  Team {currentYearRange} Q{currentQuarter} Rock Progress
                </div>
                {/* <div className={styles.progressContainer}>
                <span className={styles.progressUnderlay}>
                  <CircularProgress variant="static" size={100} thickness={22} color="inherit" value={100} />
                </span>
                <CustomCircularProgress variant="static" size={100} thickness={22} color="inherit" value={teamProgress} />
                <Typography variant="caption" className={styles.progressLabel}>
                  {_.round(teamProgress)} %
                </Typography>
              </div> */}
                <div className={styles.progressContainer}>
                  <Pie data={teamProgressChartData} options={{ plugins: { legend: { position: "bottom" } } }} />
                </div>
              </div>
              <div className={styles.progressLegend}>
                <div>
                  <div className={styles.legendText}>Days</div>
                  <div className={styles.legendText}>Gone</div>
                  <div className={styles.valueCompleted}>{totalDays - remainingDays}</div>
                </div>
                <div>
                  <div className={styles.legendText}>Days</div>
                  <div className={styles.legendText}>Remaining</div>
                  <div className={styles.valueTotal}>{remainingDays}</div>
                </div>
              </div>
              {/* {!_.isEmpty(teamObjectives) && (
                <div className={styles.objList}>
                  {_.sortBy(teamObjectives, ["plan.id"]).map((obj) => (
                    <div key={obj.id} className={styles.item}>
                      <PlanPill plan={obj.plan} noMargin={true} noIndent={true} />
                      <Typography variant="body2" className={styles.itemValue} style={{ marginLeft: 4 }}>
                        {obj.value}
                      </Typography>
                      <span className={styles.itemButton}></span>
                      <span
                        className={
                          obj.status === "on track"
                            ? styles.itemLabelOnTrack
                            : obj.status === "off track"
                            ? styles.itemLabelOffTrack
                            : styles.itemLabelComplete
                        }
                      >
                        {obj.status.toUpperCase()}
                      </span>
                    </div>
                  ))}
                </div>
              )} */}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <div className={styles.containerRocks}>
                  <Typography variant="body2" className={styles.itemTitleRock}>
                    <Icon path={mdiRhombus} size={0.75} color="white" style={{ minWidth: "16px" }} /> My Rocks / Success Criterias
                  </Typography>
                  <div className={styles.list}>
                    {_.sortBy(rocks || [], ["plan.year", "index"]).map((rock, idx) => {
                      const rockPastDue = rock.index < currentQuarter || isBefore(parseInt(fiscalYear), currentTime);

                      return (
                        <div key={rock.id} style={{ backgroundColor: idx % 2 === 1 ? "#f8f8f8" : "#ffffff" }}>
                          <div className={styles.itemRock}>
                            <span className={styles.itemLabelRock}>ROCK</span>{" "}
                            <Typography variant="body2" className={styles.itemValue}>
                              {rock.value}
                            </Typography>
                            <span className={styles.itemButton}>
                              {rockPastDue && (
                                <span className={styles.itemLabelPastDue}>
                                  <Tooltip title="Past Due">
                                    <Icon path={mdiAlert} size={0.75} color="#a71e19e6" />
                                  </Tooltip>
                                </span>
                              )}
                              {canEdit && (
                                <Menu>
                                  <MenuItem onClick={handleEditRock(true, rock)}>Edit</MenuItem>
                                  <MenuItem onClick={handleDeleteRock(rock)} className={styles.delete}>
                                    Delete
                                  </MenuItem>
                                </Menu>
                              )}
                            </span>
                          </div>
                          <div>
                            {_.sortBy(rock.successCriterias || [], ["targetDate"]).map((sc) => {
                              if (sc.done !== false) return null;
                              const isPastDue = !_.isNil(sc.targetDate) ? isBefore(new Date(parseInt(sc.targetDate)), currentTime) : false;
                              return (
                                <div key={sc.id} className={styles.itemNested}>
                                  <span className={styles.itemLabelSC}>SC</span> <Typography variant="body2">{sc.value}</Typography>{" "}
                                  {isPastDue && (
                                    <span className={styles.itemLabelPastDue}>
                                      <Tooltip title="Past Due">
                                        <Icon path={mdiAlert} size={0.75} color="#a71e19e6" />
                                      </Tooltip>
                                    </span>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div className={styles.containerTodos}>
                  <Typography variant="body2" className={styles.itemTitleTodo}>
                    <Icon path={mdiAlertDecagram} size={0.75} color="white" /> My Todos / Issues
                  </Typography>
                  <div className={styles.list}>
                    {_.sortBy(todos || [], [
                      function (todo) {
                        return getPriorityValue(todo.priority);
                      },
                      "createdAt",
                    ]).map((todo, idx) => (
                      <DashboardTodo
                        todo={todo}
                        idx={idx}
                        key={todo.id}
                        handleDeleteTodo={handleDeleteTodo}
                        handleEditTodo={handleEditTodo}
                        canEdit={canEdit}
                      />
                    ))}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <div className={styles.containerObjectives}>
                  <Typography variant="body2" className={styles.itemTitleObjective}>
                    <Icon path={mdiChartTimelineVariant} size={0.75} color="white" /> My Objectives
                  </Typography>
                  <div className={styles.list}>
                    {!_.isEmpty(myObjectives) && (
                      <div className={styles.objList}>
                        {_.sortBy(myObjectives, ["plan.id"]).map((obj) => (
                          <div key={obj.id} className={styles.item}>
                            <PlanPill plan={obj.plan} noMargin={true} noIndent={true} />
                            <Typography variant="body2" className={styles.itemValue} style={{ marginLeft: 4 }}>
                              {obj.value}
                            </Typography>
                            <span className={styles.itemButton}></span>
                            <span
                              className={
                                obj.status === "on track"
                                  ? styles.itemLabelOnTrack
                                  : obj.status === "off track"
                                  ? styles.itemLabelOffTrack
                                  : styles.itemLabelComplete
                              }
                            >
                              {obj.status.toUpperCase()}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
              {/* <Grid item xs={12}>
                    <div className={styles.containerMeetings}>
                      <Typography variant="body2" className={styles.itemTitle}>
                        Meeting
                      </Typography>
                    </div>
                  </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {(itemType !== "rock" || !_.isEmpty(selectedItem)) && (
        <EditRockDialog
          rock={selectedItem}
          open={editRockDialog}
          handleClose={handleEditRock(false)}
          snack={snack}
          handleAddIssueDialog={handleAddIssueDialog}
          planId={corpForSelectedYear.id}
        />
      )}
      {(itemType !== "todo" || !_.isEmpty(selectedItem)) && (
        <EditTodoDialog
          open={editTodoDialog}
          handleClose={handleEditTodo(false)}
          todo={selectedItem}
          category={category}
          org={params.org}
          user={user}
          snack={snack}
          requestFetch={requestFetch}
          planId={corpForSelectedYear.id}
        />
      )}
      <ConfirmDeletionDialog
        itemType={itemType === "todo" ? category : itemType}
        value={_.get(selectedItem, "value")}
        confirmOpen={confirmOpen}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleDelete(itemType === "rock" ? deleteRock : deleteTodo)}
      />
    </>
  );
};

export default Dashboard;

const GET_DATA = gql`
  query Dashboard_GetData($organization: ID!, $user: ID!, $sharedPlanId: ID, $oneYearCorpPlan: ID) {
    organizationSetupProgress(id: $organization) {
      vision
      strategy
      execution
      progress
    }

    objectives(organization: $organization, sharedPlanId: $sharedPlanId, corpPlan: $oneYearCorpPlan) {
      id
      value
      category
      number
      status
      plan {
        id
        departmentName
        color
        sharedPlanId
        shortName
      }
      users {
        id
      }
    }

    rocks(
      organization: $organization
      user: $user
      oneYearCorpPlan: $oneYearCorpPlan
      sharedPlanId: $sharedPlanId
      statuses: ["incomplete"]
    ) {
      ...RockFields     
    }

    todos(organization: $organization, user: $user, sharedPlanId: $sharedPlanId, oneYearCorpPlan: $oneYearCorpPlan, done: false) {
      ...TodoFields
    }

    quarterlyProgress(userId: $user, sharedPlanId: $sharedPlanId, organization: $organization) {
      userCompleted
      userTotal
      teamCompleted
      teamTotal
    }

    weeklyTargets(organization: $organization, user: $user, oneYearCorpPlan: $oneYearCorpPlan, sharedPlanId: $sharedPlanId) {
      ...WeeklyTargetFields
    }

    plans(organization: $organization, closed: false, category: "1 year") {
      id
      departmentName
      sharedPlanId
      year
    }
  }

  ${WEEKLY_TARGET_FIELDS}
  ${ROCK_FIELDS}
  ${TODO_FIELDS}
  ${USER_FIELDS}
`;

const DELETE_ROCK = gql`
  mutation DashboardDeleteRock($id: ID!) {
    deleteRock(id: $id) {
      deletedRock {
        id
      }
      deletedSuccessCriterias {
        id
      }
      updatedObjective {
        id
        rocks {
          id
        }
      }
      updatedPlan {
        id
        rocks {
          id
        }
      }
    }
  }
`;

const DELETE_TODO = gql`
  mutation ($id: ID!) {
    deleteTodo(id: $id) {
      todo {
        id: _id
      }
      successCriteria {
        id
        todos {
          id: _id
        }
      }
      rock {
        id
        todos {
          id: _id
        }
      }
      metric {
        id
        todos {
          id: _id
        }
      }
      objective {
        id
        todos {
          id: _id
        }
      }
    }
  }
`;
