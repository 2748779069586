// To be removed
import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./TheVision.module.scss";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { UserContext } from "../../context/userContext";
import { isAuthed } from "../../utils/authorization";
import {
  Container,
  Grid,
  Card,
  List,
  ListItem,
  ListItemText,
  Typography,
  CardContent,
  CardActions,
  Hidden,
  Button,
} from "@material-ui/core";
import {
  mdiHeart,
  mdiPillar,
  mdiSitemap,
  mdiAccountGroup,
  mdiGift,
  mdiChartAreasplineVariant,
  mdiStar,
  mdiArmFlex,
} from "@mdi/js";
import Loading from "../../components/Loading/Loading";
import NotesButton from "../../components/Notes/NotesButton";

import AddCoreDialog from "./AddCoreDialog";
import EditDialog from "./EditDialog";

import { addDays } from "date-fns";
import { getCoreDisplayName } from "../../utils/misc";

const info = {
  purpose: [mdiHeart, "Why we exist", "deepOrange", "purpose"],
  value: [mdiPillar, "What we stand for", "red", "values"],
  process: [mdiSitemap, "How we serve our customers", "blue", "process"],
  market: [mdiAccountGroup, "Who we serve", "pink", "market"],
  offering: [mdiGift, "What we offer", "orange", "offering"],
  valueProposition: [mdiChartAreasplineVariant, "What our customers get", "green", "value proposition"],
  differentiation: [mdiStar, "What makes us unique", "cyan", "differentiators"],
  competency: [mdiArmFlex, "What we do best", "lightGreen", "competency"],
};

const TheVision = ({ params }) => {
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { user } = useContext(UserContext);
  const [dialog, setDialog] = useState({ core: null });
  const [initForm, setInitForm] = useState({});
  const [addCore, setAddCore] = useState(false);
  const [coreData, setCoreData] = useState({});
  const [showSave, setShowSave] = useState([]);
  const { loading, data, refetch } = useQuery(GET_ORGANIZATION, {
    variables: { id: params.org, plan: user.departmentFilter.id },
  });
  const { data: closedCorpPlans } = useQuery(GET_CLOSED_CORP_PLANS, {
    variables: { id: params.org },
  });

  const [updateCore] = useMutation(UPDATE_CORE);

  const handleAddCore = (open) => () => {
    setAddCore(open);
  };

  const handleEditClose = () => {
    setDialog({ core: null });
  };

  const handleEditCore = (key, values) => () => {
    setInitForm({ values });
    setDialog({ ...dialog, core: { key, values } });
  };

  const handleDragEndCore = ({ draggableId, destination }) => {
    if (!destination) return;

    const { droppableId } = destination;
    if (!showSave.includes(droppableId)) setShowSave([...showSave, droppableId]);

    const clonedData = _.cloneDeep(coreData);
    const val = clonedData[droppableId].splice(draggableId, 1)[0];
    clonedData[droppableId].splice(destination.index, 0, val);

    setCoreData(clonedData);
  };

  const handleSaveCore = (name) => async () => {
    const values = coreData[name];
    const ok = await updateCore({
      variables: {
        id: params.org,
        name,
        values,
      },
    });

    if (ok.data.updateCore) {
      snack("Updated core details");
      requestFetch();

      const clonedSave = _.cloneDeep(showSave);
      const index = clonedSave.indexOf(name);
      clonedSave.splice(index, 1);
      setShowSave(clonedSave);
    }
  };

  useEffect(() => {
    refetch();
  }, [fetch, user.departmentFilter]);

  useEffect(() => {
    if (data) {
      const orgCore = _.get(data, "organization.core");
      if (!_.isNil(orgCore)) {
        setCoreData(orgCore);
      } else {
        setCoreData({});
      }
    }
  }, [data]);



  const handleDownloadPdf = () => {

    const pageHeight = 279.4; // 11"
    const pageWidth = 215.9; // 8.5"
    const orientation = "landscape";
    let tableCount = 0;
    let minFinalY = 0;
    let currStartY = 5;
    let prevRow = -1;
    const numColumnsToPrint = 4;
    const marginWidth = 4;
    const columnTableWidth = (orientation === "landscape") ? (pageHeight-marginWidth) / numColumnsToPrint : (pageWidth-marginWidth) / numColumnsToPrint;

    const doc = new jsPDF({
      orientation: orientation,
      unit: "mm",
      format: [pageWidth,  pageHeight]
    });


    sortedView.forEach(({ name, show }) => {
      if (!show || _.isNil(coreData[name])) return;
        //const coreNotes = notes[name];
        const values = coreData[name];
        
        //Header
        const CoreHeader = "Core " +  _.startCase(getCoreDisplayName(name));
        //const CoreSubHeader = info[name][1];
 
        //Rows
        let body = [];
        if (!_.isEmpty(values)) {
          body = values.map(value => {
            return [ value ];
          });
        }
        else {
          body.push(["Nothing to show"]);
        };
        
        const currCol = tableCount % numColumnsToPrint;
        const currRow = Math.floor(tableCount / numColumnsToPrint);


        if (prevRow !== currRow) {
          currStartY = minFinalY + 5;
          prevRow = currRow;
        }
        else if (doc.lastAutoTable.finalY > minFinalY) {
          minFinalY = doc.lastAutoTable.finalY;
        }

        doc.autoTable({
          head: [[CoreHeader]],
          body: body,
          startY: currStartY,
          showHead: 'firstPage',
          tableWidth: columnTableWidth - marginWidth,
          margin: { 
            left: columnTableWidth * currCol + marginWidth //marginWidth is for the margin before the first column
            },
        });

        tableCount++;
      });
   
    doc.save("ventrek vision.pdf");    
  };  


  const mostRecentClosedCorpPlan = _.orderBy(_.get(closedCorpPlans, "corporatePlans", []), (plan) => parseInt(plan.year), ["desc"])[0];
  const minYear = mostRecentClosedCorpPlan ? addDays(parseInt(_.get(mostRecentClosedCorpPlan, "year")), 1) : undefined;

  if (loading) return <Loading />;

  if (!data.organization) return null; //TODO remove this check
  const { fiscalYear, viewPreferences, notes } = data.organization;
  const { plans } = data;
  const currentYearPlans = _.filter(plans, (plan) => plan.year === fiscalYear && plan.category === '1 year')
  let coreItems = [];

  const sortedView = _.sortBy(viewPreferences, "position");

  sortedView.forEach(({ name, show }) => {


    if (!show || _.isNil(coreData[name])) return;
    const coreNotes = notes[name];
    const values = coreData[name];
    const displayName = getCoreDisplayName(name);

    coreItems.push(
      <Grid item xs={12} sm={6} md={4} lg={3} key={name} className={styles.cardGridContainer}>
  
            <Card className={styles.cardItem}>
              <CardContent>
                <Typography variant="h6" className={styles.label}>
                  Core {_.startCase(displayName)}
                </Typography>
                <Typography variant="subtitle1" className={styles.label}>
                  {info[name][1]}
                </Typography>                  
                <DragDropContext onDragEnd={handleDragEndCore}>
                  <Droppable droppableId={name}>
                    {(provided, snapshot) => {
                      return (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <List xs={12}>
                            {!_.isEmpty(values) ? (
                              values.map((item, i) => (
                                <Draggable
                                  draggableId={i.toString()}
                                  index={i}
                                  key={i}
                                  isDragDisabled={!isAuthed(user.user, "department facilitator")}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <ListItem
                                        key={i}
                                        divider
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <ListItemText>{item}</ListItemText>
                                      </ListItem>
                                    );
                                  }}
                                </Draggable>
                              ))
                            ) : (
                              <>
                                <ListItem variant="body2" className={styles.body} align="center">
                                  <ListItemText>
                                    Nothing to show
                                    <br />
                                    {isAuthed(user.user, "company admin") && (
                                      <Button onClick={handleEditCore(name, values)} color="primary">
                                        Add a Core {_.startCase(name)}
                                      </Button>
                                    )}
                                  </ListItemText>
                                </ListItem>
                              </>
                            )}
                          </List>
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
                {showSave.includes(name) && (
                  <div className={styles.editButton}>
                    <Button color="primary" variant="contained" onClick={handleSaveCore(name)}>
                      Save
                    </Button>
                  </div>
                )}
              </CardContent>
              <CardActions className={styles.cardActions}>
                <NotesButton id={params.org} core={name} model="organization" size={1.25} doc={{ notes: coreNotes }} />
                {isAuthed(user.user, "company admin") && (
                  <Button variant="outlined" onClick={handleEditCore(name, values)}>
                    Edit
                  </Button>
                )}                    
              </CardActions>              
            </Card>
      </Grid>
    );
  });

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h5" className={styles.label}>
                The Vision
              </Typography>
            </Grid>
          </Hidden>

          {coreItems}
          { coreItems.length > 0 ?
            <Grid item xs={12}>
              <div className={styles.center}>
                <Button onClick={handleDownloadPdf} color="primary" variant="contained" >
                  Download Report
                </Button>
              </div>
            </Grid>          
            :
            <Grid xs={12} item>
              <Typography align="center">
                <Button variant="contained" color="primary" onClick={handleAddCore(true)} disabled={!isAuthed(user.user, "company admin")}>
                  New Department Core
                </Button>
              </Typography>
            </Grid>         
          }       
        </Grid>
      </Container>

      <AddCoreDialog id={params.org} open={addCore} handleClose={handleAddCore(false)} plans={currentYearPlans} departmentplanid={user.departmentFilter.id} />

      
      <EditDialog
        id={params.org}
        open={dialog}
        handleClose={handleEditClose}
        initForm={initForm}
        name={_.get(dialog, "core.key", null)}
        plan={user.departmentFilter.id}
        minYear={minYear}
      />
    </>
  );
};

export default TheVision;

const GET_ORGANIZATION = gql`
  query TheVision_GetOrg($id: ID!, $plan: ID) {
    organization(id: $id, plan: $plan) {
      id
      name
      fiscalYear
      profilePicture
      plansOrder
      core {
        purpose
        value
        competency
        market
        offering
        valueProposition
        differentiation
        process
      }
      createdAt
      bhag {
        goal
        targetDate
      }
      viewPreferences {
        name
        position
        show
      }
      notes {
        bhag {
          id
          date
        }
        purpose {
          id
          date
        }
        value {
          id
          date
        }
        competency {
          id
          date
        }
        market {
          id
          date
        }
        offering {
          id
          date
        }
        valueProposition {
          id
          date
        }
        process {
          id
          date
        }
        differentiation {
          id
          date
        }
      }
    }

    plans(organization: $id, closed: false) {
      id
      departmentName
      theme
      year
      useDepartmentTheme
      sharedPlanId
      category
      color
      shortName
      targetDate
    }
  }
`;

const UPDATE_CORE = gql`
  mutation TheCore_UpdateCore($id: ID!, $values: [String], $name: String) {
    updateCore(id: $id, values: $values, name: $name)
  }
`;

const GET_CLOSED_CORP_PLANS = gql`
  query GetCorpPlans($id: ID!) {
    corporatePlans: plans(organization: $id, departmentName: "Corporate", category: "1 year", closed: true) {
      id
      year
      closed
    }
  }
`;
