import React from "react";
import _ from "lodash";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";

export default function ConfirmDeletionDialog({
  itemType,
  value,
  confirmOpen,
  handleConfirmOpen,
  handleDeletion,
  children,
  deleteLoading,
}) {
  return (
    <Dialog
      open={confirmOpen}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleConfirmOpen(false)();
        }
      }}
    >
      <DialogTitle>Delete the following {itemType}?</DialogTitle>
      <DialogContent>{_.isEmpty(children) ? <DialogContentText>{value}</DialogContentText> : <>{children}</>}</DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmOpen(false)}>Cancel</Button>
        <Button onClick={handleDeletion} color="primary" autoFocus disabled={deleteLoading}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
