import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

const APP_START_YEAR = 2020;
const currentYear = new Date().getFullYear();
const yearList = Array(currentYear - APP_START_YEAR + 1)
  .fill(null)
  .map((value, idx) => currentYear - idx);

const SelectYear = ({ selectedYear = currentYear, handleChange, margin = "none" }) => {
  return (
    <FormControl variant="outlined" margin={margin}>
      <InputLabel>Year</InputLabel>
      <Select name="year" value={selectedYear} onChange={handleChange} label="Year">
        {yearList.map((year, idx) => (
          <MenuItem key={idx} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default SelectYear;
