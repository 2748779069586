import React, { useState } from "react";
import styles from "./Notes.module.scss";
import { IconButton, Button, Tooltip } from "@material-ui/core";
import { grey, red } from "@material-ui/core/colors";
import { mdiTrashCanOutline, mdiCheck, mdiClose, mdiFileDocumentBoxRemove, mdiPencil, mdiContentCopy } from "@mdi/js";
import Icon from "@mdi/react";

const DeleteConfirm = ({
  id,
  referenceId,
  model,
  core,
  filename,
  handleDelete,
  additionalProps,
  handleEditMode,
  handleRemoveImage,
  handleCopyImage, // is null if the file is not an image
  note,
  deleteIconColor = red[400],
  otherIconColor = grey[400],
}) => {
  const [confirm, setConfirm] = useState(false);
  const handleConfirmDelete = (open) => () => {
    setConfirm(open);
  };

  if (confirm) {
    return (
      <div className={styles.listItemText}>
        Delete?
        <IconButton onClick={handleConfirmDelete(false)} size="small">
          <Icon path={mdiClose} color="rgba(0, 0, 0, 0.54)" size={1} />
        </IconButton>
        <IconButton onClick={handleDelete({ id, referenceId, model, filename, core, additionalProps })} size="small">
          <Icon path={mdiCheck} color={deleteIconColor} size={1} />
        </IconButton>
      </div>
    );
  }
  return (
    <div className={styles.listItemText}>
      <Tooltip title="Edit">
        <IconButton onClick={handleEditMode(true, note)} size="small">
          <Icon path={mdiPencil} color={otherIconColor} size={1} />
        </IconButton>
      </Tooltip>
      {handleCopyImage && (
        <Tooltip title="Copy Image">
          <IconButton onClick={() => handleCopyImage(filename)} size="small">
            <Icon path={mdiContentCopy} color={otherIconColor} size={1} />
          </IconButton>
        </Tooltip>
      )}
      {filename && (
        <Tooltip title="Remove Upload">
          <IconButton onClick={() => handleRemoveImage(referenceId, model, core, filename, id, additionalProps)} size="small">
            <Icon path={mdiFileDocumentBoxRemove} color={deleteIconColor} size={1} />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Remove Note">
        <IconButton onClick={handleConfirmDelete(true)} size="small">
          <Icon path={mdiTrashCanOutline} color={deleteIconColor} size={1} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default DeleteConfirm;
