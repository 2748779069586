import React from "react";
import styles from "./Metrics.module.scss";
import { TableCell } from "@material-ui/core";
import SkeletonMui from "@material-ui/lab/Skeleton";

const Skeleton = ({ long }) => {
  return (
    <>
      <TableCell />
      <TableCell>
        <SkeletonMui variant="text" />
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="text" width={100} />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="text" width={100} />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="text" width={100} />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="text" width={100} />
        </div>
      </TableCell>
      {long && (
        <>
          <TableCell>
            <div className={styles.flexCenter}>
              <SkeletonMui variant="text" width={100} />
            </div>
          </TableCell>
          <TableCell>
            <div className={styles.flexCenter}>
              <SkeletonMui variant="text" width={150} />
            </div>
          </TableCell>
        </>
      )}
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="circle" height={40} width={40} />
        </div>
      </TableCell>
    </>
  );
};

export default Skeleton;
