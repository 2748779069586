import React, { useState, useContext, useEffect } from "react";
import styles from "./Bugs.module.scss";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import _ from "lodash";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Card,
  CardContent,
  CardActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Hidden,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import CardTitle from "../../components/CardTitle/CardTitle";
import { useForm } from "../../hooks";
import Loading from "../../components/Loading/Loading";
import Report from "./Report";
import { isAuthed } from "../../utils/authorization";
import { UserContext } from "../../context/userContext";
import useMobileMenu from "../../hooks/useMobileMenu";

const initForm = {
  search: null,
};

const initErrorForm = {};

const Bugs = () => {
  const { fetch, requestFetch } = useContext(FetchContext);
  const { user } = useContext(UserContext);
  const { snack } = useContext(SnackbarContext);
  const [offset, setOffset] = useState(0);
  const [size, setSize] = useState(10);
  const [getReports, { data, loading, refetch }] = useLazyQuery(GET_REPORTS);
  const location = useLocation();
  const history = useHistory();
  const { form, handleChange, resetForm } = useForm({
    initForm,
    initErrorForm,
  });

  const [showResolved, setShowResolved] = useState(JSON.parse(localStorage.getItem(`dashboard.bug.showResolved`)) || false);

  const { renderMobileMenu } = useMobileMenu();

  const handleSearch = () => {
    const { search } = form;
    history.push({
      search: `?q=${search}`,
    });

    getReports({ variables: { offset, size, search } });
  };

  const handleChangePage = (e, page) => {
    setOffset(page);
  };

  const handleChangeSize = (e) => {
    setSize(e.target.value);
  };

  const handleShowResolved = () => {
    localStorage.setItem(`dashboard.bug.showResolved`, JSON.stringify(!showResolved));
    setShowResolved(!showResolved);
  };

  useEffect(() => {
    const search = queryString.parse(location.search);
    if (!_.isEmpty(search)) {
      resetForm({ ...initForm, search: _.get(search, "q") });
    }
    // TODO: Fix this not searching with the query string on first render
    getReports({ variables: { offset, size, search: _.get(search, "q") } });
  }, []);

  useEffect(() => {
    getReports({ variables: { offset, size, search: _.get(form, "search") } });
  }, [size, offset]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [fetch]);

  if (loading || !data) return <Loading />;
  const reports = _.get(data, "reports", []);

  const numOfResolved = reports.reduce((sum, report) => (report.resolved ? sum + 1 : sum), 0);

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h6" className={styles.label}>
                Reported Bugs
              </Typography>
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Card className={styles.card}>
              <CardTitle vertical color="teal">
                <Typography variant="h5" className={styles.title}>
                  Reports
                </Typography>
              </CardTitle>
              <CardActions className={styles.cardActions}>
                {renderMobileMenu(
                  <>
                    <TextField
                      variant="outlined"
                      label="Search"
                      name="search"
                      size="small"
                      value={form.search || ""}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") handleSearch();
                      }}
                      className={styles.left}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleSearch}>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Button variant="outlined" className={styles.left} onClick={handleShowResolved} size="large">
                      {showResolved ? "Hide" : "Show"} Resolved ({numOfResolved}/{reports.length})
                    </Button>
                  </>
                )}
              </CardActions>
              <CardContent className={styles.cardContent}>
                <Table size="small" className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Reported By</TableCell>
                      <TableCell>Company</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell>Date Reported</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {reports &&
                      reports.map((report) => {
                        return (
                          <Report
                            key={report.id}
                            report={report}
                            snack={snack}
                            requestFetch={requestFetch}
                            // canEdit={isAuthed(user.user, "department facilitator")}
                            showResolved={showResolved}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  page={offset}
                  rowsPerPage={size}
                  count={data.reportsCount}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeSize}
                  component="div"
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Bugs;

const GET_REPORTS = gql`
  query Bugs_GetReports($offset: Int, $size: Int, $search: String) {
    reports(offset: $offset, size: $size, search: $search) {
      id
      type
      title
      description
      resolved
      author {
        name {
          first
          last
        }
        auth
        organization {
          id
          name
        }
        profilePicture
      }
      createdAt
    }
    reportsCount(search: $search)
  }
`;
