import React from "react";
import { useParams } from "react-router-dom";
import styles from "../WeeklyReview.module.scss";
import { Typography } from "@material-ui/core";
import NotesButton from "../../../components/Notes/NotesButton";

const Welcome = () => {
  const params = useParams();

  return (
    <>
      <Typography variant="h4" align="center" paragraph>
        Please share one personal and one professional good news
      </Typography>
      <Typography variant="h5" align="center" className={styles.flexCenter}>
        Notes:
        <NotesButton id={params.meeting_id} core="welcome" model="meeting" size={1.25} />
      </Typography>
    </>
  );
};

export default Welcome;
