import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, makeStyles, Typography, styled } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import styles from "./Metrics.module.scss";
import Icon from "@mdi/react";
import {
  mdiArrowUpBold,
  mdiArrowDownBold,
} from "@mdi/js";


const defaultDistribution = [0.25, 0.25, 0.25, 0.25];

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
});

const Distribution = ({ open, onClose, selectedYear, distribution, projectedTotal, handleSubmit }) => {
  const [distributionValues, setDistributionValues] = useState([]);
  const [annualTotal, setAnnualTotal] = useState(projectedTotal);
  
  const decimalDigits = 10;

  useEffect(() => {
    if (!_.isEqual(distribution, distributionValues) || _.isEmpty(distribution)) {
      setDistributionValues(!_.isNil(distribution) && !_.isEmpty(distribution) ? distribution : defaultDistribution);
    }
  },[distribution]);

  const handlePercentageChange = (e) => {
    const index = e.target.name;
    let newDistributionValues = [...distributionValues];
    newDistributionValues[index] = _.round(Number(e.target.value),10);
    setDistributionValues([...newDistributionValues]);
  };

  const handleAmountChange = (e) => {

    if (zeroTotal)
      return;

    const index = e.target.name;

    let newDistributionValues = [...distributionValues];
    newDistributionValues[index] = _.round(Number(e.target.value/annualTotal),10);
    const roundedDistribution = newDistributionValues;//.map(percentage => _.round(percentage, decimalDigits));

    // Correct the final element to ensure the total is exactly 1.0
    const total = _.sum(roundedDistribution);
    const error = 1.0 - total;
    if (error !== 0.0 && Math.abs(error) < 0.000001) {
      roundedDistribution[3] += error;
    }
    setDistributionValues([...roundedDistribution]);
  };

  const classes = useStyles();
  const total = _.round(_.sum(distributionValues), 10);
   const currentAnnualTotal = distributionValues.length ?  distributionValues.reduce((accumulator, currentValue) => accumulator + (currentValue*annualTotal), 0) : 0;
  const zeroTotal = _.isNil(annualTotal) || !annualTotal;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Quarter Projected Distribution ({selectedYear})
        <Typography variant="subtitle1">Projected Annual Value: {!zeroTotal ? _.round(annualTotal,decimalDigits) : <i>Not Set</i>}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1">Distribute by Percentage:</Typography>
        <div className={styles.distributionContainer}>
          {distributionValues.map((value, idx) => (
            <TextField
              required
              key={idx}
              label={`Q${idx + 1}`}
              name={`${idx}`}
              variant="outlined"
              margin="dense"
              value={value}
              type="number"
              onChange={handlePercentageChange}
              helperText=" "
              inputProps={{style: {fontSize: 12}}}
              className={classes.input}
              InputLabelProps={{
                shrink: true,
              }}        
            />
          ))}
            <TextField
              disabled
              error={total !== 1}
              label={`Total %`}
              variant="outlined"
              margin="dense"
              value={total}
              type="number"
              helperText=" "
              inputProps={{style: {fontSize: 12, fontWeight: 'bold', color:'black'}}} 
              className={classes.input}  
              InputLabelProps={{
                shrink: true,
              }} 
            />           
        </div>
        <Typography variant="subtitle1">Distribute by Value:</Typography>
        <div className={styles.distributionContainer}>
          {distributionValues.map((value, idx) => (
            <TextField
              disabled={zeroTotal}
              required
              key={idx}
              name={`${idx}`}
              variant="outlined"
              margin="dense"
              value= {!zeroTotal && _.round(value*annualTotal,decimalDigits)}
              type="number"
              onChange={handleAmountChange}
              helperText=" "
              inputProps={{style: {fontSize: 12}}}
              className={classes.input} 
              InputLabelProps={{
                shrink: true,
              }} 
            />
          ))}
            <TextField
              disabled
              error={total !== 1}
              label={`Total Value`}
              variant="outlined"
              margin="dense"
              value={!zeroTotal && _.round(currentAnnualTotal,decimalDigits)}
              type="number"
              helperText={
                (!zeroTotal && total !== 1) ? (
                  <>
                    <Icon 
                      path={currentAnnualTotal < annualTotal ? mdiArrowUpBold : mdiArrowDownBold } 
                      size="1em" 
                    /> 
                    {_.round(Math.abs(currentAnnualTotal - annualTotal), decimalDigits)}
                  </>
                ) : " "
              }
              inputProps={{style: {fontSize: 12, fontWeight: 'bold', color:'black'}}}
              className={classes.input}
              InputLabelProps={{
                shrink: true,
              }} 
            />          
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => handleSubmit(distributionValues)} color="primary" disabled={total !== 1}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Distribution;
