import { useState } from "react";
import _ from "lodash";
import { setDay } from "date-fns";

function useForm({ initForm, initErrorForm }) {
  const [form, setForm] = useState(initForm);
  const [formErrors, setFormErrors] = useState({});

  function processChange(name, value) {
    const clonedForm = _.cloneDeep(form);
    _.set(clonedForm, name, value);
    setForm(clonedForm);

    validate(name, value, clonedForm);

    return clonedForm;
  }

  function handleToggleCheckBox(e) {
    const { name, checked } = e.target;

    const clonedForm = _.cloneDeep(form);
    _.set(clonedForm, name, checked);
    setForm(clonedForm);

    return clonedForm;
  }

  function handleChange(e) {
    const { name, value, checked } = e.target;

    // Lodash is used to set the form so target names such as "a.b" will be set as {a: {b: target.value}}
    // instead of {a: {b: null}, a.b: target.value}
    let newValue = value;
    if (!_.isNil(checked) && ["true", "false"].includes(value)) {
      newValue = checked;
    }

    return processChange(name, newValue);
  }

  function handleChangeManual({ name, value } = {}) {
    processChange(name, value);
  }

  const handleChangeDate = (name) => (date) => {
    const clonedForm = _.cloneDeep(form);
    _.set(clonedForm, name, date);
    setForm(clonedForm);
  };

  const handleChangeDayOfTheWeek = (name) => (e) => {
    const day = e.target.value;
    const clonedForm = _.cloneDeep(form);
    _.set(clonedForm, name, setDay(form[name], day));
    setForm(clonedForm);
  };

  const handleChangeToggleButton = (name) => (e, value) => {
    const clonedForm = _.cloneDeep(form);
    _.set(clonedForm, name, value);
    setForm(clonedForm);
  };

  const handleChangeCallback = (callback) => (e) => {
    const clonedForm = handleChange(e);
    callback(clonedForm);
  };

  function resetForm(form = initForm) {
    setForm(form);
    setFormErrors({});
  }

  function validate(name, value, currentForm) {
    let failed = [];
    const criteria = _.get(initErrorForm, name, []);
    const currentFieldErrors = {};
    criteria.forEach((c) => {
      let type;
      if (typeof c === "string") {
        type = c;
      } else {
        type = c.type;
      }

      switch (type) {
        case "required": {
          const pass = !(_.isNil(value) || (_.isEmpty(value) && typeof value !== "boolean"));
          failed.push(pass);
          _.set(currentFieldErrors, name, pass ? false : "This field is required");
          break;
        }
        case "gte": {
          const pass = value.length >= c.target;
          failed.push(pass);
          _.set(currentFieldErrors, name, pass ? false : `Length must be greater or equal to ${c.target}`);
          break;
        }
        case "custom": {
          let pass = false;
          // check custom pass condition
          if (c.callback(currentForm, value)) {
            pass = true;
          }
          failed.push(pass);
          _.set(currentFieldErrors, name, pass ? false : c.errorMessage);
          break;
        }
        default:
          break;
      }
    });

    setFormErrors((prevState) => _.merge({}, prevState, currentFieldErrors));
    return failed;
  }

  function validateForm() {
    let failed = [];
    for (let fieldToCheck in initErrorForm) {
      failed = [...failed, ...validate(fieldToCheck, _.get(form, fieldToCheck), form)];
    }

    return _.isEmpty(failed.filter((f) => f === false));
  }

  function valueAsFormattedNum(value) {
    if (!value && value !== 0) return "";
    if (isNaN(value)) return "NaN";
    return value.toLocaleString();
    // let val = value;
    // if (_.isNumber(val)) {
    //   val = val.toString();
    // }
    // val = removeFormattedNum(val);

    // const [ints, decimals] = val.split(".");

    // return `${ints.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${decimals !== undefined ? `.${decimals}` : ""}`;
  }

  function removeFormattedNum(value) {
    return value.replace(/,/g, "");
  }

  return {
    form,
    formErrors,
    handleChange,
    handleChangeManual,
    handleToggleCheckBox,
    handleChangeDate,
    handleChangeDayOfTheWeek,
    handleChangeToggleButton,
    handleChangeCallback,
    resetForm,
    validateForm,
    valueAsFormattedNum,
    removeFormattedNum,
  };
}

export default useForm;
