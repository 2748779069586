import React, { useContext, Fragment } from "react";
import handleViewport from "react-in-viewport";
import styles from "./Metrics.module.scss";
import _ from "lodash";
import { mdiAlert, mdiDrag, mdiVariableBox } from "@mdi/js";
import { isAuthed } from "../../utils/authorization";
import useInViewport from "../../hooks/useInViewport";
import { parseMetricValueUsingFormula, getQuarterMetricTotal } from "../../utils/misc";

import { UserContext } from "../../context/userContext";

import { TableRow, TableCell, MenuItem, Typography, Tooltip } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import Icon from "@mdi/react";

import UserAvatars from "../UserAvatars/UserAvatars";
import Menu from "../Menu/Menu";
import Status from "../Status/Status";
import NotesButton from "../Notes/NotesButton";
import Skeleton from "./Skeleton";
import PlanPill from "../PlanPill/PlanPill";

import { year as utilsYear, getYear, addToDate, subToDate } from "../../utils/dates";

const Metric = ({
  innerRef,
  provided,
  inViewport,
  forwardedRef,
  currentQuarter,
  category,
  handleAddIssueDialog,
  canEdit,
  metric,
  afterDnd,
  count,
  referenceMap,
  corpForSelectedYear,
  handleEditDialog,
  handleUpdateStatus,
  handleConfirmOpen,
  yearOne = new Date(),
  fiscalYear,
  minCorpPlanDisplayFiscalYear,
}) => {
  const {
    id,
    value,
    comparator,
    measurables,
    unit,
    users,
    status,
    departmentName,
    plan,
    cumulative,
    calculateTotal,
    enableFormula,
    formula,
    formulaScope,
    number,
    objectives,
  } = metric;
  const { user } = useContext(UserContext);

  const hasRendered = useInViewport(inViewport);

  const YEARS = { Y1: utilsYear(yearOne), Y2: utilsYear(yearOne, 1), Y3: utilsYear(yearOne, 2) };

  const handleComparator = (comparatorStr, leftOperand, rightOperand) => {
    const comparisonOperatorsHash = {
      lt: function (a, b) {
        return a < b;
      },
      gt: function (a, b) {
        return a > b;
      },
      gte: function (a, b) {
        return a >= b;
      },
      lte: function (a, b) {
        return a <= b;
      },
      eq: function (a, b) {
        return a == b;
      },
    };

    const comparisonOperator = comparisonOperatorsHash[comparatorStr];
    return comparisonOperator(leftOperand, rightOperand);
  };

  const getUnit = (value) => {
    if (!value) return "—";
    if (!unit) return _.round(Number(value), 2).toLocaleString();
    if (unit === "$") return `${unit}${_.round(value, 2).toLocaleString()}`;
    return `${_.round(Number(value), 2).toLocaleString()}${unit}`;
  };

  const reviewedByPeriodNumber = _.get(plan, "periodData", []).reduce((a, b) => {
    a[b.periodNumber] = b.reviewed;
    return a;
  }, {});

  if (!hasRendered && !afterDnd)
    return (
      <TableRow
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={(el) => {
          forwardedRef.current = el;
          innerRef(el);
        }}
        className={styles.skeletonRow}
      >
        <Skeleton long />
      </TableRow>
    );

  // let ytd = 0; //[Acutal, YTD Cumulative, Projected]
  // if (category === "1 year" && measurables) {
  //   measurables.forEach(([year, projected, actual], idx) => {
  //     const index = parseInt(year.value[1]);

  //     if (cumulative && !enableFormula) {
  //       if (index === currentQuarter) {
  //         ytd = _.toNumber(projected.value);
  //       }
  //     } else if (index <= currentQuarter) {
  //       if (!enableFormula) {
  //         ytd += _.toNumber(projected.value);
  //       } else {
  //         ytd += parseValueUsingFormula({ formula, formulaScope, index: idx, valueIndex: 1, referenceMap });
  //       }
  //     }
  //   });
  // }

  const endYear = parseInt(getYear(addToDate(fiscalYear, {years:2})));
  const startYear = parseInt(getYear(minCorpPlanDisplayFiscalYear));

  let expectedNextYear = startYear;

  return (
    <TableRow
      {...provided.draggableProps}
      ref={(el) => {
        forwardedRef.current = el;
        innerRef(el);
      }}
      style={{ ...provided.draggableProps.style }}
      className={styles.tableRow}
    >
      <TableCell align="center">
        <div {...provided.dragHandleProps} style={{ opacity: canEdit ? 1 : 0.25 }}>
          <Icon path={mdiDrag} size={1} />
        </div>
      </TableCell>
      <TableCell align="center">
        <UserAvatars users={users} />
      </TableCell>
      <TableCell>
        <div className={styles.flex}>
          {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
          {category === "1 year" && `${number}.`} {value}
          <NotesButton
            id={id}
            model="metric"
            value={value.trim()}
            user={_.get(users, ["0", "id"], null)}
            doc={metric}
            tabs={["notes", "issues", "todos"]}
            canEditTodo={canEdit}
            planId={_.get(corpForSelectedYear, "id")}
          />
          {canEdit && (
            <Menu>
              {/* <MenuItem onClick={handleAddIssueDialog(id, "Metric", value.trim(), users[0] ? users[0].id : null)}>Add Issue</MenuItem> */}
              <MenuItem onClick={handleEditDialog(true, metric)}>Edit</MenuItem>
              <MenuItem onClick={handleConfirmOpen(true, metric)} className={styles.delete}>
                Delete
              </MenuItem>
            </Menu>
          )}
        </div>
      </TableCell>
      {category === "3 year" && (
        <TableCell>
          {enableFormula && (
            <Tooltip title={`Formula Metric computed by [${formula}]`}>
              <Icon path={mdiVariableBox} size={1} />
            </Tooltip>
          )}
        </TableCell>
      )}
      {measurables &&
        measurables.map(([year, projected, actual], idx, arr) => {
          let isPast = false;
          let isCurrent = false;
          let isClosed = false;
          let blankCells = [];
          let blankTrailingCells = [];

          if (category === "1 year") {
            const index = parseInt(year.value[1]);
            isClosed = _.get(reviewedByPeriodNumber, index, false);
            if (index < currentQuarter) {
              isPast = true;
            } else if (index === currentQuarter) {
              isCurrent = true;
            }
          }
          else {
            //category === "3 year"
            const yearInt = parseInt(YEARS[year.value] || year.value);
            const currYearInt = parseInt(utilsYear(yearOne));

            if ((yearInt < parseInt(getYear(minCorpPlanDisplayFiscalYear))) || (yearInt > currYearInt+2)) {
              return null;
            }

            
            const blankYears = yearInt - expectedNextYear; // number of years that are missing

            if (blankYears !== 0) {
              console.log(`Adding ${blankYears} Blank General Years!`);

              //there are missing years in the data, need to add a blank cell value for each missing year;
              blankCells = Array.from({ length: blankYears }, (_, idx) => {
                isPast = false;
                isCurrent = false;
                const missingYear = yearInt - blankYears + idx;
                if (missingYear < currYearInt) {
                  isPast = true;
                } else if (missingYear === currYearInt) {
                  isCurrent = true;
                } 

                return (
                  <TableCell key={`mblank-${idx}`} align="center" className={isCurrent ? styles.current : isPast ? styles.past : undefined}>
                    <Tooltip title={`No Measurable`}>
                      <span>-</span>
                    </Tooltip>
                  </TableCell>
                )}
              );
            }

            //check if it's the last element of the array, and ensure the current year matches the endYear, 
            //otherwise, additional blanks at the end will be required.
            const blankTrailingYears = (idx === arr.length-1) ? endYear - yearInt : 0;

            if (blankTrailingYears !== 0) {
              //there are missing years in the data, need to add a blank cell value for each missing year;
              console.log(`Adding ${blankTrailingYears} Trailing Years!`);
              blankTrailingCells = Array.from({ length: blankTrailingYears }, (_, idx) => {

                isPast = false;
                isCurrent = false;
                const missingYear = yearInt + 1 + idx;
                if (missingYear < currYearInt) {
                  isPast = true;
                } else if (missingYear === currYearInt) {
                  isCurrent = true;
                } 
                
                return (
                  <TableCell key={`tmblank-${idx}`} align="center" className={isCurrent ? styles.current : isPast ? styles.past : undefined}>
                    <Tooltip title={`No Measurable`}>
                      <span>-</span>
                    </Tooltip>
                  </TableCell>
                  
                )}
              );
            }     

            isPast = false;
            isCurrent = false;
            if (yearInt < currYearInt) {
              isPast = true;
            } else if (yearInt === currYearInt) {
              isCurrent = true;
            }      
            
            
            expectedNextYear = yearInt+1;
          }

          const overdue = (isPast || isClosed) && !Boolean(actual.value) && !enableFormula;
          const isTotal = year === "Total";

          // const reference = _.get(actual, "reference");
          // const linkedActual = !_.isNil(reference)
          //   ? getQuarterMetricTotal(_.get(reference, "measurables", []), _.get(reference, "calculateTotal", false))
          //   : undefined;

          let targetMet;
          if (!enableFormula) {
            // const actualVal = _.isNil(linkedActual) ? actual.value : linkedActual;
            const actualVal = actual.value;
            if (!_.isEmpty(actualVal) && !_.isEmpty(projected.value)) {
              if (handleComparator(comparator, parseFloat(actualVal), parseFloat(projected.value))) {
                targetMet = true;
              } else {
                targetMet = false;
              }
            }
          }

          let formulaProjected;
          let formulaActual;
          if (enableFormula) {
            formulaProjected = parseMetricValueUsingFormula({ formula, formulaScope, index: idx, valueIndex: 1, referenceMap });
            formulaActual = parseMetricValueUsingFormula({ formula, formulaScope, index: idx, valueIndex: 2, referenceMap });
            if (!isNaN(formulaActual) && !isNaN(formulaProjected)) {
              if (handleComparator(comparator, formulaActual, formulaProjected)) {
                targetMet = true;
              } else {
                targetMet = false;
              }
            }
          }

          const notesForColumn = year.notes || [];
          const notesPath = `measurables.${idx}.0`; // store it under name of the column
          const additionalProps = {
            path: notesPath,
          };

          return (
            <Fragment key={idx} >
              {blankCells}
              <TableCell align="center" className={isCurrent ? styles.current : isPast ? styles.past : undefined}>
                <div className={styles.flexCenter}>
                  {overdue && (
                    <Tooltip title={`Information needs to be entered.${isClosed ? " Current Quarter is closed" : ""}`}>
                      <Icon path={mdiAlert} color={red[400]} size={1} className={styles.overdue} />
                    </Tooltip>
                  )}
                  {!enableFormula && (
                    <Typography variant="inherit" noWrap>
                      <span>{getUnit(projected.value)} |</span>{" "}
                      <span className={targetMet === true ? styles.green : targetMet === false ? styles.red : undefined}>
                        {/* {_.isNil(linkedActual) ? getUnit(actual.value) : getUnit(linkedActual)} */}
                        {getUnit(actual.value)}
                      </span>
                    </Typography>
                  )}
                  {enableFormula && (
                    <Typography variant="inherit" noWrap>
                      <span>{getUnit(formulaProjected)} |</span>{" "}
                      <span className={targetMet === true ? styles.green : targetMet === false ? styles.red : undefined}>
                        {getUnit(formulaActual)}
                      </span>
                    </Typography>
                  )}
                  <NotesButton
                    id={id}
                    model="metric"
                    value={value}
                    user={user.id}
                    doc={{ notes: notesForColumn }}
                    tabs={["notes"]}
                    additionalProps={additionalProps}
                  />
                </div>
              </TableCell>
              {blankTrailingCells}
            </Fragment>
          );
        })}

      {/* {category === "my" && <TableCell colSpan="2" />}  //unsure what this was for? */}
      <TableCell>
        <div className={styles.flexCenter}>
          <Status status={status} />
          {canEdit && (
            <Menu icon="arrow">
              {category === "3 year" && (
                <MenuItem onClick={handleUpdateStatus("complete", metric)} disabled={status === "complete"}>
                  Mark as complete
                </MenuItem>              
                )
              }
              <MenuItem onClick={handleUpdateStatus("on track", metric)} disabled={status === "on track"}>
                Mark as on track
              </MenuItem>
              <MenuItem onClick={handleUpdateStatus("off track", metric)} disabled={status === "off track"}>
                Mark as off track
              </MenuItem>
            </Menu>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
};

export default handleViewport(Metric);
