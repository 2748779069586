import React, { useState } from "react";
const VERSION_NUM = process.env.REACT_APP_VERSION;
const x = "¯\\_(ツ)_/¯";

const Version = ({ ...rest }) => {
  const [count, setCount] = useState(0);

  const handleClick = () => {
    setCount(count + 1);
  };

  if (!VERSION_NUM) return null;

  return (
    <span onClick={handleClick} {...rest}>
      v{count > 99 ? x : VERSION_NUM}
    </span>
    // <span {...rest}>
    //   v1.4.2
    // </span>
  );
};

export default Version;
