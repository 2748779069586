import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styles from "./WeeklyTargets.module.scss";
import _, { camelCase, upperFirst } from "lodash";
import gql from "graphql-tag";
import { useLazyQuery, useMutation } from "@apollo/client";
import { isSameWeek, isBefore } from "date-fns";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { isAuthed } from "../../utils/authorization";
import { DialogContext } from "../../context/dialogContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { FetchContext } from "../../context/fetchContext";
import { UserContext } from "../../context/userContext";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
  TextField,
  Link,
  IconButton,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiPlus, mdiBullseyeArrow, mdiContentSave, mdiEyeOutline, mdiEyeOffOutline } from "@mdi/js";
import { startOfWeek, endOfWeek, formatAs, after, subToDate } from "../../utils/dates";
import CardTitle from "../CardTitle/CardTitle";
import WeeklyTarget from "./WeeklyTarget";
import { convertToWeeklyTargetsReferenceMap } from "../../utils/misc";
import useMobileMenu from "../../hooks/useMobileMenu";
import EditDialog from "./EditDialog";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
// import SelectYear from "../SelectYear/SelectYear";
import { Prompt } from "react-router-dom";

const WeeklyTargets = ({ weeklyTargets, handleEnlarge, plansOrder, checked, planId, variables }) => {
  const { user, setUser } = useContext(UserContext);
  const { dialog, setDialog } = useContext(DialogContext);
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [updateWeeklyTargetNumber] = useMutation(UPDATE_WEEKLY_TARGET_NUMBER);
  const [hideWeeklyTarget] = useMutation(HIDE_WEEKLY_TARGET);
  const [deleteWeeklyTarget, { loading: deleteLoading }] = useMutation(DELETE_WEEKLY_TARGET, {
    update(cache, { data: { deleteWeeklyTarget } }) {
      try {
        const deletedWeeklyTarget = deleteWeeklyTarget.weeklyTarget;
        const deletedWTCacheId = cache.identify(deletedWeeklyTarget);
        // propagate to all weeklytargets query
        cache.modify({
          fields: {
            weeklyTargets: (existingWeeklyTargets) =>
              existingWeeklyTargets.filter((wt) => {
                const cacheId = cache.identify(wt);
                return cacheId !== deletedWTCacheId;
              }),
          },
        });
      } catch (e) {
        console.log(e);
      }
    },
  });
  const [updateWeeklyTarget] = useMutation(UPDATE_WEEKLY_TARGET);

  const [savePending, setSavePending] = useState({});
  const [saving, setSaving] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [getSelectedDept, { data, loading }] = useLazyQuery(GET_SELECTED_DEPT);
  // const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedWeeklyTarget, setSelectedWeeklyTarget] = useState({});
  const [editDialog, setEditDialog] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [showHidden, setShowHidden] = useState(() => {
    const sessionStorageKey = JSON.parse(sessionStorage.getItem(`scorecards.showHiddenKpis`));
    return _.isNil(sessionStorageKey) ? false : sessionStorageKey;
  });
  // const [processedKpis, setProcessedKpis] = useState([]);

  const handleToggleHiddenKpis = () => {
    sessionStorage.setItem("scorecards.showHiddenKpis", JSON.stringify(!showHidden));
    setShowHidden((prev) => !prev);
  };

  const handleConfirmOpen = (open, weeklyTarget) => () => {
    setConfirmOpen(open);
    setSelectedWeeklyTarget(weeklyTarget);
  };

  const handleDeleteWeeklyTarget = (weeklyTarget) => async () => {
    const { id, value } = weeklyTarget;
    const res = await deleteWeeklyTarget({ variables: { id } });
    let delMutationData = `delete${toPascalCase("weeklyTarget")}`;

    if (res.data[delMutationData]) {
      snack(`Deleted "${value}" KPI`);
      handleConfirmOpen(false)();
      return true;
    }
    return false;
  };

  function toPascalCase(str) {
    return upperFirst(camelCase(str));
  }
  const { isMobile, renderMobileMenu } = useMobileMenu();

  const handleEditDialog = (open, weeklyTarget) => () => {
    setEditDialog(open);
    setSelectedWeeklyTarget(weeklyTarget);
  };

  const referenceMap = useMemo(() => convertToWeeklyTargetsReferenceMap(weeklyTargets), [weeklyTargets]);

  const filteredWeeklyTargets = useMemo(() => {
    return weeklyTargets.filter(filterWeeklyTargets);
  }, [weeklyTargets, searchTerm]);

  function filterWeeklyTargets(value) {
    // if it the case that there is no entered search-term
    if (searchTerm === "") {
      return value;
    }

    // if the execution goes past this point, this means either the user entered a search-term
    let userName = `${_.get(value, "user.name.first", "")} ${_.get(value, "user.name.last", "")}`;

    let targetValue = _.get(value, "target", ""),
      targetUnit = _.get(value, "unit", "");
    targetUnit = targetUnit === null ? "" : targetUnit;

    let target = targetUnit === "$" ? targetUnit + targetValue : `${targetValue} ${targetUnit}`.trim();

    let hasSearchTermMatch =
      _.get(value, "value", "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      userName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      target.toLowerCase().includes(searchTerm.toLowerCase());

    if (hasSearchTermMatch) {
      return value;
    }
  }

  const containerEle = useRef(null);

  const handleAddDialog = () => {
    setDialog({ ...dialog, addWeeklyTargetDialog: { open: true, planId, variables } });
  };

  const handleAddTodoOrIssue =
    (category, referenceModel = null, referenceId = null, value = null, user = null) =>
    () => {
      setDialog({ ...dialog, addTodoDialog: { open: true, category, referenceId, referenceModel, value, user, planId } });
    };

  const handleDragEnd = async ({ draggableId, destination }) => {
    if (!destination) return;
    const [, dragId] = draggableId.split("_");

    const ok = await updateWeeklyTargetNumber({ variables: { id: dragId, number: destination.index } });
    if (ok.data.updateWeeklyTarget) {
      snack(`Moved weekly target to number ${destination.index}`);
      // requestFetch();
    }
  };

  const handleAddToSavePending = (id, callback) => {
    setSavePending((prevState) => ({ ...prevState, [id]: callback }));
  };

  const handleExecutePendingSaves = async () => {
    setSaving(true);
    const ok = await Promise.all(
      Object.values(savePending).map((callback) => {
        return new Promise(async (resolve, reject) => {
          const ok = await callback();
          if (ok.data.updateWeeklyTarget) {
            resolve(true);
          } else {
            reject(false);
          }
        });
      })
    );

    if (ok.indexOf(false) < 0) {
      snack(`Updated ${Object.keys(savePending).length} KPI${Object.keys(savePending).length === 1 ? "" : "s"}`);
      // requestFetch();
      setSavePending({});
    }
    // potential changes needed
    setTimeout(() => setSaving(false), 500);
  };

  const activeWeek = new Date();

  const weekHeaders = useMemo(() => {
    let week = startOfWeek(new Date());
    const end = subToDate(week, { weeks: 14 });
    const arr = [];

    while (after(week, end)) {
      arr.push(week);
      week = subToDate(week, { weeks: 1 });
    }

    return arr.reverse();
  }, []);

  const navToAllDepts = () => {
    setUser({ ...user, departmentFilter: { id: null, name: "All Departments" } });
  };

  const departmentFilterId = _.get(user, "departmentFilter.id");

  // **DOES NOT CURRENTLY WORK BECAUSE KPIS HAVE MULTIPLE DEPTS
  // useEffect(() => {
  //   if (filteredWeeklyTargets) {
  //     const filteredKpisClone = _.cloneDeep(filteredWeeklyTargets);
  //     const kpisBySpid = _.groupBy(filteredKpisClone, "plan.sharedPlanId");

  //     const groupedSortedKpis = plansOrder
  //       .filter((spid) => Object.keys(kpisBySpid).includes(spid))
  //       .reduce((sortedGroupedKpis, spid) => {
  //         sortedGroupedKpis.push(_.sortBy(kpisBySpid[spid], ["number"]));
  //         return sortedGroupedKpis;
  //       }, []);

  //     setProcessedKpis(groupedSortedKpis);
  //   }
  // }, [filteredWeeklyTargets]);

  useEffect(() => {
    if (departmentFilterId) {
      getSelectedDept({
        variables: {
          id: departmentFilterId,
        },
      });
    }
  }, [departmentFilterId]);

  useEffect(() => {
    if (containerEle.current) {
      containerEle.current.scrollLeft = 9999;
    }
  }, []);

  //const hiddenKpiCount = _.filter(filteredWeeklyTargets, ["hide", true]).length;
  const hiddenKpiCount = _.filter(filteredWeeklyTargets, (target) => {
    return target.hide === true && target.display !== false;
  }).length;

  return (
    <>
      <Card className={styles.card}>
        <CardTitle vertical color={"lightGreen"}>
          <Icon path={mdiBullseyeArrow} size={1} color="#fff" className={styles.icon} />
          <Typography variant="h5" className={styles.title}>
            KPIs
          </Typography>
        </CardTitle>
        {user.departmentFilter.id !== null && (
          <CardContent className={styles.warningContainer}>
            {!loading && (
              <div className={styles.warning}>
                KPI filtered by {`${_.get(data, "plan.departmentName", "")}. `}
                Ordering is disabled. To enable ordering filter by{" "}
                <span className={styles.allDeptsLink} onClick={navToAllDepts}>
                  All Departments
                </span>
                .
              </div>
            )}
          </CardContent>
        )}
        <CardActions className={styles.cardActions}>
          {renderMobileMenu(
            <TextField
              className={styles.searchField}
              label="Search"
              type="search"
              variant="outlined"
              size="small"
              onChange={(event) => {
                setSearchTerm(event.target.value);
              }}
            />
          )}
          {/* <SelectYear handleChange={(e) => setSelectedYear(e.target.value)} selectedYear={selectedYear} margin={"dense"} /> */}
          {isMobile ? (
            <IconButton onClick={handleToggleHiddenKpis} disabled={hiddenKpiCount === 0}>
              <Icon path={showHidden ? mdiEyeOutline : mdiEyeOffOutline} size={1} />
            </IconButton>
          ) : (
            <Button variant="outlined" onClick={handleToggleHiddenKpis} size="large" disabled={hiddenKpiCount === 0}>
              {showHidden ? 0 : hiddenKpiCount} KPIs Hidden
            </Button>
          )}
          {isMobile ? (
            <IconButton
              onClick={handleExecutePendingSaves}
              disabled={_.isEmpty(savePending)}
              className={!_.isEmpty(savePending) ? styles.saveButtonSavesPending : undefined}
            >
              <Icon path={mdiContentSave} size={1} />
            </IconButton>
          ) : (
            <Button
              variant="outlined"
              disabled={_.isEmpty(savePending)}
              onClick={handleExecutePendingSaves}
              className={!_.isEmpty(savePending) ? styles.saveButtonSavesPending : undefined}
              size="large"
            >
              Save Changes
            </Button>
          )}
          {isMobile ? (
            <IconButton
              onClick={handleAddDialog}
              className={styles.iconLeft}
              color="primary"
              disabled={!isAuthed(user.user, "department facilitator")}
            >
              <Icon path={mdiPlus} size={0.75} color="#fff" />
            </IconButton>
          ) : (
            <Button
              startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
              className={styles.iconLeft}
              onClick={handleAddDialog}
              variant="contained"
              color="primary"
              disabled={!isAuthed(user.user, "department facilitator")}
            >
              New KPI
            </Button>
          )}
        </CardActions>
        <CardContent className={styles.cardContent} ref={containerEle}>
          {!_.isEmpty(weeklyTargets) ? (
            <Table className={styles.table}>
              <TableHead>
                <TableRow className={styles.fixedHeader}>
                  <TableCell />
                  <TableCell>Accountable</TableCell>
                  <TableCell />
                  <TableCell>Target</TableCell>
                  {weekHeaders.map((week, i) => {
                    const isPast = isBefore(week, activeWeek);
                    const isCurrent = isSameWeek(week, activeWeek);

                    if (!isCurrent && !isPast) return null;

                    return (
                      <TableCell
                        key={`${i}-header-cell`}
                        align="center"
                        className={`${isCurrent ? styles.weekCurrent : isPast ? styles.weekPast : styles.week}`}
                      >
                        {formatAs(startOfWeek(week), "MMM d")} - {formatAs(endOfWeek(week), "MMM d")}
                      </TableCell>
                    );
                  })}
                  <TableCell align="center">Average</TableCell>
                </TableRow>
              </TableHead>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="WEEKLY_TARGET">
                  {(provided, snapshot) => {
                    return (
                      <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                        {_.sortBy(filteredWeeklyTargets, ["number"]).map((weeklyTarget, i) => {
                          // const weeklyTargetOneYearPlansWithYear = _.get(weeklyTarget, "plans", []).filter((plan) => !_.isNil(plan.year));
                          // const weeklyTargetYears = weeklyTargetOneYearPlansWithYear.map((plan) => {
                          //   const planYear = _.get(plan, "plan.year")
                          //     ? new Date(parseInt(plan.plan.year)).getFullYear()
                          //     : new Date(parseInt(plan.year)).getFullYear();
                          //   return planYear;
                          // });
                          // const yearMatch = weeklyTargetYears.some((yearInt) => yearInt === selectedYear);
                          // if (!_.isNil(selectedYear) && !yearMatch) return null;
                         
                          if (!_.isNil(_.get(weeklyTarget, "display")) && _.get(weeklyTarget, "display") === false ) return null;
                          if (!showHidden && _.get(weeklyTarget, "hide", false)) return null;

                          return (
                            <Draggable
                              key={`${i}-draggable`}
                              draggableId={`WEEKLYTARGET_${weeklyTarget.id}_${weeklyTarget.number}`}
                              index={weeklyTarget.number}
                              isDragDisabled={!isAuthed(user.user, "department facilitator") || user.departmentFilter.id !== null}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <WeeklyTarget
                                    innerRef={provided.innerRef}
                                    provided={provided}
                                    key={`${i}-weekly-target`}
                                    weeklyTarget={weeklyTarget}
                                    weeklyTargets={weeklyTargets}
                                    weeks={weekHeaders}
                                    today={activeWeek}
                                    handleAddTodoOrIssue={handleAddTodoOrIssue}
                                    canEdit={isAuthed(user.user, "department facilitator")}
                                    handleEnlarge={handleEnlarge}
                                    checked={checked}
                                    handleAddToSavePending={handleAddToSavePending}
                                    saving={saving}
                                    referenceMap={referenceMap}
                                    handleEditDialog={handleEditDialog}
                                    planId={planId}
                                    updateWeeklyTarget={updateWeeklyTarget}
                                    handleConfirmOpen={handleConfirmOpen}
                                    savePending={!_.isEmpty(savePending)}
                                    handleHide={hideWeeklyTarget}
                                  />
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </TableBody>
                    );
                  }}
                </Droppable>
              </DragDropContext>
            </Table>
          ) : (
            <Typography variant="body1" align="center">
              Nothing to show <br />
              {isAuthed(user.user, "department facilitator") && (
                <Button color="primary" onClick={handleAddDialog}>
                  Add KPI
                </Button>
              )}
            </Typography>
          )}
        </CardContent>
      </Card>
      {!_.isEmpty(selectedWeeklyTarget) && (
        <EditDialog
          open={editDialog}
          handleClose={handleEditDialog(false)}
          snack={snack}
          requestFetch={requestFetch}
          weeklyTarget={selectedWeeklyTarget}
          weeklyTargets={weeklyTargets}
          planId={planId}
        />
      )}
      {!_.isEmpty(selectedWeeklyTarget) && (
        <ConfirmDeletionDialog
          itemType="KPI"
          value={selectedWeeklyTarget.value}
          confirmOpen={confirmOpen}
          handleConfirmOpen={handleConfirmOpen}
          handleDeletion={handleDeleteWeeklyTarget(selectedWeeklyTarget)}
          deleteLoading={deleteLoading}
        />
      )}
      <Prompt when={!_.isEmpty(savePending)} message={(location) => `There are unsaved changes, discard the changes and leave the page?`} />
    </>
  );
};

export default WeeklyTargets;

const UPDATE_WEEKLY_TARGET_NUMBER = gql`
  mutation Kpi_UpdateKpi($id: ID!, $number: Int) {
    updateWeeklyTarget(id: $id, number: $number) {
      weeklyTarget {
        id: _id
        number
      }
      weeklyTargets {
        id: _id
        number
      }
    }
  }
`;

const HIDE_WEEKLY_TARGET = gql`
  mutation Kpi_HideKpi($id: ID!, $hide: Boolean) {
    updateWeeklyTarget(id: $id, hide: $hide) {
      weeklyTarget {
        id: _id
        hide
      }
    }
  }
`;

const GET_SELECTED_DEPT = gql`
  query Kpi_GetSelectedDept($id: ID!) {
    plan(id: $id) {
      departmentName
    }
  }
`;

const DELETE_WEEKLY_TARGET = gql`
  mutation ($id: ID!) {
    deleteWeeklyTarget(id: $id) {
      weeklyTarget {
        id: _id
      }
      weeklyTargets {
        id: _id
        number
      }
      metrics {
        id
        weeklyTarget
        measurables {
          value
          notes {
            id
          }
          reference {
            id
            calculateTotal
            measurables {
              value
            }
          }
          distribution
        }
      }
    }
  }
`;

const UPDATE_WEEKLY_TARGET = gql`
  mutation ($id: ID!, $measurables: [MeasurableInput!]) {
    updateWeeklyTarget(id: $id, measurables: $measurables) {
      weeklyTarget {
        id: _id
        measurables {
          week
          value
        }
      }
      metrics {
        id
        weeklyTarget
        measurables {
          value
          notes {
            id
          }
          reference {
            id
            calculateTotal
            measurables {
              value
            }
          }
          distribution
        }
      }
    }
  }
`;
