import React from "react";
import styles from "./Rocks.module.scss";
import { TableCell } from "@material-ui/core";
import SkeletonMui from "@material-ui/lab/Skeleton";

const Skeleton = ({ long }) => {
  return (
    <>
      <TableCell className={styles.firstCell}>
        <SkeletonMui variant="text" />
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="text" width={50} />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="rect" height={25} width={200} />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="text" width={100} />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="circle" height={40} width={40} />
        </div>
      </TableCell>
      <TableCell />
    </>
  );
};

export default Skeleton;
