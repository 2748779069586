import React from "react";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import styles from "./Error.module.scss";

import { Typography, Button } from "@material-ui/core";

import logo from "../../assets/img/icon-white.png";
import splash from "../../assets/img/splash.svg";

const Error = ({ title, body }) => {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <div className={styles.container}>
      <div className={styles.splash}>
        <div className={styles.splashOverlay}>
          <img src={logo} alt="Ventrek Logo" className={styles.img} />
          <Typography variant="h3" className={styles.splashTitle}>
            Welcome to Ventrek
          </Typography>
        </div>
        <img src={splash} alt="splash" className={styles.svg} />
      </div>
      <div className={styles.content}>
        <Typography variant="h2" gutterBottom>
          {title}
        </Typography>
        <Typography paragraph>{body}</Typography>
        <div>
          <Button variant="contained" color="primary" className={styles.button} onClick={handleGoBack}>
            Go Back
          </Button>
          <Link to="/">
            <Button variant="outlined" color="primary" className={styles.button}>
              Go Home
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Error;
