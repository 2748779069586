import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import styles from "./Signout.module.scss";
import { Container } from "@material-ui/core";
import firebase from "../../utils/firebase";
import Loading from "../../components/Loading/Loading";

const Signout = () => {
  const history = useHistory();

  useEffect(() => {
    localStorage.removeItem("token");
    firebase
      .auth()
      .signOut()
      .then(() => {
        history.push("/");
        window.location.reload();
      });
  }, []);

  // Place all signout logic prior to here.
  return (
    <Container className={styles.container}>
      <Loading size={200} />
    </Container>
  );
};

export default Signout;
