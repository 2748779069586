import React, { useState, useEffect } from "react";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

import style from "../markdownGlobal.module.scss";

function Security(props) {
  const [markdownText, setMarkdownText] = useState("");

  const loadMDFile = async () => {
    const filePath = require("./security.md");

    fetch(filePath)
      .then((response) => {
        return response.text();
      })
      .then((text) => {
        setMarkdownText(text);
      });
  };

  useEffect(() => {
    loadMDFile();
  }, []);

  return (
    <Grid container spacing={0} style={{ justifyContent: "center", height: "100%" }}>
      <Grid item xs={12} sm={10} md={8} lg={6}>
        <Paper>
          <div style={{ padding: "24px", marginTop: 40 }}>
            <ReactMarkdown children={markdownText} rehypePlugins={[rehypeRaw]} className={style.reactMarkdown} />
          </div>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default Security;
