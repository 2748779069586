import { ClickAwayListener, TextField } from "@material-ui/core";
import React, { useState } from "react";

const EditableList = ({ value, defaultValue, name, placeholder, handleChange, handleSubmit, textClassName, inputClassName, disabled }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const onKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      if (isChanged) {
        handleSubmit();
        setIsChanged(false);
      }

      setIsEdit(false);
    }
  };

  const handleClickAway = () => {
    setIsEdit(false);
    if (isChanged) {
      handleSubmit();
      setIsChanged(false);
    }
  };

  const handleObservedChange = (e) => {
    handleChange(e);
    setIsChanged(true);
  };

  if (!isEdit) {
    return (
      <div onClick={() => (!disabled ? setIsEdit(true) : {})}>
        <ul className={textClassName}>
          {(value || defaultValue).split("\n").map((str, idx) => (
            <li key={idx} style={{ width: "250px" }}>
              {str}
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <TextField
          name={name}
          multiline
          fullWidth
          inputProps={{
            style: {
              height: '100px',
              overflow: 'auto'
            }
          }}
          margin="none"
          variant="filled"
          value={value || ""}
          onChange={handleObservedChange}
          placeholder={placeholder}
          className={inputClassName}
          onKeyDown={onKeyDown}
          helperText={"Shift + Enter to add a new line"}
        />
      </ClickAwayListener>
    );
  }
};

export default EditableList;
