import _ from "lodash";
import React, { createContext, useState } from "react";

export const LoadingContext = createContext();

export const LoadingContextProvider = ({ children }) => {
  const [loading, setLoading] = useState({});

  const updateLoading = ({ loadingObj, groupKeyStr = "shared" } = {}) => {
    setLoading((prev) => {
      const updateObj = { [groupKeyStr]: loadingObj };
      return _.merge({}, prev, updateObj);
    });
  };

  const resetLoading = () => {
    setLoading({});
  };

  const getLoading = (keyArr = []) => {
    let boolArrRet = [];
    [...keyArr, "shared"].forEach((key) => {
      const loadingObj = _.get(loading, key);
      if (loadingObj) {
        boolArrRet.push(Object.values(loadingObj).some(Boolean));
      }
    });

    return boolArrRet.some(Boolean);
  };

  return <LoadingContext.Provider value={{ loading, getLoading, updateLoading, resetLoading }}>{children}</LoadingContext.Provider>;
};
