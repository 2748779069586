import React, { useEffect } from "react";
import styles from "./Review.module.scss";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Container, Grid, Typography, Button, List, ListItem, ListItemText, Hidden, MenuItem, IconButton } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiPlusCircle } from "@mdi/js";
import Rock from "../../components/Rock/Rock";
import Menu from "../../components/Menu/Menu";
import Loading from "../../components/Loading/Loading";
import UserAvatar from "../../components/UserAvatar/UserAvatar";
import UserAvatars from "../../components/UserAvatars/UserAvatars";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import Status from "../../components/Status/Status";
import ObjectiveEditDialog from "../../components/Objectives/EditDialog";
import MetricEditDialog from "../../components/Metrics/EditDialog";
import { fiscalYearStart, fullDate } from "../../utils/dates";
import useReview from "./hooks/useReview";

// not currently used
const AnnualReview = ({ params }) => {
  const { loading, data, refetch } = useQuery(GET_OBJECTIVES_AND_METRICS, {
    variables: {
      organization: params.org,
    },
  });

  const {
    fetch,
    step,
    snack,
    nextStep,
    prevStep,
    getQuarter,
    getUnit,
    timer,
    printTime,
    editInfo,
    handleUpdateStatus,
    handleNotes,
    handleAddRockDialog,
    handleAddObjectiveDialog,
    handleAddMetricDialog,
    handleEditDialog,
  } = useReview({});

  useEffect(() => {
    refetch();
  }, [fetch]);

  if (loading) return <Loading />;

  const objectives = [...data.oneYear];

  const quarterNumber = parseInt(getQuarter(data.organization.fiscalYear)[0]);

  const startStep = (
    <>
      <Typography variant="h4" className={styles.label} paragraph>
        Annual Review for {fullDate(fiscalYearStart(data.organization.fiscalYear))} - {fullDate(data.organization.fiscalYear)}
      </Typography>
    </>
  );

  const warmupStep = (
    <>
      <Typography variant="h1" className={styles.label} paragraph>
        {printTime(timer)}
      </Typography>
      <Typography variant="h4" className={styles.label} paragraph>
        idk some warm up activity goes here how was your weekend or something
      </Typography>
    </>
  );

  const lastStep = (
    <Typography variant="h4" className={styles.label} paragraph>
      Annual Review for {getQuarter(data.organization.fiscalYear)} Complete
    </Typography>
  );

  const threeYearObjectivesStep =
    data.threeYear &&
    data.threeYear.map(({ id, value, status, users, objectives, plan }) => {
      return (
        <>
          <Typography variant="h3" paragraph>
            <span className={styles.label}>Objective: </span>
            {value}
          </Typography>

          <Grid container spacing={3} className={styles.gridDetails}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Persons Accountable: </span>
                <UserAvatars users={users} />
              </Typography>

              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Plan: </span>3 Year Plan
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Progress: </span>
                <span>
                  <ProgressBar objectives={objectives} organization={params.org} />
                </span>
              </Typography>

              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Status: </span>
                <span>
                  <div className={styles.info}>
                    <Status status={status} />
                    <Menu icon="arrow">
                      <MenuItem onClick={handleUpdateStatus(id, "on track")} disabled={status === "on track"}>
                        Mark as on track
                      </MenuItem>
                      <MenuItem onClick={handleUpdateStatus("off track")} disabled={status === "off track"}>
                        Mark as off track
                      </MenuItem>
                    </Menu>
                  </div>
                </span>
              </Typography>

              <div className={styles.buttons}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleEditDialog({
                    id,
                    initForm: { value, users: users.map((u) => u.id), objectives },
                    category: "3 year",
                    type: "objective",
                  })}
                >
                  Edit
                </Button>
                <Button color="primary" variant="outlined" onClick={handleNotes(id, "objective")}>
                  Notes
                </Button>
                <Button color="primary" variant="outlined" onClick={handleAddObjectiveDialog("3 year")}>
                  New objective
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      );
    });

  const objectivesStep =
    objectives &&
    objectives.map(({ id, description, plan, rocks, status, users, value }) => {
      return (
        <>
          <Typography variant="h3" paragraph>
            <span className={styles.label}>1 Year Objective: </span>
            {value}
          </Typography>
          <Typography variant="h5" paragraph align="left" className={styles.description}>
            <span className={styles.label}>Description: </span>
            {description || <em>No description</em>}
          </Typography>
          <Grid container spacing={3} className={styles.gridDetails}>
            <Grid item xs={12} sm={6}>
              <div className={styles.grid}>
                <Typography variant="h5" gutterBottom className={styles.infoSc}>
                  <span className={styles.label}>Rocks: </span>
                </Typography>

                <List>
                  {rocks &&
                    rocks.map((rock, i) => {
                      return (
                        <ListItem key={i}>
                          <Rock rock={rock} key={i} />
                        </ListItem>
                      );
                    })}
                  <ListItem className={styles.centerButton}>
                    <IconButton size="small" onClick={handleAddRockDialog(id, quarterNumber)}>
                      <Icon path={mdiPlusCircle} size={1} color="rgba(0, 0, 0, .1)" />
                    </IconButton>
                  </ListItem>
                </List>
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Person Accountable: </span>
                <UserAvatar user={users[0]} />
              </Typography>

              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Plan: </span>
                {plan.departmentName || "1 Year Plan"}
              </Typography>

              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Progress: </span>
                <span>
                  <ProgressBar rocks={rocks} />
                </span>
              </Typography>

              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Status: </span>
                <span>
                  <div className={styles.info}>
                    <Status status={status} />
                    <Menu icon="arrow">
                      <MenuItem onClick={handleUpdateStatus(id, "on track")} disabled={status === "on track"}>
                        Mark as on track
                      </MenuItem>
                      <MenuItem onClick={handleUpdateStatus("off track")} disabled={status === "off track"}>
                        Mark as off track
                      </MenuItem>
                    </Menu>
                  </div>
                </span>
              </Typography>

              <div className={styles.buttons}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleEditDialog({
                    id,
                    initForm: { value, description, users: users.map((u) => u.id), objectives },
                    category: "1 year",
                    type: "objective",
                  })}
                >
                  Edit
                </Button>
                <Button color="primary" variant="outlined" onClick={handleNotes(id, "objective")}>
                  Notes
                </Button>
                <Button color="primary" variant="outlined" onClick={handleAddObjectiveDialog("1 year")}>
                  New objective
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      );
    });

  const metricsStep =
    data.metricsByCategory &&
    data.metricsByCategory.map(({ id, measurables, status, unit, users, value, plan }) => {
      return (
        <>
          <Typography variant="h3" paragraph>
            <span className={styles.label}>Metric: </span>
            {value}
          </Typography>
          <Grid container spacing={3} className={styles.gridDetails}>
            <Grid item xs={12} sm={6}>
              {measurables &&
                measurables.map(([year, projected, actual], i) => {
                  return (
                    <Typography variant="h5" gutterBottom className={styles.info}>
                      <span className={styles.label}>Year {i + 1}: </span>
                      {getUnit(actual, unit) || "—"} / {getUnit(projected, unit)}
                    </Typography>
                  );
                })}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Person Accountable: </span>
                <UserAvatar user={users[0]} />
              </Typography>

              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Plan: </span>
                {plan.departmentName || "1 Year Plan"}
              </Typography>

              <Typography variant="h5" gutterBottom className={styles.info}>
                <span className={styles.label}>Status: </span>
                <span>
                  <div className={styles.info}>
                    <Status status={status} />
                    <Menu icon="arrow">
                      <MenuItem onClick={handleUpdateStatus(id, "on track")} disabled={status === "on track"}>
                        Mark as on track
                      </MenuItem>
                      <MenuItem onClick={handleUpdateStatus("off track")} disabled={status === "off track"}>
                        Mark as off track
                      </MenuItem>
                    </Menu>
                  </div>
                </span>
              </Typography>

              <div className={styles.buttons}>
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleEditDialog({
                    id,
                    initForm: { value, measurables, unit, users: users.map((u) => u.id) },
                    type: "metric",
                  })}
                >
                  Edit
                </Button>
                <Button color="primary" variant="outlined" onClick={handleNotes(id, "metric")}>
                  Notes
                </Button>
                <Button color="primary" variant="outlined" onClick={handleAddMetricDialog("year")}>
                  New metric
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      );
    });

  const steps = [startStep, warmupStep, ...threeYearObjectivesStep, ...objectivesStep, ...metricsStep, lastStep];

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h6" className={styles.label}>
                Annual Review
              </Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <div className={styles.centered}>
              {steps[step]}
              <div>
                {step > 1 && step !== steps.length - 1 && (
                  <Button color="primary" variant="contained" size="large" className={styles.button} onClick={prevStep}>
                    Prev
                  </Button>
                )}
                {step < steps.length - 1 && (
                  <Button color="primary" variant="contained" size="large" className={styles.button} onClick={nextStep}>
                    {step > 0 ? "Next" : "Start"}
                  </Button>
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>

      {editInfo.type === "objective" && (
        <ObjectiveEditDialog
          open={Boolean(editInfo.id)}
          handleClose={handleEditDialog({ type: false })}
          id={editInfo.id}
          initForm={editInfo.initForm}
          category={editInfo.category}
          snack={snack}
        />
      )}

      {editInfo.type === "metric" && (
        <MetricEditDialog
          open={Boolean(editInfo.id)}
          handleClose={handleEditDialog({ type: false })}
          id={editInfo.id}
          initForm={editInfo.initForm}
          snack={snack}
        />
      )}
    </>
  );
};

export default AnnualReview;

const GET_OBJECTIVES_AND_METRICS = gql`
  query AnnualReview_GetObjsMetrics($organization: ID!) {
    organization(id: $organization) {
      id
      fiscalYear
    }

    threeYear: objectives(organization: $organization, category: "3 year") {
      id
      value
      status
      plan {
        id
      }
      users {
        id
        profilePicture
        name {
          first
          last
        }
      }
      objectives
    }

    oneYear: objectives(organization: $organization, category: "1 year") {
      ...objectiveFields
    }

    metricsByCategory: metrics(organization: $organization, category: "year") {
      id
      value
      measurables {
        value
        notes {
          id
        }
        reference {
          id
        }
        distribution
      }
      unit
      status
      users {
        id
        profilePicture
        name {
          first
          last
        }
      }
      plan {
        id
        departmentName
      }
    }
  }

  fragment objectiveFields on Objective {
    id
    value
    description
    plan {
      id
      departmentName
    }
    status
    users {
      id
      profilePicture
      name {
        first
        last
      }
    }
    rocks {
      id
      value
      index
      successCriterias {
        id
        value
        done
      }
      users {
        id
        name {
          first
          last
        }
        profilePicture
      }
      notes {
        id
        date
      }
      todos {
        id: _id
        creaetedAt
      }
    }
  }
`;
