import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import useForm from "../../hooks/useForm";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { UserContext } from "../../context/userContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";

const EditAdminDialog = ({ open, handleClose, initForm, organizations }) => {
  const history = useHistory();
  const [updateUser] = useMutation(UPDATE_USER);
  const [createUser] = useMutation(CREATE_ADMIN);
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { user } = useContext(UserContext);
  const { form, handleChange, resetForm } = useForm({
    initForm,
  });

  const handleSubmit = async () => {
    if (form.id) {
      const isUpdatingSelf = user.user.id === form.id;
      const ok = await updateUser({
        variables: form,
      });

      if (ok) {
        if (isUpdatingSelf) {
          snack("Updated admin. Please log in to view changes.");
          setTimeout(() => {
            history.push("/signout");
          }, 5000);
        } else {
          snack("Updated admin");
          requestFetch();
          handleClose();
        }
      }
    } else {
      const { data } = await createUser({
        variables: form,
      });

      if (data.createAdmin.ok) {
        snack(`Created user: ${form.firstName} ${form.lastName}`);
        requestFetch();
        handleClose();
      } else {
        snack(data.createAdmin.reason, "error");
      }
    }
  };

  useEffect(() => {
    resetForm(initForm);
  }, [initForm]);

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
    >
      <DialogTitle>{form.id ? "Edit" : "Create new"} Admin</DialogTitle>
      <DialogContent>
        <TextField
          label="First Name"
          name="firstName"
          fullWidth
          variant="outlined"
          margin="normal"
          value={form.firstName || ""}
          onChange={handleChange}
        />
        <TextField
          label="Last Name"
          name="lastName"
          fullWidth
          variant="outlined"
          margin="normal"
          value={form.lastName || ""}
          onChange={handleChange}
        />
        <TextField
          label="Email"
          name="email"
          fullWidth
          variant="outlined"
          margin="normal"
          value={form.email || ""}
          onChange={handleChange}
        />
        {!form.id && (
          <TextField
            label="Password"
            name="password"
            fullWidth
            type="password"
            variant="outlined"
            margin="normal"
            value={form.password || ""}
            onChange={handleChange}
          />
        )}

        <FormControl fullWidth variant="outlined" margin="normal">
          <InputLabel>Authorization</InputLabel>
          <Select name="auth" value={form.auth || ""} onChange={handleChange} label="Authorization">
            <MenuItem value="ventrek admin">Ventrek Admin</MenuItem>
            <MenuItem value="ventrek facilitator">Ventrek Facilitator</MenuItem>
          </Select>
        </FormControl>
        {form.auth === "ventrek facilitator" && (
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Organizations</InputLabel>
            <Select name="organizations" value={form.organizations} multiple onChange={handleChange} label="Organizations">
              {organizations &&
                organizations.map(({ name, id }) => {
                  return (
                    <MenuItem value={id} key={id}>
                      {name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAdminDialog;

const UPDATE_USER = gql`
  mutation ($id: ID!, $firstName: String, $lastName: String, $email: String, $auth: String, $organizations: [ID!]) {
    updateUser(id: $id, firstName: $firstName, lastName: $lastName, email: $email, auth: $auth, organizations: $organizations)
  }
`;

const CREATE_ADMIN = gql`
  mutation ($firstName: String!, $lastName: String!, $email: String!, $auth: String!, $organizations: [ID!], $password: String!) {
    createAdmin(
      firstName: $firstName
      lastName: $lastName
      email: $email
      auth: $auth
      organizations: $organizations
      password: $password
    ) {
      ok
      reason
    }
  }
`;
