import React from "react";
import styles from "./Objectives.module.scss";
import { TableCell } from "@material-ui/core";
import SkeletonMui from "@material-ui/lab/Skeleton";

const Skeleton = ({ long }) => {
  return (
    <>
      <TableCell />
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="circle" height={40} width={40} />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="rect" height={25} width={225} />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="circle" height={25} width={25} />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          {long ? <SkeletonMui variant="circle" height={25} width={25} /> : <SkeletonMui variant="text" width={100} />}
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="text" width={100} />
        </div>
      </TableCell>
      {long && (
        <TableCell>
          <div className={styles.flexCenter}>
            <SkeletonMui variant="text" width={100} />
          </div>
        </TableCell>
      )}
    </>
  );
};

export default Skeleton;
