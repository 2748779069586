import styles from "./MultiYearProgressDisplay.module.scss"

import React, { useState } from "react";
import { Grid } from "@material-ui/core";

import { getPlanQuarterProgress } from "../../utils/dates";
import { addQuarters, format, sub } from "date-fns";


 const MultiYearProgressDisplay = ({ planTargetDate, planLengthYears }) => {

    const getQuarterBlockColour = (checkQuarter, currentQuarter) => {
        let colour = '';

        const quarterDiff  = checkQuarter - currentQuarter;
        if (quarterDiff < 0) {
            colour = 'red';
        }
        else if (quarterDiff === 0) {
            colour = 'purple';
        }
        else if ((quarterDiff >= 1) && (quarterDiff <= 3)) {
            colour = 'rgb(144, 121, 182)';
        }
        else {
            colour = 'rgb(149, 120, 211)';
        }

        return colour;
    }

    const initializeQuarterBlocks = (planTargetDate, planLengthYears) => {

        const { totalQuarters, currentQuarter, planStartDate } = getPlanQuarterProgress(planTargetDate, planLengthYears);

        return Array(20).fill().map( (item, index) => {
            const itemQuarter = index+1; // +1 because totalQuarters, currentQuarter, etc are not zero based

            const quarterStartDate = addQuarters(planStartDate, itemQuarter-1);
            const quarterEndDate = sub(addQuarters(quarterStartDate, 1), { days: 1 });

            return ( {
                id: index,
                value: ((totalQuarters - itemQuarter)+1) * 3,
                colour: getQuarterBlockColour(itemQuarter, currentQuarter),
                isCurrent: (index+1) === currentQuarter ? true : false,
                quarterStartDate,
                quarterEndDate,
            });
        });
    }
    
    // Function to chunk the array into sub-arrays of max 4 items
    const chunkArray = (array, chunkSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    };

    const createYearlyBlocksItems = (quarterBlocks) => {

        const quarterYearlyBlocks = chunkArray(quarterBlocks, 4);

        return (
            <>
            {quarterYearlyBlocks.map((quarterBlockItems, index) => {
                return (
                    <div key={`cell-${index}`} className={styles.cell}>
                        <Grid container className={styles.quarterContainer} >                    
                            <Grid item >
                                <Grid container className={styles.quarterBlockContainer} >
                                    {quarterBlockItems.map(quarterBlockItem => (
                                        <Grid item key={`qbi-${quarterBlockItem.id}`} className={styles.quarterBlock} style={{backgroundColor: quarterBlockItem.colour}}>{quarterBlockItem.value}</Grid>
                                    ))}
                                </Grid>                                      
                            </Grid>                        
                            <Grid item className={styles.yearLabel} >
                                <div>{format(quarterBlockItems[quarterBlockItems.length-1].quarterEndDate,'MMM d')}</div>
                                <div>{format(quarterBlockItems[quarterBlockItems.length-1].quarterEndDate,'yyyy')}</div >                   
                            </Grid>                    
                        </Grid>  
                    </div>                        
                )

            })}
            </>
          );



    }
    
    const [quarterBlocks, setQuartersBlocks] = useState( () => initializeQuarterBlocks(planTargetDate, planLengthYears));

    const yearlyBlockItems = createYearlyBlocksItems(quarterBlocks);

    return (
        <div className={styles.container}>
            {yearlyBlockItems}
        </div>
    );


}

export default MultiYearProgressDisplay;

