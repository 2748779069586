import React, { useMemo, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
  Switch,
  FormControl,
  InputLabel,
  Avatar,
} from "@material-ui/core";
import SelectDepartment from "../../components/SelectDepartment/SelectDepartment";
import { NOTIFICATION_FREQUENCY } from "../../utils/const";
import { permissionDictionary } from "../../utils/authorization";
import { UserContext } from "../../context/userContext";
import Icon from "@mdi/react";
import { mdiAccount } from "@mdi/js";
import styles from "./Users.module.scss";
import { removeTypenameDeep } from "../../utils/misc";

const EditDialog = ({
  open,
  handleClose,
  user,
  form,
  resetForm,
  formErrors,
  handleChange,
  handleToggleCheckBox,
  handleSubmit,
  handleUploadImage,
  handleCreateUserCancel,
  plans,
}) => {
  const { user: currentUser } = useContext(UserContext);
  const authOptions = useMemo(() => {
    const currentLevel = permissionDictionary[currentUser.user.auth];
    const arr = [];
    for (let key in permissionDictionary) {
      if (permissionDictionary[key] >= currentLevel && !key.includes("ventrek")) arr.push(key);
    }
    return arr;
  }, [currentUser]);

  const changeAvatar = async (e, form) => {
    const existingProfilePicture = _.get(form, "profilePicture");
    let profilePictureUrl = await handleUploadImage(e, existingProfilePicture);
    handleChange({
      target: {
        name: "profilePicture",
        value: profilePictureUrl,
      },
    });
  };
  useEffect(() => {
    if (user) {
      const { id, name, auth, position, email, plan, notificationSettings, profilePicture } = user;
      const notificationsWithoutTypename = removeTypenameDeep(notificationSettings);
      let editForm = {
        id,
        firstName: name.first,
        lastName: name.last,
        position,
        email,
        password: "",
        plan: plan.map(({ id }) => id),
        notificationSettings: notificationsWithoutTypename,
        auth,
        profilePicture,
      };
      resetForm(editForm);
    }
  }, [user]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        onExited={() => resetForm()}
      >
        <DialogTitle>{user ? "Edit" : "Create"} User</DialogTitle>
        <DialogContent>
          <TextField
            label="First Name"
            name="firstName"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.firstName || ""}
            onChange={handleChange}
            helperText={formErrors.firstName}
            error={Boolean(formErrors.firstName)}
          />
          <TextField
            label="Last Name"
            name="lastName"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.lastName || ""}
            onChange={handleChange}
            helperText={formErrors.lastName}
            error={Boolean(formErrors.lastName)}
          />
          <TextField
            label="Email"
            name="email"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.email || ""}
            onChange={handleChange}
            helperText={formErrors.email}
            error={Boolean(formErrors.email)}
          />
          <TextField
            type="password"
            label={user ? "New Password" : "Password"}
            name="password"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.password || ""}
            onChange={handleChange}
            helperText={formErrors.password}
            error={Boolean(formErrors.password)}
          />
          <TextField
            label="Title"
            name="position"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.position || ""}
            onChange={handleChange}
            helperText={formErrors.position}
            error={Boolean(formErrors.position)}
          />
          <input
            name="file"
            onChange={(e) => changeAvatar(e, form)}
            accept="image/*"
            id="file-upload"
            type="file"
            className={styles.input}
          />
          <div className={styles.fileUpload}>
            <label htmlFor="file-upload">
              <Avatar src={_.get(form, "profilePicture")}>
                <Icon path={mdiAccount} size={1} />
              </Avatar>
            </label>
            <div>Profile Picture</div>
          </div>
          <SelectDepartment
            plans={plans}
            name="plan"
            handleChange={handleChange}
            value={form.plan}
            helperText="Which department does this user belong to?"
            multiple
          />
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel>Authorization</InputLabel>
            <Select name="auth" value={form.auth || ""} onChange={handleChange} label="Authorization" data-test='cud-select-auth'>
              {authOptions.map((option, idx) => {
                return (
                  <MenuItem key={idx} onClick={handleChange} value={option} disabled={form.auth === option} data-test='cud-select-item-auth'>
                    {_.startCase(option)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {form.notificationSettings &&
            Object.keys(form.notificationSettings).map((setting, idx) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  margin: "0 10px 10px 0",
                  maxWidth: "500px",
                }}
                key={idx}
              >
                <Typography variant="subtitle1">{_.startCase(setting)}</Typography>
                {_.get(form, `notificationSettings.${setting}.frequency`, "N/A") !== "N/A" && (
                  <Select
                    value={_.get(form, `notificationSettings.${setting}.frequency`, "N/A")}
                    onChange={handleChange}
                    name={`notificationSettings.${setting}.frequency`}
                    label={"Frequency"}
                  >
                    {NOTIFICATION_FREQUENCY.map((value) => {
                      return (
                        <MenuItem key={value} value={value} data-test='cud-item-notifications'>
                          {_.startCase(value)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
                <Switch
                  checked={_.get(form, `notificationSettings.${setting}.enabled`, false)}
                  onChange={handleToggleCheckBox}
                  name={`notificationSettings.${setting}.enabled`}
                  data-test='cud-switch-notifications'
                />
              </div>
            ))}
          {/* <Menu icon="arrow">
              {authOptions.map((option) => {
                return (
                  <MenuItem onClick={handleChangeAuth(option)} disabled={auth === option}>
                    Change authorization to {_.startCase(option)}
                  </MenuItem>
                );
              })}
            </Menu> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={user ? handleClose : () => handleCreateUserCancel(form)} variant="outlined">
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit} variant="contained" data-test='cud-button-save'>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialog;
