import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import useForm from "../../hooks/useForm";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import _ from "lodash";
import gql from "graphql-tag";

import { FetchContext } from "../../context/fetchContext";
import { UserContext } from "../../context/userContext";

import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, Typography, DialogContentText } from "@material-ui/core";

import SelectQuarter from "../SelectQuarter/SelectQuarter";
import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import SelectObjectives from "../SelectObjectives/SelectObjectives";
import SuccessCriteriaList from "./SuccessCriteriaList";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import Loading from "../Loading/Loading";

const initErrorForm = {
  value: ["required"],
};

const EditDialog = ({ open, handleClose, rock, snack, handleAddIssueDialog, planId, objectiveId }) => {
  const { id, value, index, users, successCriterias, objective, plan, status } = rock;

  const [scToBeDeleted, setScToBeDeleted] = useState([]);
  const [submitFormAfterChange, setSubmitFormAfterChange] = useState(false);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const { user } = useContext(UserContext);
  const { sharedPlanId } = _.get(user, "departmentFilter");
  const { fetch, requestFetch } = useContext(FetchContext);
  const [updateRock] = useMutation(UPDATE_ROCK);
  const {
    data,
    refetch,
    loading: userDataLoading,
  } = useQuery(GET_USERS_OBJS_PLANS, {
    variables: { organization: params.org, sharedPlanId, corpPlan: planId },
  });

  const itemType = "success criteria";
  const { confirmOpen, handleConfirmOpen } = useConfirmDelete({
    id,
    value: scToBeDeleted.length,
    itemType,
  });

  const handleScToBeDeleted = (scToDelete) => {
    setScToBeDeleted((prev) => [...prev, scToDelete]);
  };

  const initForm = {
    value,
    users: _.map(users, "id"),
    index,
    objective: objectiveId ? objectiveId : _.get(objective, "id", null),
    plan: _.get(plan, "id", null),
    successCriterias,
    status,
  };

  const { form, formErrors, handleChange, handleChangeManual, validateForm, resetForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setLoading(true);
    const { value, users, index, status, objective, plan, successCriterias } = form;

    const ok = await updateRock({
      variables: {
        id,
        value,
        users,
        index,
        status,
        objective,
        plan,
        successCriteria: successCriterias.map((sc) => _.pick(sc, ["id", "value", "targetDate", "done"])),
      },
    });

    if (ok.data.updateRock) {
      snack(`Updated "${value}" rock`);
      requestFetch();
      handleClose();

      if (confirmOpen) {
        handleConfirmOpen(false)();
      }
      setScToBeDeleted([]);
    }

    setLoading(false);
  };

  const handleSave = async () => {
    if (_.isEmpty(scToBeDeleted)) {
      await handleSubmit();
    } else {
      handleConfirmOpen(true)();
    }
  };

  const handleCancel = () => {
    setScToBeDeleted([]);
    handleClose();
  };

  const handleChangeComplete = () => {
    setSubmitFormAfterChange(true);
    handleChangeManual({ name: "status", value: form.status === "complete" ? "on track" : "complete" });
  };

  useEffect(() => {
    if (submitFormAfterChange) {
      setSubmitFormAfterChange(false);
      handleSave();
    }
  }, [form]);

  useEffect(() => {
    if (open) {
      refetch();
    }
  }, [fetch]);

  useEffect(() => {
    resetForm(initForm);
  }, [id]);

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullWidth
      >
        <DialogTitle>Edit Rock</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Rock Description"
            name="value"
            fullWidth
            variant="outlined"
            margin="normal"
            value={form.value || ""}
            onChange={handleChange}
            multiline
            helperText={formErrors.value}
            error={Boolean(formErrors.value)}
          />
          <SelectQuarter name="index" value={form.index} handleChange={handleChange} />
          <SelectObjectives
            name="objective"
            objectives={_.get(data, "objectives", null)}
            handleChange={handleChange}
            values={form.objective}
            category="rock"
            helperText={formErrors.objective}
            error={Boolean(formErrors.objective)}
            plansOrder={_.get(data, "organization.plansOrder")}
          />
          <SelectDepartment plans={_.get(data, "plans")} name="plan" handleChange={handleChange} value={form.plan} />
          {!userDataLoading && (
            <SelectUsers
              name="users"
              users={_.get(data, "users")}
              handleChange={handleChange}
              values={form.users}
              plan={form.plan}
              allPlans={_.get(data, "plans")}
            />
          )}
          <SuccessCriteriaList
            successCriterias={form.successCriterias}
            onChange={handleChangeManual}
            handleAddIssueDialog={handleAddIssueDialog}
            handleToBeDeleted={handleScToBeDeleted}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleChangeComplete} variant="contained" color="primary" style={{ marginRight: "auto" }}>
            {submitFormAfterChange || loading ? (
              <Loading size={24} color="#fff" />
            ) : (
              `Mark as ${form.status === "complete" ? "Incomplete" : "Complete"}`
            )}
          </Button>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button color="primary" onClick={handleSave} variant="contained">
            {loading ? <Loading size={24} color="#fff" /> : "Save"}
          </Button>
        </DialogActions>
      </Dialog>
      <ConfirmDeletionDialog
        itemType={itemType}
        confirmOpen={confirmOpen}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleSubmit}
      >
        {!_.isEmpty(scToBeDeleted) &&
          scToBeDeleted.map((sc) => {
            return <DialogContentText>&#x25cf; {sc.value}</DialogContentText>;
          })}
      </ConfirmDeletionDialog>
    </>
  );
};

export default EditDialog;

const UPDATE_ROCK = gql`
  mutation RocksEditDialog_UpdateRock(
    $id: ID!
    $value: String
    $users: [ID!]
    $index: Int!
    $status: String
    $objective: ID
    $plan: ID
    $successCriteria: [SuccessCriterionInput]
  ) {
    updateRock(
      id: $id
      value: $value
      users: $users
      index: $index
      status: $status
      objective: $objective
      plan: $plan
      successCriteria: $successCriteria
    )
  }
`;

const GET_USERS_OBJS_PLANS = gql`
  query RocksEditDialog_GetUsersObjsPlans($organization: ID!, $sharedPlanId: ID, $corpPlan: ID) {
    organization(id: $organization) {
      id
      plansOrder
    }

    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    objectives(organization: $organization, sharedPlanId: $sharedPlanId, corpPlan: $corpPlan) {
      id
      value
      category
      number
      plan {
        id
        departmentName
        sharedPlanId
        color
        shortName
      }
    }

    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $corpPlan) {
      id
      departmentName
      sharedPlanId
    }
  }
`;
