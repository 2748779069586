import React, { useState, useEffect, useContext } from "react";
import styles from "./SelectOrganization.module.scss";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  Typography,
  Card,
  CardContent,
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  TextField,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiTrashCanOutline } from "@mdi/js";
import { isAuthed, isVentrekAdmin } from "../../utils/authorization";
import { UserContext } from "../../context/userContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { DialogContext } from "../../context/dialogContext";
import { FetchContext } from "../../context/fetchContext";
import Loading from "../../components/Loading/Loading";
import { fiscalYearStart, fullDate } from "../../utils/dates";
import logo from "../../assets/img/logo-white.svg";
import splash from "../../assets/img/splash.svg";

import AddOrgDialog from "../../components/AddOrgDialog/AddOrgDialog";
import EditUserDialog from "./EditAdminDialog";
import ConfirmDialog from "../OneYear/ConfirmDialog";

const ORGANIZATIONS = "Organizations";
const ADMINS = "Admins";

const SelectOrganization = () => {
  const { user } = useContext(UserContext);
  const { snack } = useContext(SnackbarContext);
  const { dialog, setDialog } = useContext(DialogContext);
  const { fetch, requestFetch } = useContext(FetchContext);
  const [deleteConfirm, setDeleteConfirm] = useState({ name: "", callback: null });
  const [deleteName, setDeleteName] = useState();
  const [view, setView] = useState(ORGANIZATIONS); // organizations, admins
  const [editDialog, setEditDialog] = useState({});
  const { loading, data, refetch } = useQuery(GET_ORGANIZATIONS_AND_USERS, { variables: { ids: _.get(user, "user.organizations", []) } });
  const [updateActive] = useMutation(UPDATE_ACTIVE);
  const [deleteOrg] = useMutation(DELETE_ORGANIZATION);
  const [deleteAdmin] = useMutation(DELETE_ADMIN);
  const history = useHistory();

  // confirm dialog states
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogFunc, setConfirmDialogFunc] = useState(() => () => {});
  const [confirmDialogMsg, setConfirmDialogMsg] = useState("");

  const handleLogOut = () => {
    history.push("/signout");
  };

  const handleAddOrg = () => {
    setDialog({ ...dialog, addOrgDialog: true });
  };

  const handleConfirmDialog = (name, callback) => () => {
    if (_.isNil(name)) {
      setDeleteConfirm({ name: "", callback: null });
      setDeleteName();
    } else {
      setDeleteConfirm({ name, callback });
    }
  };

  const handleChange = (e) => {
    setDeleteName(e.target.value);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const onExitConfirmDialog = () => {
    setConfirmDialogFunc(() => () => {});
    setConfirmDialogMsg("");
  };

  const handleToggleActive = (id, active, name) => async () => {
    if (!active) {
      setConfirmDialogOpen(true);
      setConfirmDialogMsg(`You are about to deactivate ${name}. Are you sure?`);
      setConfirmDialogFunc(() => async () => {
        await updateActive({ variables: { id, active } });
        handleCloseConfirmDialog();
        requestFetch();
      });
    } else {
      await updateActive({ variables: { id, active } });
      requestFetch();
    }
  };

  const handleDeleteOrg = (id) => async () => {
    const ok = await deleteOrg({ variables: { id } });

    if (ok) {
      snack(`Deleted organization`);
      requestFetch();
      setDeleteConfirm({ name: "", callback: null });
      setDeleteName();
    }
  };

  const handleView = () => {
    if (view === ORGANIZATIONS) {
      setView(ADMINS);
    } else if (view === ADMINS) {
      setView(ORGANIZATIONS);
    }
  };

  const handleEditAdmin = (user) => () => {
    if (user) {
      const { id, name, email, organizations, auth } = user;
      setEditDialog({ id, email, organizations, auth, firstName: name.first, lastName: name.last });
    } else {
      setEditDialog({});
    }
  };

  const handleDeleteAdmin = (id) => async () => {
    const ok = await deleteAdmin({ variables: { id } });

    if (ok) {
      snack(`Deleted admin`);
      requestFetch();
      setDeleteConfirm({ name: "", callback: null });
      setDeleteName();
    }
  };

  const organizations = _.get(data, "organizations", []);
  const users = _.get(data, "userAdmins", []);

  //Clear out any departmental filters that might be stored
  sessionStorage.removeItem("departmentFilterId");
  sessionStorage.removeItem("departmentFilterSharedId");

  useEffect(() => {
    refetch();
  }, [fetch]);

  if (!isAuthed(user.user, "ventrek facilitator")) history.push(`/${user.user.organization}`);
  return (
    <>
      <div className={styles.container}>
        <div className={styles.splash}>
          <div className={styles.splashOverlay}>
            <img src={logo} alt="Ventrek Logo" className={styles.img} />
            <Typography variant="h3" className={styles.splashTitle}>
              Welcome to Ventrek
            </Typography>
          </div>
          <img src={splash} alt="splash" className={styles.svg} />
        </div>
        <div className={styles.content}>
          <div className={styles.contentWrapper}>
            {view === ORGANIZATIONS && (
              <>
                <Typography variant="h2" gutterBottom>
                  Select an organization
                </Typography>

                {isVentrekAdmin(user.user) && (
                  <div className={styles.cardContainer}>
                    <Card className={styles.card} onClick={handleAddOrg} data-test="link-create-org">
                      <CardContent className={styles.createBtn}>
                        <Typography variant="h6" align="center">
                          Create a new organization
                        </Typography>
                      </CardContent>
                    </Card>
                  </div>
                )}

                {loading ? (
                  <Loading color="#fff" />
                ) : (
                  _.sortBy(organizations, (o) => !o.active).map(({ id, name, fiscalYear, active }) => {
                    return (
                      <div className={active ? styles.cardContainer : styles.cardContainerInactive} key={id} data-test={`card-company-${id}`}>
                        <Link to={`/${id}`} className={styles.link}>
                          <Card className={styles.card}>
                            <CardContent>
                              <Typography variant="h6">{name}</Typography>
                              <Typography variant="subtitle1" className={styles.fiscalYear}>
                                <span>Fiscal Year: </span>
                                {fullDate(fiscalYearStart(fiscalYear))} - {fullDate(fiscalYear)}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Link>
                        <div className={styles.buttons}>
                          <Button
                            color={active ? "primary" : "secondary"}
                            variant="outlined"
                            onClick={handleToggleActive(id, !active, name)}
                            data-test='button-org-active'
                          >
                            {active ? "Active" : "Inactive"}  
                          </Button>
                          <IconButton 
                            onClick={handleConfirmDialog(name, handleDeleteOrg(id))}
                            data-test='button-org-delete'
                          >
                            <Icon path={mdiTrashCanOutline} size={1} color="#ef5350" />
                          </IconButton>
                        </div>
                      </div>
                    );
                  })
                )}
              </>
            )}

            {view === ADMINS && (
              <>
                <Typography variant="h2" gutterBottom>
                  Manage Admins
                </Typography>

                <div className={styles.cardContainer}>
                  <Card
                    className={styles.card}
                    onClick={handleEditAdmin({
                      name: {
                        first: "",
                        last: "",
                      },
                      email: "",
                      auth: "ventrek facilitator",
                      organizations: [],
                      id: null,
                    })}
                  >
                    <CardContent className={styles.createBtn}>
                      <Typography variant="h6" align="center">
                        Create a new admin
                      </Typography>
                    </CardContent>
                  </Card>
                </div>

                {_.sortBy(users, (u) => u.disabled === true).map((user) => {
                  const { id, name, email, organizations, auth, disabled } = user;
                  return (
                    <div className={!disabled ? styles.cardContainer : styles.cardContainerInactive} key={id}>
                      <Card>
                        <CardContent>
                          <Typography variant="h6">
                            {name.first} {name.last}{" "}
                            <Typography variant="body2" display="inline">
                              {email}
                            </Typography>
                          </Typography>
                          <Typography variant="subtitle1" className={styles.auth}>
                            {auth}
                            {auth === "ventrek facilitator" && <span> - Organization: {organizations.length}</span>}
                          </Typography>
                        </CardContent>
                      </Card>

                      <div className={styles.buttons}>
                        <Button color={!disabled ? "primary" : "secondary"} variant="outlined" onClick={handleEditAdmin(user)}>
                          Edit
                        </Button>
                        <IconButton
                          onClick={handleConfirmDialog(`${name.first} ${name.last}`, handleDeleteAdmin(id))}
                          disabled={disabled === true}
                        >
                          <Icon path={mdiTrashCanOutline} size={1} color="#ef5350" />
                        </IconButton>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>

          <div className={styles.flex}>
            {isVentrekAdmin(user.user) && (
              <div className={styles.cardContainer}>
                <Card className={styles.card} onClick={handleView}>
                  <CardContent className={styles.createBtn}>
                    <Typography variant="h6" align="center">
                      {view === ORGANIZATIONS ? ADMINS : ORGANIZATIONS}
                    </Typography>
                  </CardContent>
                </Card>
              </div>
            )}
            <div className={styles.cardContainer}>
              <Card className={styles.card} onClick={handleLogOut} data-test="button-logout">
                <CardContent className={styles.logoutBtn}>
                  <Typography variant="h6" align="center">
                    Log out
                  </Typography>
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={Boolean(deleteConfirm.name)}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleConfirmDialog()();
          }
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <span className={styles.red}>You are going to remove {deleteConfirm.name}. Are you ABSOLUTELY sure?</span>
          </DialogContentText>
          <DialogContentText>
            Please type <code>{deleteConfirm.name}</code> to proceed.
          </DialogContentText>
          <TextField variant="outlined" fullWidth value={deleteName || ""} onChange={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleConfirmDialog()}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={deleteConfirm.callback} disabled={deleteName !== deleteConfirm.name}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={confirmDialogOpen}
        handleClose={handleCloseConfirmDialog}
        handleConfirm={confirmDialogFunc}
        message={confirmDialogMsg}
        onExited={onExitConfirmDialog}
      />

      <AddOrgDialog dialog={dialog} setDialog={setDialog} user={user} requestFetch={requestFetch} snack={snack} />

      <EditUserDialog open={!_.isEmpty(editDialog)} handleClose={handleEditAdmin()} initForm={editDialog} organizations={organizations} />
    </>
  );
};

export default SelectOrganization;

const GET_ORGANIZATIONS_AND_USERS = gql`
  query ($ids: [ID!]) {
    organizations(ids: $ids) {
      id
      name
      fiscalYear
      active
    }
    userAdmins {
      id
      name {
        first
        last
      }
      email
      organizations
      auth
      disabled
    }
  }
`;

const DELETE_ORGANIZATION = gql`
  mutation ($id: ID!) {
    deleteOrganization(id: $id)
  }
`;

const UPDATE_ACTIVE = gql`
  mutation ($id: ID!, $active: Boolean!) {
    updateOrganization(id: $id, active: $active)
  }
`;

const DELETE_ADMIN = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id)
  }
`;
