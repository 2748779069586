import React, { useEffect } from "react";
import _ from "lodash";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem, Checkbox, ListItemText, Typography } from "@material-ui/core";
import PlanPill from "../PlanPill/PlanPill";

const SelectRock = ({ name, rocks, handleChange, value, defaultRock, helperText, error, sharedPlanId }) => {
  useEffect(() => {
    if (!_.isNil(defaultRock)) {
      const e = {
        target: {
          name,
          value: defaultRock,
        },
      };

      handleChange(e);
    }
  }, []);
  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error}>
      <InputLabel>Tied In Rock</InputLabel>
      <Select
        value={value || ""}
        label="Tied In Rock"
        onChange={handleChange}
        name={name}
        renderValue={(id) => {
          const rock = _.find(rocks, { id });
          return (
            <Typography variant="inherit" noWrap>
              {rock ? rock.value : null}
            </Typography>
          );
        }}
        error={error}
      >
        {rocks &&
          rocks
            .filter(({ plan }) => (_.isNil(sharedPlanId) ? true : !_.isNil(plan) ? plan.sharedPlanId === sharedPlanId : false))
            .map(({ id, value: rockValue, plan }) => {
              return (
                <MenuItem key={id} value={id}>
                  <Checkbox checked={value === id} />
                  <ListItemText>
                    {!_.isNil(plan) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
                    {rockValue}
                  </ListItemText>
                </MenuItem>
              );
            })}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectRock;
