import React, { useState, useEffect, useContext } from "react";
import styles from "./TheCore.module.scss";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import { isDate } from "date-fns";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import MenuIcon from "@material-ui/icons/Menu";

const initErrorForm = {
  name: ["required"],
};

const EditDialog = ({ id, open, handleClose, initForm, name, plan, minYear }) => {
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [count, setCount] = useState(0);
  const [listKey, setListKey] = useState(false);
  const [updateOrg] = useMutation(UPDATE_ORG);
  const [updateCore] = useMutation(UPDATE_CORE);
  const [updateBhag] = useMutation(UPDATE_BHAG);

  const { form, formErrors, handleChange, handleChangeDate, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const handleDragEndOrg = ({ draggableId, destination, source }) => {
    if (!destination) return;
    setListKey(!listKey);

    const indexOfName = form.viewPreferences.findIndex((obj) => obj.name === draggableId);
    const clonedForm = _.cloneDeep(form);

    clonedForm.viewPreferences[indexOfName] = { ...clonedForm.viewPreferences[indexOfName], position: destination.index };

    if (source.index < destination.index) {
      const toDecrement = clonedForm.viewPreferences.filter(
        ({ name, position }) =>
          position <= destination.index && name !== clonedForm.viewPreferences[indexOfName].name && position > source.index
      );

      toDecrement.forEach((obj) => obj.position--);
    } else if (source.index > destination.index) {
      const toIncrement = clonedForm.viewPreferences.filter(
        ({ name, position }) =>
          position >= destination.index && name !== clonedForm.viewPreferences[indexOfName].name && position < source.index
      );

      toIncrement.forEach((obj) => obj.position++);
    }

    resetForm(clonedForm);
  };

  const handleDragEndCore = ({ draggableId, destination }) => {
    if (!destination) return;

    const clonedForm = _.cloneDeep(form);
    const val = clonedForm.values.splice(draggableId, 1)[0];
    clonedForm.values.splice(destination.index, 0, val);

    resetForm(clonedForm);
  };

  const handleChangeHidden = (name, show) => () => {
    const indexOfName = form.viewPreferences.findIndex((obj) => obj.name === name);
    const clonedForm = _.cloneDeep(form);
    clonedForm.viewPreferences[indexOfName] = { ...clonedForm.viewPreferences[indexOfName], show: !show };
    resetForm(clonedForm);
  };

  const handleSubmitCore = async () => {
    const { values } = form;
    const ok = await updateCore({
      variables: {
        id,
        name,
        plan,
        values: _.compact(values),
      },
    });

    if (ok.data.updateCore) {
      snack("Updated core details");
      requestFetch();
      handleClose();
    }
  };

  const handleDeleteEntry = (value) => () => {
    const clonedForm = _.cloneDeep(form);
    const index = clonedForm.values.indexOf(value);
    if (index > -1) {
      clonedForm.values.splice(index, 1);
    }

    resetForm(clonedForm);
    if (count > 1) {
      setCount(count - 1);
    }
  };

  const handleSubmitOrg = async () => {
    if (!validateForm()) return;

    const { name, fiscalYear, viewPreferences } = form;
    const ok = await updateOrg({
      variables: {
        id,
        name,
        fiscalYear: isDate(fiscalYear) ? fiscalYear : new Date(fiscalYear),
        viewPreferences,
      },
    });

    if (ok) {
      snack("Updated organization details");
      requestFetch();
      handleClose();
    }
  };

  const handleSubmitBhag = async () => {
    const { goal, targetDate } = form;
    const ok = await updateBhag({
      variables: {
        id,
        goal: goal,
        targetDate: new Date(targetDate),
      },
    });

    if (ok) {
      snack("Updated 5 Year Mission");
      requestFetch();
      handleClose();
    }
  };

  useEffect(() => {
    const values = _.get(initForm, "values", []);
    resetForm(initForm);
    setCount(values.length);
  }, [open]);

  return (
    <>
      <Dialog
        open={Boolean(open.core)}
        fullWidth
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullScreen={fs}
      >
        <DialogTitle>Edit Core {_.startCase(name)}</DialogTitle>
        <DialogContent>
          <DragDropContext onDragEnd={handleDragEndCore}>
            <Droppable droppableId={"0"}>
              {(provided, snapshot) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {Array.from(Array(count), (_, i) => (
                      <Draggable draggableId={i.toString()} index={i} key={i}>
                        {(provided, snapshot) => {
                          return (
                            <TextField
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              key={i}
                              label={`Core ${name} ${i + 1}`}
                              name={`values[${i}]`}
                              fullWidth
                              multiline
                              margin="normal"
                              variant="outlined"
                              value={form.values[i] || ""}
                              onChange={handleChange}
                              className={styles.placeholder}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <MenuIcon className={styles.draggable} />
                                    <IconButton onClick={handleDeleteEntry(form.values[i])}>
                                      <CloseIcon />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          );
                        }}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
          <IconButton onClick={() => setCount(count + 1)}>
            <AddIcon />
          </IconButton>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" onClick={handleSubmitCore} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={Boolean(open.org)}
        fullWidth
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <DialogTitle>Edit Organization</DialogTitle>
        <DialogContent>
          <TextField
            label="Organization Name"
            name="name"
            fullWidth
            margin="normal"
            variant="outlined"
            value={form.name || ""}
            onChange={handleChange}
            helperText={formErrors.name}
            error={Boolean(formErrors.name)}
          />
          <DatePicker
            fullWidth
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            label="Fiscal Year End"
            margin="normal"
            value={form.fiscalYear || ""}
            onChange={handleChangeDate("fiscalYear")}
            minDate={minYear}
          />
          <DragDropContext onDragEnd={handleDragEndOrg}>
            <Droppable droppableId={"0"}>
              {(provided, snapshot) => {
                return (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    <List>
                      {form.viewPreferences &&
                        _.sortBy(form.viewPreferences, "position").map(({ name, position, show }) => {
                          return (
                            <Draggable draggableId={name} index={position} key={`${position}${listKey.toString()}`}>
                              {(provided, snapshot) => {
                                const isCore = name !== "projects" ? "Core " : "";
                                return (
                                  <ListItem divider {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                                    <ListItemText>
                                      <div className={styles.flexBetween}>
                                        {isCore}
                                        {_.startCase(name)}
                                        <IconButton onClick={handleChangeHidden(name, show)}>
                                          {show ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                      </div>
                                    </ListItemText>
                                  </ListItem>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                    </List>
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" onClick={handleSubmitOrg} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={Boolean(open.bhag)}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
        fullWidth
      >
        <DialogTitle>Create New 5 Year Mission</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="5 Year Mission"
            name="goal"
            margin="normal"
            fullWidth
            multiline
            variant="outlined"
            value={form.goal || ""}
            onChange={handleChange}
            helperText={formErrors.goal}
            error={Boolean(formErrors.goal)}
          />
          <DatePicker
            fullWidth
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="dd/MM/yyyy"
            margin="normal"
            label="Target Date"
            value={form.targetDate || ""}
            onChange={handleChangeDate("targetDate")}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmitBhag} color="primary" variant="contained" className={styles.button}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditDialog;

const UPDATE_ORG = gql`
  mutation ($id: ID!, $name: String!, $fiscalYear: String!, $viewPreferences: [ViewPreferencesInput!]) {
    updateOrganization(id: $id, name: $name, fiscalYear: $fiscalYear, viewPreferences: $viewPreferences)
  }
`;

const UPDATE_CORE = gql`
  mutation ($id: ID!, $values: [String], $name: String, $plan: ID) {
    updateCore(id: $id, values: $values, name: $name, plan: $plan)
  }
`;

const UPDATE_BHAG = gql`
  mutation ($id: ID!, $goal: String!, $targetDate: String!) {
    updateBhag(organization: $id, goal: $goal, targetDate: $targetDate)
  }
`;
