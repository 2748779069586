import { Table, TableBody } from "@material-ui/core";
import React, { useState, useRef, useContext } from "react";
import { FixedSizeList } from "react-window";

const VirtualTableContext = React.createContext({ top: 0, setTop: () => {}, header: <></> });

const VirtualTable = ({ row, header, ...otherProps }) => {
  const listRef = useRef();
  const [top, setTop] = useState(0);

  return (
    <VirtualTableContext.Provider value={{ top, setTop, header }}>
      <FixedSizeList
        {...otherProps}
        innerElementType={Inner}
        onItemsRendered={(props) => {
          const style =
            listRef.current &&
            // @ts-ignore private method access
            listRef.current._getItemStyle(props.overscanStartIndex);
          setTop((style && style.top) || 0);

          // Call the original callback
          otherProps.onItemsRendered && otherProps.onItemsRendered(props);
        }}
        ref={(el) => (listRef.current = el)}
      >
        {row}
      </FixedSizeList>
    </VirtualTableContext.Provider>
  );
};

const Inner = React.forwardRef(function Inner({ children, ...rest }, ref) {
  const { header, top } = useContext(VirtualTableContext);
  return (
    <div {...rest} ref={ref}>
      <Table style={{ top, position: "absolute", width: "100%" }}>
        {header}
        <TableBody>{children}</TableBody>
      </Table>
    </div>
  );
});

export default VirtualTable;
