import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import styles from "../WeeklyReview.module.scss";
import { FetchContext } from "../../../context/fetchContext";
import { SnackbarContext } from "../../../context/snackbarContext";
import { NotesContext } from "../../../context/notesContext";
import Todos from "../../../components/Todos/Todos";
import { Typography, Card, CardContent, CardActions, MenuItem, Button, IconButton } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiClose, mdiAlertOctagram } from "@mdi/js";
import UserAvatar from "../../../components/UserAvatar/UserAvatar";
import Status from "../../../components/Status/Status";
import Menu from "../../../components/Menu/Menu";
import { formatAs, diffInDays } from "../../../utils/dates";
import { getTodoTieIn } from "../../../utils/graphql";

const IssueSolving = ({ todos, usersAttending, handleAddDialog }) => {
  const { notes } = useContext(NotesContext);
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [tieIns, setTieIns] = useState({});
  const [updateTodo] = useMutation(UPDATE_TODO);

  const handleCheckbox = (id) => () => {
    const index = selectedIssues.indexOf(id);
    const issues = _.cloneDeep(selectedIssues);
    if (index < 0) {
      if (issues.length < 3) {
        issues.push(id);
      }
    } else {
      issues.splice(index, 1);

      const oldTieIns = _.cloneDeep(tieIns);
      delete oldTieIns[id];
      setTieIns(oldTieIns);
    }
    setSelectedIssues(issues);
  };

  const handleUpdatePriority = (id, value, priority) => async () => {
    const ok = await updateTodo({ variables: { id, priority } });

    if (ok.data.updateTodo) {
      snack(`Marked "${value}" as ${priority} priority`);
      handleCheckbox(id)();
      requestFetch();
    }
  };

  const handleUpdate = (id, value, done) => async () => {
    const ok = await updateTodo({ variables: { id, done: !done } });

    if (ok.data.updateTodo) {
      snack(`Marked "${value}" as ${!done ? "resolved" : "incomplete"}`);
      requestFetch();
    }
  };

  const handleNotes = (id) => () => {
    notes(id, "issue");
  };

  useEffect(() => {
    selectedIssues.forEach(async (id) => {
      const { referenceId, referenceModel } = _.find(todos, (t) => t.id === id);
      if (referenceModel) {
        const model = referenceModel === "Issue" ? "Todo" : referenceModel;
        const tieIn = await getTodoTieIn(referenceId, model);
        const value = _.get(tieIn, `data.${_.camelCase(model)}.value`);

        setTieIns({ ...tieIns, [id]: value });
      }
    });
  }, [selectedIssues]);

  return (
    <div>
      <div className={styles.issueCardContainer}>
        <div className={styles.flexCenterWrap}>
          {[...selectedIssues, ...Array(3 - selectedIssues.length).keys()].map((id, i) => {
            if (_.isNumber(id))
              return (
                <Card variant="outlined" className={styles.issueCardPlaceholder}>
                  <CardContent className={styles.issueCardContent}>
                    <Icon path={mdiAlertOctagram} size={6} color="rgba(0, 0, 0, 0.54)" />

                    <Typography variant="h4" paragraph align="center" color="textSecondary">
                      Please select issue #{i + 1} to highlight
                    </Typography>
                  </CardContent>
                </Card>
              );

            const { value, createdAt, priority, referenceModel, user, done } = _.find(todos, (t) => t.id === id);

            const daysAgo = Math.abs(diffInDays(createdAt, new Date()));

            return (
              <Card className={styles.issueCard} key={id}>
                <CardContent>
                  <div className={styles.flexSpaceBetween}>
                    <Typography className={styles.label} paragraph>
                      {formatAs(createdAt, "MMM d")} ({daysAgo} {daysAgo === 1 ? "day" : "days"} ago)
                    </Typography>
                    <IconButton onClick={handleCheckbox(id)}>
                      <Icon path={mdiClose} size={1} color="rgba(0, 0, 0, 0.54)" />
                    </IconButton>
                  </div>

                  <Typography variant="h4" paragraph>
                    {value}
                  </Typography>

                  <Typography variant="h6" className={styles.label}>
                    Tie In
                  </Typography>
                  <Typography variant="h5" paragraph>
                    {referenceModel ? (
                      <span>
                        {_.startCase(referenceModel)}: {tieIns[id]}
                      </span>
                    ) : (
                      <span>No tie in</span>
                    )}
                  </Typography>

                  <Typography variant="h6" className={styles.label}>
                    Accountable
                  </Typography>
                  <div className={styles.parapgraph}>
                    <UserAvatar user={user} variant="h5" />
                  </div>

                  <div className={styles.flexSpaceBetween}>
                    <div>
                      <Typography variant="h6" className={styles.label} component="div">
                        Status
                      </Typography>
                      <Typography variant="h5" paragraph>
                        <Status done={done} />
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="h6" className={styles.label}>
                        Priority
                      </Typography>
                      <Typography variant="h5" paragraph component="div">
                        <div className={styles.flexCenter}>
                          <Status priority={priority} />
                          <Menu icon="arrow">
                            <MenuItem onClick={handleUpdatePriority(id, value, "high")} disabled={priority === "high"}>
                              Mark as on high priority
                            </MenuItem>
                            <MenuItem onClick={handleUpdatePriority(id, value, "medium")} disabled={priority === "medium"}>
                              Mark as on medium priority
                            </MenuItem>
                            <MenuItem onClick={handleUpdatePriority(id, value, "low")} disabled={priority === "low"}>
                              Mark as on low priority
                            </MenuItem>
                          </Menu>
                        </div>
                      </Typography>
                    </div>
                  </div>
                </CardContent>
                <CardActions className={styles.flexCenter}>
                  <Button variant="outlined" color="primary" onClick={handleNotes(id)}>
                    Notes
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleUpdate(id, value, done)}>
                    {done ? "unresolve" : "resolve"}
                  </Button>
                  <Button variant="outlined" color="primary" onClick={handleAddDialog("todo", "Issue", id)}>
                    New Todo
                  </Button>
                </CardActions>
              </Card>
            );
          })}
        </div>
      </div>
      <Todos todos={todos} filterUsers={usersAttending} issues handleCheckbox={handleCheckbox} checked={selectedIssues} />
    </div>
  );
};

export default IssueSolving;

const UPDATE_TODO = gql`
  mutation($id: ID!, $done: Boolean, $priority: String) {
    updateTodo(id: $id, done: $done, priority: $priority)
  }
`;
