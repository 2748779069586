import React, { createContext, useState } from "react";

export const FetchContext = createContext();

// Automatically switch refetch back to false so it is ready to be used again
export const FetchContextProvider = ({ children }) => {
  const [fetch, setFetch] = useState(false);

  const requestFetch = () => {
    setFetch((prev) => !prev);
  };

  return <FetchContext.Provider value={{ fetch, requestFetch }}>{children}</FetchContext.Provider>;
};
