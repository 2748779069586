import React, { useState, useEffect, useContext } from "react";
import styles from "./StatusIcons.module.scss";
import _ from "lodash";
import Icon from "@mdi/react";
import { mdiCheckboxBlankCircle, mdiRhombus, mdiLinkVariantOff } from "@mdi/js";
import { green, red, blueGrey } from "@material-ui/core/colors";
import { List, ListItem, ListItemText, ListItemIcon, Tooltip } from "@material-ui/core";
import { FetchContext } from "../../context/fetchContext";
import PlanPill from "../PlanPill/PlanPill";
import { year } from "../../utils/dates";

const StatusIcons = ({ items, currentYear }) => {
  const { fetch } = useContext(FetchContext);
  const [tooltipValues, setTooltipValues] = useState();
  const [onTrack, setOnTrack] = useState(0);
  const [offTrack, setOffTrack] = useState(0);

  useEffect(() => {
    // Parse data to populate status numbers
    if (items) {
      let on = 0,
        off = 0;
      items.forEach(({ status }) => {
        switch (status) {
          case "on track":
            on++;
            break;
          case "off track":
            off++;
            break;
          default:
            break;
        }
      });
      setOnTrack(on);
      setOffTrack(off);

      setTooltipValues(
        <List dense>
          {items &&
            items.map(({ value, status, number, plan = {} }) => {
              const planYearBackup = _.get(plan, "year");
              const planYear = _.get(plan, "plan.year", planYearBackup);

              return (
                <ListItem key={value}>
                  <ListItemIcon className={styles.listIcon}>
                    {status === "on track" && <Icon path={mdiCheckboxBlankCircle} size={1} color={green[300]} />}
                    {status === "off track" && <Icon path={mdiRhombus} size={1} color={red[300]} />}
                    {!_.isEmpty(plan) && <PlanPill plan={plan} />}
                  </ListItemIcon>
                  <ListItemText>
                    {number}. {value}
                    {planYear && ` (${year(planYear)})`}
                  </ListItemText>
                </ListItem>
              );
            })}
        </List>
      );
    }
  }, [items, fetch]);

  const noTieIn = onTrack === 0 && offTrack === 0;

  return (
    <Tooltip title={!noTieIn ? tooltipValues : "No Tie In"} interactive>
      <div className={styles.container}>
        {onTrack > 0 && (
          <span className={styles.wrapper}>
            <Icon path={mdiCheckboxBlankCircle} size={1.25} color={green[300]} />
            <span className={styles.text}>{onTrack}</span>
          </span>
        )}
        {offTrack > 0 && (
          <span className={styles.wrapper}>
            <Icon path={mdiRhombus} size={1.25} color={red[300]} />
            <span className={styles.text}>{offTrack}</span>
          </span>
        )}
        {noTieIn && (
          <span className={styles.wrapper}>
            <Icon path={mdiLinkVariantOff} size={1.25} color={blueGrey[400]} />
          </span>
        )}
      </div>
    </Tooltip>
  );
};

export default StatusIcons;
