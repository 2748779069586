import React from "react";
import _ from "lodash";
import { FormControl, FormHelperText, InputLabel, Select, MenuItem } from "@material-ui/core";

const SelectMetric = ({ metrics = [], label, name, value, handleChange, helperText, error, showAll, multiple, disabled = false }) => {
  return (
    <FormControl fullWidth variant="outlined" margin="normal" error={error}>
      <InputLabel>{label}</InputLabel>
      <Select name={name} value={value || ""} multiple={multiple} onChange={handleChange} label={label} error={error} disabled={disabled}>
        {showAll && <MenuItem value={null}>No {label} link</MenuItem>}
        {!_.isEmpty(metrics) &&
          metrics.map(({ id, value }) => (
            <MenuItem key={id} value={id}>
              {value}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default SelectMetric;
