import React from "react";
import _ from "lodash";
import styles from "./Inactive.module.scss";

import { Typography, Button } from "@material-ui/core";

import logo from "../../assets/img/icon-white.png";
import splash from "../../assets/img/splash.svg";

const Inactive = ({ name }) => {
  return (
    <div className={styles.container}>
      <div className={styles.splash}>
        <div className={styles.splashOverlay}>
          <img src={logo} alt="Ventrek Logo" className={styles.img} />
          <Typography variant="h3" className={styles.splashTitle}>
            Welcome to Ventrek
          </Typography>
        </div>
        <img src={splash} alt="splash" className={styles.svg} />
      </div>
      <div className={styles.content}>
        <Typography variant="h2" gutterBottom>
          Prohibited
        </Typography>

        <Typography variant="body1" color="error" paragraph>
          {name} has been marked as inactive.
        </Typography>

        <Button margin="normal" fullWidth variant="contained" color="primary" href="/signout">
          Sign out
        </Button>
      </div>
    </div>
  );
};

export default Inactive;
