import React from "react";
import styles from "./Loading.module.scss";

const Loading = ({ size, color, fullscreen }) => {
  return (
    <div className={fullscreen ? styles.fullscreen : null}>
      <div className={styles.container} style={size ? { height: size, width: size } : {}}>
        <div className={styles.one} style={color ? { borderBottomColor: color } : {}} />
        <div className={styles.two} style={color ? { borderRightColor: color } : {}} />
        <div className={styles.three} style={color ? { borderTopColor: color } : {}} />
      </div>
    </div>
  );
};

export default Loading;
