import { iterateObserversSafely } from '@apollo/client/utilities';
import { useEffect, useState } from 'react';
const _ = require("lodash");

function useVersionCheck() {
  const [currentVersion, setCurrentVersion] = useState(null);
  const [intervalInMilliSeconds, setIntervalInMilliSeconds] = useState(20000);

  useEffect(() => {

    // Function to fetch the version file
    const fetchVersion = async () => {
      try {
        const response = await fetch('/version.json', {
          cache: 'no-store', // Ensure it doesn't cache the version file
        });
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Failed to fetch version:', error);
        return null;
      }
    };

    const intervalId = setInterval(async () => {
        const {version: latestVersion, interval} = await fetchVersion();
        const newInterval = parseInt(interval);
        if (_.isNaN(newInterval) || !_.isNumber(newInterval) || newInterval === 0) {
          setIntervalInMilliSeconds(86400000); // Default to 1 days
        }        
        else if (!_.isNaN(newInterval) && _.isNumber(newInterval) && newInterval !== intervalInMilliSeconds) {
          setIntervalInMilliSeconds(newInterval);
        }

        if (latestVersion && currentVersion && latestVersion !== currentVersion) {
          // If the version has changed, reload the app
          window.location.reload(true);
        } else {
          setCurrentVersion(latestVersion);
        }
      }, intervalInMilliSeconds);

    // Cleanup the interval on unmount
    return () => clearInterval(intervalId);
  }, [currentVersion, intervalInMilliSeconds]);

  return currentVersion;
}


export default useVersionCheck;