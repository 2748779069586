import React, { useState } from "react";
import styles from "./Bugs.module.scss";
import _ from "lodash";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { TableRow, TableCell, MenuItem, Typography } from "@material-ui/core";
import UserAvatars from "../../components/UserAvatars/UserAvatars";
import Menu from "../../components/Menu/Menu";
// import EditDialog from "./EditDialog";
import { diffInDays, formatAs } from "../../utils/dates";
import ActionDialog from "./ActionDialog";

const Report = ({ report, snack, requestFetch, canEdit, showResolved }) => {
  const { id, title, description, author, createdAt, resolved } = report;
  const [open, setOpen] = useState({ view: false, delete: false });

  const [updateReport] = useMutation(UPDATE_RERPORT);

  const handleToggleResolveReport = async () => {
    const ok = await updateReport({ variables: { id, resolved: !resolved } });

    if (ok.data.updateReport) {
      snack(`Marked "${title}" as ${!resolved ? "Resolved" : "Unresolved"}`);
      requestFetch();
    }
  };

  const handleDialog = (name, open) => () => {
    setOpen({ ...open, [name]: open });
  };

  const handleCloseDialog = () => {
    setOpen({ view: false, delete: false });
  };

  const daysAgo = Math.abs(diffInDays(createdAt, new Date()));

  if (resolved && !showResolved) return null;
  return (
    <>
      <TableRow>
        <TableCell className={styles.firstCell}>
          <UserAvatars users={[{ id: author.id, name: author.name, profilePicture: author.profilePicture }]} />
        </TableCell>
        <TableCell>
          <div className={styles.flex}>{title}</div>
        </TableCell>
        <TableCell>
          {author.name.first} {author.name.last}
        </TableCell>
        <TableCell>{_.get(author, "organization.name")}</TableCell>
        <TableCell>{_.startCase(_.get(author, "auth", ""))}</TableCell>
        <TableCell>
          <Typography noWrap variant="inherit">
            {formatAs(createdAt, "MMM d")} <br />({daysAgo} {daysAgo === 1 ? "day" : "days"} ago)
          </Typography>
        </TableCell>
        <TableCell>{resolved ? "Resolved" : "Unresolved"}</TableCell>
        <TableCell>
          <Menu>
            <MenuItem onClick={handleDialog("view", true)}>View Details</MenuItem>
            <MenuItem onClick={handleToggleResolveReport}>Mark as {resolved ? "Unresolved" : "Resolved"}</MenuItem>
            <MenuItem onClick={handleDialog("delete", true)} className={styles.delete}>
              Delete
            </MenuItem>
          </Menu>
        </TableCell>
      </TableRow>

      <ActionDialog open={open} handleClose={handleCloseDialog} snack={snack} requestFetch={requestFetch} report={report} />
    </>
  );
};

export default Report;

const UPDATE_RERPORT = gql`
  mutation ($id: ID!, $type: String, $title: String, $description: String, $resolved: Boolean) {
    updateReport(id: $id, type: $type, title: $title, description: $description, resolved: $resolved)
  }
`;
