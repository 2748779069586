import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styles from "./WeeklyReview.module.scss";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Container, Grid, Typography, Button, Hidden, Divider, List, ListItem, ListItemText } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiPlus } from "@mdi/js";
import { isVentrekAdmin, isAuthed } from "../../utils/authorization";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import Loading from "../../components/Loading/Loading";
import UserAvatar from "../../components/UserAvatar/UserAvatar";
import UserAvatars from "../../components/UserAvatars/UserAvatars";
import { formatAs } from "../../utils/dates";
import AddMeetingDialog from "./AddMeetingDialog";
import EditDialog from "./EditDialog";
import HistoryDialog from "./HistoryDialog";

const WeeklyReview = ({ user, params }) => {
  const { loading, data, refetch } = useQuery(GET_MEETINGS, {
    variables: {
      organization: params.org,
    },
  });

  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [dialog, setDialog] = useState(false);
  const [editDialog, setEditDialog] = useState({ id: null, title: null, users: [], startTime: null, steps: [], short: false });
  const [historyDialog, setHistoryDialog] = useState({ open: false, data: {} });

  const handleDialog = (open, newMeeting = null) => () => {
    setDialog(open);
    if (newMeeting) {
      const { id, title, users, startTime, steps, frequency, plan } = newMeeting;
      setEditDialog({
        id,
        title,
        users: users.map(({ id }) => id),
        startTime: new Date(parseInt(startTime)),
        steps,
        frequency,
        plan,
        short: true,
      });
    }
  };

  const handleEditDialog = ({ id, title = null, users = [], startTime = new Date(), steps = [], frequency = 1, plan = null }) => () => {
    if (id) {
      setEditDialog({
        id,
        title,
        users: users.map(({ id }) => id),
        startTime: new Date(parseInt(startTime)),
        steps,
        frequency,
        plan,
        short: false,
      });
    } else {
      setEditDialog({ id, title, users, startTime: null, steps, frequency, plan, short: false });
    }
  };

  const handleHistoryDialog = (open, data = {}) => () => {
    setHistoryDialog({ open, data });
  };

  useEffect(() => {
    refetch();
  }, [fetch]);

  return <Typography style={{ textAlign: "center" }}>Coming Soon</Typography>;

  if (loading) return <Loading />;

  const filteredMeetings = data.meetings.filter((m) => m.status === "finished");
  const previousMeetings = _.groupBy(filteredMeetings, (m) => m.title);

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h6" className={styles.label}>
                Weekly Review
              </Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              {data.meetings &&
                data.meetings.map(({ id, title, plan, owner, status, users, startTime, steps, frequency }) => {
                  if (!isVentrekAdmin(user.user)) {
                    const user_id = user.user.id;
                    const attendees = users.map(({ id }) => id);
                    if (user_id !== owner.id && !attendees.includes(user_id)) return null;
                  }
                  if (status === "finished") return null;

                  const inProgress = status === "in progress";
                  const canEdit = isAuthed(user.user, "company admin") || user.user.id === owner.id;

                  return (
                    <React.Fragment key={id}>
                      <Grid item xs={12} sm={3}>
                        <Typography variant="h4" className={styles.purple}>
                          {title}
                        </Typography>
                        {plan && (
                          <Typography variant="h6" className={styles.label}>
                            {plan.departmentName}
                          </Typography>
                        )}
                      </Grid>

                      <Grid item xs={12} sm={9}>
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={5}>
                            <Typography variant="h6" className={styles.label}>
                              Date
                            </Typography>

                            <Typography variant="h4" paragraph>
                              {formatAs(startTime, "iiii")}s at {formatAs(startTime, "h:mma")}
                            </Typography>

                            <Typography variant="h6" className={styles.label}>
                              Frequency
                            </Typography>

                            <Typography variant="h4" paragraph>
                              {FREQUENCY[frequency]}
                            </Typography>

                            <Typography variant="h6" className={styles.label}>
                              Attending
                            </Typography>
                            <div className={styles.flexParagraph}>
                              {!_.isEmpty(users) ? <UserAvatars users={users} /> : <Typography variant="h4">No Attendees</Typography>}
                            </div>

                            <Typography variant="h6" className={styles.label}>
                              Status
                            </Typography>
                            <Typography variant="h4" className={inProgress ? styles.statusPurple : styles.status} paragraph>
                              {status}
                            </Typography>

                            <Typography variant="h6" className={styles.label}>
                              Organizer
                            </Typography>
                            <UserAvatar user={owner} variant="h5" />
                          </Grid>
                          <Grid item xs={12} sm={5}>
                            <Typography variant="h6" className={styles.label}>
                              Previous Meetings
                            </Typography>
                            <List disablePadding className={styles.previousMeetings}>
                              {previousMeetings[title] &&
                                previousMeetings[title].reverse().map((meeting) => {
                                  const { id, rating, endTime } = meeting;
                                  const overallRating = Math.round(rating.reduce((sum, val) => sum + val, 0 / rating.length));

                                  return (
                                    <ListItem key={id} disableGutters button onClick={handleHistoryDialog(true, meeting)}>
                                      <ListItemText
                                        primary={formatAs(endTime, "MMM d, yyyy")}
                                        secondary={_.isFinite(overallRating) ? `Rated ${overallRating} out of 10` : "No rating"}
                                      />
                                    </ListItem>
                                  );
                                })}
                            </List>
                          </Grid>
                          <Grid item xs={12} sm={2}>
                            <div className={styles.buttons}>
                              {canEdit && (
                                <Button
                                  onClick={handleEditDialog({
                                    id,
                                    title,
                                    users,
                                    startTime,
                                    steps,
                                    frequency,
                                    plan: _.get(plan, "id", null),
                                  })}
                                  variant="outlined"
                                >
                                  Edit
                                </Button>
                              )}

                              {(canEdit || inProgress) && (
                                <Link to={`weekly/${id}`} disabled={!canEdit && !inProgress}>
                                  <Button variant="contained" color="primary" className={styles.button}>
                                    {inProgress ? "Join" : "Start"}
                                  </Button>
                                </Link>
                              )}
                            </div>
                          </Grid>
                        </Grid>

                        <Divider className={styles.divider} />
                      </Grid>
                    </React.Fragment>
                  );
                })}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div className={styles.newMeeting}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
                onClick={handleDialog(true)}
              >
                New Meeting
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>

      <AddMeetingDialog open={dialog} handleClose={handleDialog} params={params} requestFetch={requestFetch} user={user} snack={snack} />

      <EditDialog
        open={Boolean(editDialog.id)}
        handleClose={handleEditDialog({ id: null })}
        initForm={editDialog}
        params={params}
        requestFetch={requestFetch}
        snack={snack}
      />

      <HistoryDialog open={historyDialog.open} handleClose={handleHistoryDialog(false)} meeting={historyDialog.data} />
    </>
  );
};

export default WeeklyReview;

const GET_MEETINGS = gql`
  query($organization: ID!) {
    meetings(organization: $organization) {
      id
      title
      plan {
        id
        departmentName
      }
      status
      startTime
      frequency
      owner {
        id
        name {
          first
          last
        }
        profilePicture
      }
      users {
        id
        name {
          first
          last
        }
        profilePicture
      }
      steps {
        name
        alias
        duration
      }
      endTime
      rating
      weeklyTargets {
        id
        value
      }
      rocks {
        id
        value
      }
      todos {
        id
        value
      }
      issues {
        id
        value
      }
    }
  }
`;

const FREQUENCY = {
  1: "Every Week",
  2: "Every 2 Weeks",
  4: "Every Month",
  12: "Every 3 Months",
};
