import React, { useState, useEffect } from "react";
import styles from "./Status.module.scss";
import _ from "lodash";
import { red, green, orange, blueGrey, grey } from "@material-ui/core/colors";
import Icon from "@mdi/react";
import { mdiCheckCircle, mdiAlertRhombus, mdiAlertDecagram, mdiStarCircle } from "@mdi/js";

const Status = ({ status, done, priority }) => {
  const [color, setColor] = useState();
  const bg = {
    complete: blueGrey[300],
    low: green[300],
    "on track": green[300],
    medium: orange[300],
    "off track": red[300],
    high: red[300],
    untracked: grey[500],
  };

  const parseDone = (done) => {
    // Only return something if a status is not provided
    if (!_.isNil(status) || !_.isNil(priority)) return null;

    if (done) {
      return "Complete";
    } else {
      return "Incomplete";
    }
  };

  const getIcon = () => {
    let path, color;
    if (status) {
      switch (status) {
        case "complete":
          path = mdiStarCircle;
          color = bg["complete"];
          break;
        case "on track":
          path = mdiCheckCircle;
          color = bg["on track"];
          break;

        case "off track":
          path = mdiAlertRhombus;
          color = bg["off track"];
          break;

        default:
          break;
      }
    } else if (!_.isNil(done) && done) {
      path = mdiCheckCircle;
      color = bg["on track"];
    } else if (!_.isNil(done)) {
      path = mdiAlertDecagram;
      color = bg["off track"];
    }

    if (path && color) {
      return <Icon path={path} size={1} color={color} className={styles.icon} />;
    }
    return null;
  };

  useEffect(() => {
    if (status) {
      setColor(bg[status]);
    } else if (priority) {
      setColor(bg[priority]);
    } else if (done) {
      setColor(bg["on track"]);
    } else {
      setColor(bg["off track"]);
    }
  }, [status, done, priority]);

  return (
    <div className={styles.status} style={{ color }}>
      {getIcon()}
      {status}
      {priority}
      {parseDone(done)}
    </div>
  );
};

export default Status;
