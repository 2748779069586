import { ClickAwayListener, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";

const EditableText = ({ value, defaultValue, name, placeholder, handleChange, handleSubmit, textClassName, inputClassName, disabled }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      if (isChanged) {
        handleSubmit();
        setIsChanged(false);
      }
      setIsEdit(false);
    }
  };

  const handleClickAway = () => {
    setIsEdit(false);
    if (isChanged) {
      handleSubmit();
      setIsChanged(false);
    }
  };

  const handleObservedChange = (e) => {
    handleChange(e);
    setIsChanged(true);
  };

  if (!isEdit) {
    return (
      <Typography className={textClassName} onClick={() => (!disabled ? setIsEdit(true) : {})}>
        {value || defaultValue}
      </Typography>
    );
  } else {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <TextField
          fullWidth
          name={name}
          margin="none"
          variant="standard"
          value={value || ""}
          onChange={handleObservedChange}
          placeholder={placeholder}
          className={inputClassName}
          onKeyDown={onKeyDown}
        />
      </ClickAwayListener>
    );
  }
};

export default EditableText;
