import React, { useState } from "react";
import _ from "lodash";
import { format } from "date-fns";

import { Paper, List, ListItem, ListItemText, Typography } from "@material-ui/core";

import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

function SavedEmails() {
  const [emailList, setEmailList] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(null);
  const { data, loading } = useQuery(GET_SAVED_EMAIL_LIST, {
    onCompleted: (data) => {
      setEmailList(data.savedEmailList);
    },
    fetchPolicy: "network-only",
  });

  return (
    <div style={{ flex: 1, minHeight: 0, padding: "32px", display: "flex" }}>
      <Paper style={{ display: "flex", flex: 1, minHeight: 0 }}>
        <span style={{ marginRight: "16px", marginLeft: "16px", overflow: "auto" }}>
          {!_.isEmpty(emailList) ? (
            <List style={{ minWidth: "200px", maxHeight: "100%" }}>
              {emailList.map((email, idx) => (
                <ListItem
                  key={_.get(email, "filename", "")}
                  button
                  onClick={() => {
                    setSelectedEmail(email);
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <ListItemText
                      primary={getEmailFromFileName(_.get(email, "filename", ""))}
                      secondary={
                        <>
                          <Typography color="textSecondary">{getSubjectFromFileName(_.get(email, "filename", ""))}</Typography>
                          <Typography color="textSecondary">
                            {!_.isNil(_.get(email, "stat.mtime")) &&
                              format(new Date(parseInt(_.get(email, "stat.mtime"))), "h:mm a MMM dd, yyyy")}
                          </Typography>
                        </>
                      }
                    />
                  </div>
                </ListItem>
              ))}
            </List>
          ) : (
            <div>No saved Email</div>
          )}
        </span>
        <span style={{ flexGrow: 1, minWidth: 300 }}>
          <iframe
            title="Email"
            width="100%"
            height="100%"
            src={!_.isNil(selectedEmail) && `${process.env.REACT_APP_SERVER_URI}/emails/${selectedEmail.filename}`}
          />
        </span>
      </Paper>
    </div>
  );
}

export default SavedEmails;

const GET_SAVED_EMAIL_LIST = gql`
  query SavedEmails_GetSavedEmailList {
    savedEmailList {
      filename
      stat {
        mtime
      }
    }
  }
`;

function getEmailFromFileName(fileName) {
  const startIndex = fileName.indexOf("_", 14) + 1;
  const endIndex = fileName.indexOf(".html");

  if (startIndex !== -1 && endIndex !== -1) {
    return fileName.substring(startIndex, endIndex);
  } else {
    return fileName;
  }
}

function getSubjectFromFileName(fileName) {
  fileName = fileName.replace(/-/g, " ");
  const startIndex = fileName.indexOf("_") + 1;
  const endIndex = fileName.indexOf("_", 14);

  if (startIndex !== -1 && endIndex !== -1) {
    return fileName.substring(startIndex, endIndex);
  } else {
    return fileName;
  }
}
