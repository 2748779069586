import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, useParams } from "react-router-dom";
import styles from "./Layout.module.scss";
import { useQuery } from "@apollo/client";
import { useMediaQuery } from "@material-ui/core";
import gql from "graphql-tag";
import { UserContext } from "../context/userContext";
import { DialogContext } from "../context/dialogContext";
import { FetchContext } from "../context/fetchContext";
import { SnackbarContext } from "../context/snackbarContext";
import Sidebar from "../components/Sidebar/Sidebar";
import Navbar from "../components/Navbar/Navbar";
import Loading from "../components/Loading/Loading";
import { Inactive, Error } from "../views";
import { routes } from "../routes";
import { isVentrekAdmin } from "../utils/authorization";
import _ from "lodash";

import AddPlanDialog from "../components/AddPlanDialog/AddPlanDialog";
import AddObjectiveDialog from "../components/AddObjectiveDialog/AddObjectiveDialog";
import AddMetricDialog from "../components/AddMetricDialog/AddMetricDialog";
import AddRockDialog from "../components/AddRockDialog/AddRockDialog";
import AddSuccessCriteriaDialog from "../components/AddSuccessCriteriaDialog/AddSuccessCriteriaDialog";
import AddTodoDialog from "../components/AddTodoDialog/AddTodoDialog";
import AddWeeklyTargetDialog from "../components/AddWeeklyTargetDialog/AddWeeklyTargetDialog";
import Notes from "../components/Notes/Notes";

const Layout = () => {
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const params = useParams();
  const { user } = useContext(UserContext);
  const { dialog, setDialog } = useContext(DialogContext);
  const { requestFetch, fetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { loading, data } = useQuery(GET_ORGANIZATION, { variables: { id: params.org } });
  const [drawer, setDrawer] = useState(isDesktop);
  const { loading: orgsLoading, data: orgsData } = useQuery(GET_ORGANIZATIONS, {
    variables: { ids: _.get(user, "user.organizations", []) },
  });

  const handleDrawer = () => {
    setDrawer(!drawer);
  };

  useEffect(() => {
    setDrawer(isDesktop);
  }, [isDesktop]);

  if (loading || orgsLoading) return <Loading fullscreen size={128} />;
  if (!data || !data.organization) return <Error title="Unauthorized" body="You do not have permission to proceed" />;

  if (!data.organization.active && !isVentrekAdmin(user.user)) return <Inactive name={data.organization.name} />;

  return (
    <>
      <div>
        <Navbar
          dialog={dialog}
          setDialog={setDialog}
          org={data.organization}
          params={params}
          handleOpen={handleDrawer}
          snack={snack}
          open={drawer}
          orgs={orgsData.organizations}
        />
        <Sidebar params={params} org={data.organization} open={drawer} handleClose={handleDrawer} isDesktop={isDesktop} />
        <div className={isDesktop ? (drawer ? styles.content : styles.contentFullscreen) : styles.contentFullscreen}>
          <Switch>
            {routes.map((route) => {
              const { path, name, props } = route;
              return (
                <Route
                  path={`/:org/${path}`}
                  render={() => <route.component user={user} org={data.organization} params={params} {...props} />}
                  key={name}
                />
              );
            })}
          </Switch>
        </div>
      </div>

      <AddPlanDialog dialog={dialog} setDialog={setDialog} user={user} requestFetch={requestFetch} params={params} snack={snack} />
      <AddObjectiveDialog dialog={dialog} setDialog={setDialog} requestFetch={requestFetch} fetch={fetch} params={params} snack={snack} />
      <AddMetricDialog
        dialog={dialog}
        setDialog={setDialog}
        user={user}
        requestFetch={requestFetch}
        fetch={fetch}
        params={params}
        snack={snack}
      />
      <AddRockDialog
        dialog={dialog}
        setDialog={setDialog}
        user={user}
        requestFetch={requestFetch}
        fetch={fetch}
        params={params}
        snack={snack}
      />
      <AddSuccessCriteriaDialog
        dialog={dialog}
        setDialog={setDialog}
        user={user}
        requestFetch={requestFetch}
        params={params}
        snack={snack}
      />
      <AddTodoDialog
        dialog={dialog}
        setDialog={setDialog}
        user={user}
        fetch={fetch}
        requestFetch={requestFetch}
        params={params}
        snack={snack}
      />
      <AddWeeklyTargetDialog dialog={dialog} setDialog={setDialog} user={user} requestFetch={requestFetch} params={params} snack={snack} />

      <Notes user={user} snack={snack} dialog={dialog} setDialog={setDialog} fetch={fetch} />
    </>
  );
};

export default Layout;

const GET_ORGANIZATION = gql`
  query Layout_GetOrg($id: ID!) {
    organization(id: $id) {
      id
      name
      active
      fiscalYear
      plansOrder
      viewPreferences {
        name
        position
        show
      }
    }
  }
`;

const GET_ORGANIZATIONS = gql`
  query ($ids: [ID!]) {
    organizations(ids: $ids) {
      id
      name
      fiscalYear
      active
    }
  }
`;
