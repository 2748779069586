import React from "react";
import styles from "./UserAvatar.module.scss";
import _ from "lodash";
import { Avatar, Typography } from "@material-ui/core";

const UserAvatar = ({ user, variant }) => {
  if (_.isNil(user))
    return (
      <span className={styles.center}>
        <Avatar className={styles.avatarNone}>?</Avatar>
        No user assigned
      </span>
    );

  const { id, profilePicture, name } = user;
  return (
    <span className={styles.center}>
      <Avatar key={id} src={profilePicture} alt={`${name.first} ${name.last}`} className={styles.avatar}>
        {name.first[0]}
        {name.last[0]}
      </Avatar>
      <Typography variant={variant || "body1"}>
        {name.first} {name.last}
      </Typography>
    </span>
  );
};

export default UserAvatar;
