import { useState, useEffect, useRef } from "react";
import _ from "lodash";

const useTableFilters = ({ location, initialValue = {} }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialValue.rowsPerPage || 10);
  const [sort, setSort] = useState({ value: initialValue.sortValue, order: initialValue.sortOrder });
  const [searchTerm, setSearchTerm] = useState(localStorage.getItem(`${location}.searchTerm`) || "");
  const [debouncedSearchTerm, setDebounceSearchTerm] = useState(localStorage.getItem(`${location}.searchTerm`) || "");
  const [showCompleted, setShowCompleted] = useState(initialValue.showCompleted);

  const debouncedSetSearchTermRef = useRef(
    _.debounce((newValue) => {
      setDebounceSearchTerm(newValue);
      if (newValue && newValue !== localStorage.getItem(`${location}.searchTerm`)) {
        localStorage.setItem(`${location}.searchTerm`, newValue);
      } else if (!newValue) {
        localStorage.removeItem(`${location}.searchTerm`);
      }
    }, 1000)
  );
  useEffect(() => {
    if (debouncedSetSearchTermRef.current) {
      debouncedSetSearchTermRef.current.cancel();
      debouncedSetSearchTermRef.current(searchTerm);
    }
  }, [searchTerm]);

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
    if (e.target.value) {
      localStorage.setItem(`${location}.rowsPerPage`, JSON.stringify(e.target.value));
    }
  };

  return {
    page,
    rowsPerPage,
    sort,
    searchTerm,
    debouncedSearchTerm,
    showCompleted,
    handleChangePage,
    handleRowsPerPage,
    setSort,
    setSearchTerm,
    setShowCompleted,
  };
};

export default useTableFilters;
