import React from "react";
import styles from "./WeeklyReview.module.scss";
import _ from "lodash";
import { formatDistanceStrict, differenceInSeconds, format } from "date-fns";
import {
  Container,
  Grid,
  Typography,
  Button,
  Hidden,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Card,
  CardContent,
  Divider,
} from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { mdiCheckboxMarked, mdiAlertDecagram, mdiNote, mdiCheckCircle } from "@mdi/js";
import Icon from "@mdi/react";
import useReview from "./useReview";
import {
  CustomerUpdate,
  IssueSolving,
  PeopleUpdate,
  ProjectUpdate,
  RockReview,
  Start,
  TodoList,
  WeeklyMetrics,
  Welcome,
  WrapUp,
} from "./Steps";
import UserAvatars from "../../components/UserAvatars/UserAvatars";

const Review = ({ user }) => {
  const {
    loading,
    userLoading,
    isAdmin,
    data,
    userData,
    currentTime,
    currentStep,
    completedSteps,
    currentStepAlias,
    currentRock,
    currentSessionSubscription,
    completedRocks,
    usersAttending,
    userRooms,
    quarterInfo,
    snack,
    requestFetch,
    formatAsTime,
    updateCurrentRock,
    updateRockStatus,
    handleChangeStep,
    handleStepButton,
    handleAddDialog,
    handleAddScDialog,
    handleStart,
    handleUsersAttending,
    handleUpdateSuccessCriteria,
    handleExit,
  } = useReview(user);

  if (loading || userLoading) return null;
  const { title, steps } = data.meeting;

  const stepsComponents = {
    "Customer Update": <CustomerUpdate />,
    "Issue Solving": (
      <IssueSolving todos={_.get(userData, "issues", [])} usersAttending={usersAttending} handleAddDialog={handleAddDialog} />
    ),
    "People Update": <PeopleUpdate />,
    "Project Update": <ProjectUpdate projects={_.get(userData, "projects", [])} />,
    "Rock Review": (
      <RockReview
        rocks={_.get(userData, "rocks", [])}
        currentRock={currentRock}
        updateCurrentRock={updateCurrentRock}
        updateRockStatus={updateRockStatus}
        filterUsers={usersAttending}
        fiscalYear={_.get(userData, "organization.fiscalYear")}
        handleAddDialog={handleAddDialog}
        handleAddScDialog={handleAddScDialog}
        handleUpdateSuccessCriteria={handleUpdateSuccessCriteria}
        snack={snack}
        requestFetch={requestFetch}
        completedRocks={completedRocks}
      />
    ),
    Start: (
      <Start
        users={data.meeting.users}
        usersAttending={usersAttending}
        handleUsersAttending={handleUsersAttending}
        handleStart={handleStart}
      />
    ),
    "To Do List": <TodoList todos={_.get(userData, "todos", [])} usersAttending={usersAttending} />,
    KPIs: (
      <WeeklyMetrics
        weeklyTargets={_.get(userData, "weeklyTargets", [])}
        usersAttending={usersAttending}
        fiscalYear={_.get(userData, "organization.fiscalYear")}
      />
    ),
    Welcome: <Welcome />,
    "Wrap Up": <WrapUp handleExit={handleExit} isAdmin={isAdmin} usersAttending={usersAttending} data={data} />,
  };

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Hidden xsDown>
          <Grid item xs={12}>
            <Typography variant="h6" className={styles.label}>
              {title}
            </Typography>
          </Grid>
        </Hidden>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={3}>
              {_.get(currentSessionSubscription, "meetingSub.currentStep") === currentStep && (
                <Typography variant="h4" className={styles.purple} align="center">
                  {formatAsTime(differenceInSeconds(new Date(currentSessionSubscription.meetingSub.currentStepEndTime), currentTime))}
                </Typography>
              )}
              {_.has(currentSessionSubscription, "meetingSub.endTime") && (
                <>
                  <Typography variant="h6" align="center">
                    {currentTime.getTime() > new Date(currentSessionSubscription.meetingSub.endTime).getTime()
                      ? "0 minutes"
                      : formatDistanceStrict(currentTime, new Date(currentSessionSubscription.meetingSub.endTime), { unit: "minute" })}{" "}
                    remaining
                  </Typography>
                  <Typography variant="h6" align="center">
                    {format(new Date(currentSessionSubscription.meetingSub.endTime), "MMM dd, yyyy")}
                  </Typography>
                </>
              )}

              <Card className={styles.listContainer}>
                <CardContent>
                  <List className={styles.list}>
                    {steps.map(({ name, alias, duration }, i) => {
                      const isCurrentStep = name === currentStep;
                      const isReviewed = completedSteps.includes(name);
                      const isLastStep = i === steps.length - 1;

                      return (
                        <ListItem
                          key={name}
                          className={isCurrentStep ? undefined : styles.label}
                          divider={!isLastStep}
                          button
                          onClick={handleChangeStep(name)}
                        >
                          <ListItemText>
                            <span className={styles.flex}>
                              {alias || name}
                              {isReviewed && <Icon path={mdiCheckCircle} color={green[400]} size={0.75} className={styles.check} />}
                            </span>
                          </ListItemText>
                          <UserAvatars users={userRooms[_.camelCase(name)]} hidden small />
                          <ListItemSecondaryAction className={isCurrentStep ? undefined : styles.label}>
                            {duration}m
                          </ListItemSecondaryAction>
                        </ListItem>
                      );
                    })}
                  </List>
                </CardContent>
              </Card>
              <Typography align="center" className={styles.divider}>
                <Button variant="outlined" color="primary" onClick={handleStepButton(-1)} disabled={currentStep === "Welcome"}>
                  Prev
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  className={styles.button}
                  onClick={handleStepButton(1)}
                  disabled={currentStep === "Wrap Up"}
                >
                  Next
                </Button>
              </Typography>
            </Grid>

            <Grid item xs={12} md={9}>
              <div className={styles.flexSpaceBetween}>
                <div>
                  <Typography variant="h3" className={styles.purple}>
                    {currentStepAlias}
                  </Typography>
                  {_.has(data, "meeting.users") && (
                    <>
                      <div className={styles.flex}>
                        <Typography variant="h6" className={styles.labelOrganizer}>
                          Organizer:
                        </Typography>
                        <UserAvatars users={[data.meeting.owner]} />
                        <Divider orientation="vertical" flexItem className={styles.dividerVertical} />
                        <Typography variant="h6" className={styles.labelOrganizer}>
                          Participants:
                        </Typography>
                        {!_.isEmpty(data.meeting.users) ? (
                          <UserAvatars users={data.meeting.users} />
                        ) : (
                          <Typography variant="p">No Attendees</Typography>
                        )}
                      </div>
                    </>
                  )}
                  {quarterInfo.quarter && (
                    <>
                      <div className={styles.flex}>
                        <Typography variant="h6" className={styles.labelOrganizer}>
                          Quarter:
                        </Typography>
                        <Typography variant="h6" className={styles.label}>
                          {quarterInfo.quarter} ({quarterInfo.daysRemaining} day{quarterInfo.daysRemaining === 1 ? "" : "s"} remaining)
                        </Typography>
                      </div>
                    </>
                  )}
                </div>

                <div>
                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.buttonBlue}
                    startIcon={<Icon path={mdiNote} size={1} color="#fff" />}
                  >
                    Notes
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.buttonRed}
                    startIcon={<Icon path={mdiAlertDecagram} size={1} color="#fff" />}
                    onClick={handleAddDialog("issue")}
                  >
                    New Issue
                  </Button>

                  <Button
                    variant="contained"
                    color="primary"
                    className={styles.buttonPurple}
                    startIcon={<Icon path={mdiCheckboxMarked} size={1} color="#fff" />}
                    onClick={handleAddDialog("todo")}
                  >
                    New Todo
                  </Button>
                </div>
              </div>
              {currentStep && (
                <Container className={styles.container} maxWidth={false}>
                  {stepsComponents[currentStep]}
                </Container>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Review;
