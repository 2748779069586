import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import imageCompression from "browser-image-compression";
import { v4 } from "uuid";
import styles from "./CompanyInfo.module.scss";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { UserContext } from "../../context/userContext";
import { isAuthed } from "../../utils/authorization";
import firebase from "../../utils/firebase";
import { Container, Grid, Typography, Avatar, Divider, Hidden, Button, MenuItem } from "@material-ui/core";
import Menu from "../../components/Menu/Menu";
import Icon from "@mdi/react";
import { mdiCalendar } from "@mdi/js";
import Loading from "../../components/Loading/Loading";
import AvatarEditDialog from "../../components/AvatarEditDialog/AvatarEditDialog";
import EditDialog from "./EditDialog";

import { fiscalYearStart, fullDate } from "../../utils/dates";
import { addDays } from "date-fns";

const CompanyInfo = ({ params }) => {
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { user } = useContext(UserContext);
  const [dialog, setDialog] = useState({ org: null });
  const [initForm, setInitForm] = useState({});

  const [avatarEditDialog, setAvatarEditDialog] = useState(null);

  const { loading, data, refetch } = useQuery(GET_ORGANIZATION, {
    variables: { id: params.org, plan: user.departmentFilter.id },
  });
  const { data: closedCorpPlans } = useQuery(GET_CLOSED_CORP_PLANS, {
    variables: { id: params.org },
  });

  const [updateProfilePicture] = useMutation(UPDATE_PROFILE_PIC);

  const handleEditOrg = () => {
    const { name, fiscalYear, viewPreferences } = data.organization;
    setInitForm({
      name,
      fiscalYear: parseInt(fiscalYear),
      viewPreferences: viewPreferences.map(({ name, position, show }) => ({ name, position, show })),
    });
    setDialog({ ...dialog, org: true });
  };

  const handleEditClose = () => {
    setDialog({ org: null, core: null, bhag: null });
  };

  const handleAvatarEditDialog = (open) => () => {
    setAvatarEditDialog(open);
  };

  const handleUploadImage = async (e) => {
    if (!_.isEmpty(e.target.files) && e.target.validity.valid) {
      const file = e.target.files[0];
      setAvatarEditDialog(file);
    }
  };

  const handleSaveImage = (ref) => () => {
    snack("Uploading company picture...");

    if (profilePicture) {
      deleteImage();
    }

    const canvas = ref.current.getImage();
    canvas.toBlob(async (blob) => {
      const filename = v4();
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`organization-images/${filename}`);

      const compressionOptions = { maxSizeMB: 1 };
      const compressedFile = await imageCompression(blob, compressionOptions);

      fileRef.put(compressedFile).then(async () => {
        const url = await fileRef.getDownloadURL();
        const ok = await updateProfilePicture({ variables: { id: params.org, profilePicture: url } });

        if (ok.data.updateOrganization) {
          snack("Updated company picture");
          requestFetch();
          setAvatarEditDialog(null);
        }
      });
    });
  };

  const handleDeleteImage = async () => {
    snack(`Deleting image...`);
    deleteImage();

    const ok = await updateProfilePicture({ variables: { id: params.org, profilePicture: "" } });

    if (ok.data.updateOrganization) {
      snack("Deleted company picture");
      requestFetch();
    }
  };

  const deleteImage = () => {
    const storageRef = firebase.storage().refFromURL(profilePicture);
    storageRef.delete();
  };

  const clearPreviousImageUpload = (e) => {
    // Need to clear the value of the input because the onChange doesn't fire upon attempting to select the same image file after an image upload cancellation
    e.target.value = null;
  };

  useEffect(() => {
    refetch();
  }, [fetch, user.departmentFilter]);

  const mostRecentClosedCorpPlan = _.orderBy(_.get(closedCorpPlans, "corporatePlans", []), (plan) => parseInt(plan.year), ["desc"])[0];
  const minYear = mostRecentClosedCorpPlan ? addDays(parseInt(_.get(mostRecentClosedCorpPlan, "year")), 1) : undefined;

  if (loading) return <Loading />;

  if (!data.organization) return null; //TODO remove this check
  const { name, profilePicture, fiscalYear } = data.organization;

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5" className={styles.label}>
                  Company Information
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <div className={styles.avatarWrapper}>
                  <Avatar variant="square" className={styles.avatar} src={profilePicture}>
                    {name[0]}
                  </Avatar>
                  {isAuthed(user.user, "company admin") && (
                    <Menu className={styles.editAvatar} button="Edit">
                      <input
                        name="file"
                        onChange={handleUploadImage}
                        onClick={clearPreviousImageUpload}
                        accept="image/*"
                        id="file-upload"
                        type="file"
                        className={styles.input}
                      />
                      <label htmlFor="file-upload">
                        <MenuItem component="span">Upload New</MenuItem>
                      </label>
                      {profilePicture && (
                        <MenuItem className={styles.delete} onClick={handleDeleteImage}>
                          Delete
                        </MenuItem>
                      )}
                    </Menu>
                  )}
                </div>
                <Typography variant="h6" className={styles.label}>
                  Company Name
                </Typography>
                <div className={styles.flexParagraph}>
                  <Typography variant="h5">{name}</Typography>
                  {isAuthed(user.user, "company admin") && (
                    <Button onClick={handleEditOrg} variant="outlined" className={styles.editButton}>
                      Edit
                    </Button>
                  )}
                </div>

                <Typography variant="h6" className={styles.label}>
                  Current Fiscal Year
                </Typography>
                <div className={styles.flexParagraph}>
                  <Icon path={mdiCalendar} size={1.5} color="rgba(0, 0, 0, 0.65)" className={styles.icon} />
                  <Typography variant="h5">
                    {fullDate(fiscalYearStart(fiscalYear))} - {fullDate(fiscalYear)}
                  </Typography>
                </div>
                <Divider className={styles.divider} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <AvatarEditDialog
        open={Boolean(avatarEditDialog)}
        handleClose={handleAvatarEditDialog(false)}
        handleSave={handleSaveImage}
        src={avatarEditDialog}
      />
      <EditDialog
        id={params.org}
        open={dialog}
        handleClose={handleEditClose}
        initForm={initForm}
        name={_.get(dialog, "core.key", null)}
        plan={user.departmentFilter.id}
        minYear={minYear}
      />
    </>
  );
};

export default CompanyInfo;

const GET_ORGANIZATION = gql`
  query CompanyInfo_GetOrg($id: ID!, $plan: ID) {
    organization(id: $id, plan: $plan) {
      id
      name
      fiscalYear
      profilePicture
      plansOrder
      core {
        purpose
        value
        competency
        market
        offering
        valueProposition
        differentiation
        process
      }
      createdAt
      bhag {
        goal
        targetDate
      }
      viewPreferences {
        name
        position
        show
      }
      notes {
        bhag {
          id
          date
        }
        purpose {
          id
          date
        }
        value {
          id
          date
        }
        competency {
          id
          date
        }
        market {
          id
          date
        }
        offering {
          id
          date
        }
        valueProposition {
          id
          date
        }
        process {
          id
          date
        }
        differentiation {
          id
          date
        }
      }
    }

    plans(organization: $id, closed: false) {
      id
      departmentName
      theme
      year
      useDepartmentTheme
      sharedPlanId
      category
      color
      shortName
      targetDate
    }

    projects(organization: $id) {
      id
      value
      plan {
        id
        departmentName
      }
      notes {
        id
        date
      }
    }
  }
`;

const UPDATE_PROFILE_PIC = gql`
  mutation TheCore_UpdateProfilePic($id: ID!, $profilePicture: String!) {
    updateOrganization(id: $id, profilePicture: $profilePicture)
  }
`;

const GET_CLOSED_CORP_PLANS = gql`
  query GetCorpPlans($id: ID!) {
    corporatePlans: plans(organization: $id, departmentName: "Corporate", category: "1 year", closed: true) {
      id
      year
      closed
    }
  }
`;
