import React, { useEffect } from "react";
import styles from "./WeeklyReview.module.scss";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  useMediaQuery,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { TimePicker } from "@material-ui/pickers";
import { getDay } from "../../utils/dates";

import SelectUsers from "../../components/SelectUsers/SelectUsers";
import SelectDepartment from "../../components/SelectDepartment/SelectDepartment";

const initErrorForm = {
  title: ["required"],
};

const EditDialog = ({ open, handleClose, initForm, params, requestFetch, snack }) => {
  const { data } = useQuery(GET_USERS_AND_DEPARTMENTS, { variables: { organization: params.org } });
  const [deleteMeeting] = useMutation(DELETE_MEETING);
  const [updateMeeting] = useMutation(UPDATE_MEETING);
  const { form, formErrors, handleChange, handleChangeDate, handleChangeDayOfTheWeek, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const handleSubmit = async () => {
    if (!validateForm()) return null;

    const { title, users, startTime, steps, frequency, plan } = form;
    const formattedSteps = steps.map(({ name, alias, duration }) => ({ name, alias, duration: parseInt(duration) }));

    const ok = await updateMeeting({ variables: { id: initForm.id, title, users, startTime, steps: formattedSteps, frequency, plan } });
    if (ok.data.updateMeeting) {
      snack(`Updated "${title}" meeting`);
      requestFetch();
      resetForm(initForm);
      handleClose();
    }
  };

  const handleDelete = async () => {
    const { title } = form;

    const ok = await deleteMeeting({ variables: { id: initForm.id } });
    if (ok.data.deleteMeeting) {
      snack(`Deleted "${title}" meeting`);
      requestFetch();
      resetForm(initForm);
      handleClose();
    }
  };

  useEffect(() => {
    if (open) {
      resetForm(initForm);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.flexSpaceBetween}>
          Edit Meeting
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>

      <DialogContent>
        {!initForm.short && (
          <>
            <TextField
              autoFocus
              label="Title"
              name="title"
              fullWidth
              variant="outlined"
              margin="normal"
              value={form.title || ""}
              onChange={handleChange}
              helperText={formErrors.title}
              error={Boolean(formErrors.title)}
            />
            <SelectDepartment
              plans={_.get(data, "plans")}
              name="plan"
              handleChange={handleChange}
              value={form.plan}
              helperText={formErrors.plan}
              error={Boolean(formErrors.plan)}
              showAll
            />
            <SelectUsers
              name="users"
              label="Attendees"
              multiple
              users={_.get(data, "users")}
              handleChange={handleChange}
              values={form.users}
              plan={form.plan}
              allPlans={_.get(data, "plans")}
            />
            <FormControl fullWidth margin="normal">
              <InputLabel variant="outlined">Frequency</InputLabel>
              <Select variant="outlined" label="Frequency" name="frequency" value={form.frequency} onChange={handleChange}>
                <MenuItem value={1}>Every week</MenuItem>
                <MenuItem value={2}>Every 2 weeks</MenuItem>
                <MenuItem value={4}>Every month</MenuItem>
                <MenuItem value={12}>Every 3 months</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel variant="outlined">Day Of The Week</InputLabel>
              <Select
                variant="outlined"
                label="Day Of The Week"
                value={getDay(form.startTime)}
                onChange={handleChangeDayOfTheWeek("startTime")}
              >
                <MenuItem value={0}>Sunday</MenuItem>
                <MenuItem value={1}>Monday</MenuItem>
                <MenuItem value={2}>Tuesday</MenuItem>
                <MenuItem value={3}>Wednesday</MenuItem>
                <MenuItem value={4}>Thursday</MenuItem>
                <MenuItem value={5}>Friday</MenuItem>
                <MenuItem value={6}>Saturday</MenuItem>
              </Select>
            </FormControl>
            <TimePicker
              fullWidth
              variant="inline"
              inputVariant="outlined"
              label="Time"
              margin="normal"
              value={form.startTime || ""}
              onChange={handleChangeDate("startTime")}
            />
          </>
        )}

        <Typography className={styles.steps}>Steps</Typography>
        {form.steps.map(({ name, duration }, i) => {
          return (
            <div key={i}>
              <TextField
                label={name}
                name={`steps[${i}].alias`}
                variant="outlined"
                margin="normal"
                value={form.steps[i].alias || ""}
                onChange={handleChange}
                className={styles.alias}
              />
              <TextField
                type="number"
                label="Duration"
                name={`steps[${i}].duration`}
                variant="outlined"
                margin="normal"
                value={form.steps[i].duration || ""}
                onChange={handleChange}
                InputProps={{
                  endAdornment: <InputAdornment position="end">{duration === 1 ? "Minute" : "Minutes"}</InputAdornment>,
                }}
                className={styles.duration}
              />
            </div>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleDelete}>
          Delete
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained" className={styles.button}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;

const UPDATE_MEETING = gql`
  mutation WeeklyReviewsEditDialog_UpdateMeeting(
    $id: ID!
    $title: String!
    $users: [ID!]
    $startTime: String!
    $steps: [InputStep!]
    $frequency: Int
    $plan: ID
  ) {
    updateMeeting(id: $id, title: $title, users: $users, startTime: $startTime, steps: $steps, frequency: $frequency, plan: $plan)
  }
`;

const DELETE_MEETING = gql`
  mutation WeeklyReviewsEditDialog_DeleteMeeting($id: ID!) {
    deleteMeeting(id: $id)
  }
`;

const GET_USERS_AND_DEPARTMENTS = gql`
  query WeeklyReviewsEditDialog_GetUsersDepts($organization: ID!) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: "1 year", closed: false) {
      id
      departmentName
      sharedPlanId
    }
  }
`;
