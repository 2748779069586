import React, { useState, useEffect, useMemo } from "react";
import styles from "./QuarterSummary.module.scss";
import useNotes from "../Notes/useNotes";
import _ from "lodash";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  TableContainer,
  Tooltip,
  List,
  ListItem,
  TextField,
  Chip,
  Button,
  ListItemText,
  Avatar,
  Collapse,
} from "@material-ui/core";

import Icon from "@mdi/react";
import { mdiFileImage, mdiFileDocumentOutline, mdiDownload } from "@mdi/js";

import { getCurrentQuarter, fullDate } from "../../utils/dates";
import { getQuarters, getDeptShortName } from "../../utils/misc";
import { isAuthed } from "../../utils/authorization";

import PlanPill from "../PlanPill/PlanPill";
import RichText from "../RichText/RichText";
import { deserialize } from "../RichText/functions";
import { Autocomplete } from "@material-ui/lab";
import DeleteConfirm from "../Notes/DeleteConfirm";
import QSNotesDialog from "./QSNotesDialog";
import SelectDepartment from "../SelectDepartment/SelectDepartment";

const NOTES_MODEL = "plan";

const QuarterSummary = ({ rocks = [], plans, closed, fiscalYear, highestReviewedQuarter, currentQuarter, user, snack }) => {
  const {
    editMode,
    handleEditMode,
    handleClose,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleRemoveImage,
    handleCopyImageToClipboard,
  } = useNotes({
    user,
    snack,
    model: NOTES_MODEL,
  });

  const [maxQuarter, setMaxQuarter] = useState(0);
  const [quarterTopHits, setQuarterTopHits] = useState([]);
  const [quarterTopMisses, setQuarterTopMisses] = useState([]);
  const [quarterGrades, setQuarterGrades] = useState([]);
  const [quarterNotesPlans, setQuarterNotesPlans] = useState(["", "", "", ""]);
  const [quarterNotesDialogIdx, setQuarterNotesDialogIdx] = useState();

  const toTitleCase = (str) => {
    return _.startCase(_.toLower(str)).trim();
  };

  const handleSelectNotesPlan = (event, quarterIdx) => {
    const { value } = event.target;
    setQuarterNotesPlans((prev) => {
      const copy = [...prev];
      copy[quarterIdx] = value;
      return copy;
    });
  };

  const handleOpenQuarterNotesDialog = (quarterIdx) => () => {
    setQuarterNotesDialogIdx(quarterIdx);
    handleEditMode(true)();
  };

  useEffect(() => {
    if (plans) {
      let quarterGradeGroups = [[], [], [], []];

      for (const plan of plans) {
        const trimmedPlan = _.pick(plan, ["id", "color", "shortName", "departmentName"]);
        const periodData = _.get(plan, "periodData", []);

        periodData.forEach((periodDatum, quarterIdx) => {
          const gradeObj = _.get(periodDatum, "periodGrade", {});
          const notes = _.get(periodDatum, "notes", "");

          if (!Object.values(gradeObj).some((val) => _.isNil(val))) {
            quarterGradeGroups[quarterIdx].push({ ...trimmedPlan, ...gradeObj, notes });
          }
        });
      }

      setQuarterTopHits(
        quarterGradeGroups.map((quarterGradesArr) =>
          _(quarterGradesArr)
            .groupBy(({ topHit }) => `${_.toLower(topHit).trim()}`)
            .mapKeys((planObjs, grouping) => _.get(planObjs, ["0", "topHit"])) // want the original formatting partially altered  by toLower
            .value()
        )
      );

      setQuarterTopMisses(
        quarterGradeGroups.map((quarterGradesArr) =>
          _(quarterGradesArr)
            .groupBy(({ topMiss }) => `${_.toLower(topMiss).trim()}`)
            .mapKeys((planObjs, grouping) => _.get(planObjs, ["0", "topMiss"])) // want the original formatting partially altered  by toLower
            .value()
        )
      );

      setQuarterGrades(
        quarterGradeGroups.map((quarterGradesArr) => _.groupBy(quarterGradesArr, ({ grade }) => `${_.toUpper(grade).trim()}`))
      );
    }
  }, [plans]);

  useEffect(() => {
    const quarterCeiling =
      isNaN(highestReviewedQuarter) || currentQuarter > highestReviewedQuarter ? currentQuarter : highestReviewedQuarter;

    setMaxQuarter(quarterCeiling);
  }, [highestReviewedQuarter]);

  const quarterRocksData = useMemo(() => {
    let quarterOveralls = {};

    const quarterDataArr = _(rocks)
      .filter((rock) => {
        if (!_.isEmpty(plans)) {
          // The following block will only be ran on the summary page
          const rockQuarter = _.get(rock, "index");

          // const rockPlanId = _.get(rock, "plan.id");
          // const plan = _.find(plans, ["id", rockPlanId]);
          // return _.get(plan, ["periodData", (rockQuarter - 1).toString(), "reviewed"]);

          return rockQuarter <= maxQuarter;
        }
        return true;
      })
      .groupBy((rock) => {
        const user = _.get(rock, ["users", "0"]);
        const userId = _.get(user, "id");
        const firstName = toTitleCase(_.get(user, "name.first", ""));
        const lastName = toTitleCase(_.get(user, "name.last", ""));
        return `${userId}|${firstName} ${lastName}`;
      })
      .map((rocksArr, userStr) => {
        let rocksByQuarter = _(rocksArr).sortBy(["index"]).groupBy("index").value();

        let quarterStats = {};
        Object.keys(rocksByQuarter).forEach((quarterStr) => {
          const quarterRocks = rocksByQuarter[quarterStr];

          const completed = quarterRocks.filter((rock) => _.get(rock, "status") === "complete").length;
          const total = quarterRocks.length;

          quarterStats[quarterStr] = { completed, total };

          if (_.isNil(quarterOveralls[quarterStr])) {
            quarterOveralls[quarterStr] = { completed, total };
          } else {
            quarterOveralls[quarterStr]["total"] = quarterOveralls[quarterStr]["total"] + total;
            quarterOveralls[quarterStr]["completed"] = quarterOveralls[quarterStr]["completed"] + completed;
          }
        });

        let [id, name] = userStr.split("|");
        if (id === "undefined") {
          name = "Unassigned";
        }

        return { id, name, quarterStats };
      })
      .sortBy(["name"])
      .value();

    quarterDataArr.unshift({
      name: "Rocks",
      quarterStats: quarterOveralls,
    });

    return quarterDataArr;
  }, [rocks, plans, maxQuarter]);

  const isSingleQuarter = _.isNil(fiscalYear);

  const getQuarterCell = (completed = 0, total = 0) => {
    const percentage = total === 0 ? 0 : Math.round((completed / total) * 100);
    return (
      <>
        {completed}/{total} = <b>{percentage}%</b>
      </>
    );
  };

  const getGradeStatsRow = (gradeStatsArr = [], labelStr) => {
    return (
      <TableRow>
        <TableCell>{labelStr}</TableCell>
        {gradeStatsArr.map((statsDict, idx) => {
          // const allPlansSameVal = Object.keys(statsDict).length === 1;

          return (
            <TableCell align="center" key={idx}>
              <div className={styles.gradesOuter}>
                {Object.keys(statsDict).map((statsVal) => {
                  const plans = statsDict[statsVal];
                  const visiblePlansLimit = 4;

                  return (
                    <div className={styles.gradesInner}>
                      <div className={styles.plansContainer}>
                        {/* {plans.map((plan, idx) => (
                          <PlanPill plan={plan} key={IDBIndex} noMargin></PlanPill>
                        ))} */}
                        {plans.map((plan, idx) => idx < visiblePlansLimit && <PlanPill plan={plan} noMargin />)}
                        {plans.length > visiblePlansLimit && (
                          <Tooltip
                            interactive
                            title={
                              <List className={styles.tooltipList}>
                                {plans.map(
                                  (plan, idx) =>
                                    idx >= visiblePlansLimit && (
                                      <ListItem>
                                        <PlanPill plan={plan} noMargin />
                                      </ListItem>
                                    )
                                )}
                              </List>
                            }
                          >
                            <div style={{ display: "flex" }}>
                              <PlanPill noMargin noPlanVal={`${plans.length - visiblePlansLimit} MORE`} />
                            </div>
                          </Tooltip>
                        )}
                      </div>
                      <div className={styles.statsContainer}>
                        <b>{statsVal}</b>
                      </div>
                    </div>
                  );
                })}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  const getQuarterNotes = (quarterIdx) => {
    const selectedDept = quarterNotesPlans[quarterIdx];
    const hasSelectedDept = !_.isEmpty(selectedDept);

    let notes = [];
    if (hasSelectedDept) {
      const planObj = _.find(plans, ["id", selectedDept]);
      notes = _.get(planObj, ["periodData", quarterIdx.toString(), "notes"], []);
    }

    return (
      <TableRow>
        <TableCell> Q{quarterIdx + 1} Notes</TableCell>
        <TableCell colSpan={4} style={{ padding: 16 }}>
          <Typography>Please select a department to view its notes for quarter {quarterIdx + 1}:</Typography>
          <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
            <SelectDepartment
              plans={plans}
              name="departments"
              handleChange={(e) => handleSelectNotesPlan(e, quarterIdx)}
              value={selectedDept}
            />
            <Tooltip title={hasSelectedDept ? "" : "First select department to add note"}>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpenQuarterNotesDialog(quarterIdx)}
                  style={{ whiteSpace: "nowrap", display: "inline-block" }}
                  disabled={!hasSelectedDept}
                >
                  Add Note
                </Button>
              </div>
            </Tooltip>
          </div>
          <Collapse in={hasSelectedDept}>
            {_.isEmpty(notes) ? (
              <Typography>No notes at the moment</Typography>
            ) : (
              <List>
                {notes.map((note) => {
                  const { id: noteId, user: noteUser, date, text, url, filename, type } = note;
                  const isImg = type === "img";
                  const path = isImg ? mdiFileImage : mdiFileDocumentOutline;
                  const additionalProps = {
                    path: `periodData.${quarterIdx}`,
                  };

                  // const { open, id: editId } = editMode;
                  // const isBeingEdited = noteId === editId;

                  return (
                    <ListItem
                      key={date}
                      dense
                      // style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      {/* {editMode.open && isBeingEdited ? (
                        <div style={{ width: "100%", margin: "8px 0" }}>
                          <div style={{ flex: 1 }}>
                            <RichText
                              value={deserialize(text)}
                              readOnly={false}
                              onChange={(value) => handleChange({ target: { name: "text", value } })}
                            />
                          </div>
                          <div style={{ marginTop: 8, display: "flex", gap: 8 }}>
                            <div style={{ flex: 1 }}>
                              <label htmlFor="file-upload" style={{ display: "inline-block" }}>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  component="span"
                                  style={{ whiteSpace: "nowrap", display: "inline-block" }}
                                >
                                  Upload File
                                </Button>
                              </label>
                              <input
                                name="file"
                                onChange={handleChange}
                                accept="image/*, .doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document, .pdf"
                                id="file-upload"
                                type="file"
                                className={styles.input}
                              />
                            </div>
                            <div>
                              <Button
                                variant="contained"
                                onClick={handleUpdate({ editId, model: NOTES_MODEL, additionalProps })}
                                color="primary"
                                style={{ whiteSpace: "nowrap", display: "inline-block" }}
                              >
                                Update
                              </Button>
                            </div>
                            <div>
                              <Button onClick={handleClose}>Cancel</Button>
                            </div>
                          </div>
                        </div>
                      ) : ( */}
                      <ListItemText>
                        <div className={styles.listItemText}>
                          <div className={styles.flex}>
                            <Avatar className={styles.avatar}>
                              <Icon path={path} color="#fff" size={1} />
                            </Avatar>
                            <div>
                              <Typography variant="subtitle2" style={{ display: "flex", alignItems: "center" }}>
                                {!_.isNil(noteUser) && `${_.get(noteUser, "name.first")} ${_.get(noteUser, "name.last")}`}
                                {url !== "undefined" && !_.isNil(url) && (
                                  <a
                                    href={url}
                                    target="__blank"
                                    download
                                    className={styles.downloadLink}
                                    style={{ marginLeft: _.isNil(noteUser) ? 0 : 20 }}
                                  >
                                    <Icon path={mdiDownload} color="#3f51b5" size={0.75} />
                                    {filename}
                                  </a>
                                )}
                              </Typography>
                              <Typography variant="caption">{fullDate(date)}</Typography>
                            </div>
                          </div>
                          {isAuthed(user.user, "department facilitator") && (
                            <div>
                              <DeleteConfirm
                                id={noteId}
                                referenceId={selectedDept}
                                model={NOTES_MODEL}
                                filename={filename}
                                handleDelete={handleDelete}
                                additionalProps={additionalProps}
                                handleEditMode={handleEditMode}
                                handleRemoveImage={handleRemoveImage}
                                handleCopyImage={isImg ? handleCopyImageToClipboard : null}
                                note={note}
                                otherIconColor="#512DA8"
                              />
                            </div>
                          )}
                        </div>
                        <RichText value={deserialize(text)} readOnly={true} />
                        {isImg && <img src={url} alt="" className={styles.image} />}
                      </ListItemText>
                      {/* )} */}
                    </ListItem>
                  );
                })}
              </List>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer className={styles.tableContainer}>
      {isSingleQuarter && quarterRocksData.length === 1 ? (
        <Typography style={{ textAlign: "center" }}>No summary data available</Typography>
      ) : (
        <Table size="small">
          {!isSingleQuarter && (
            <>
              <colgroup>
                {[...Array(5).keys()].map((idx) => (
                  <col key={idx} style={{ width: idx > 0 ? "calc(90% / 4)" : "10%" }} />
                ))}
              </colgroup>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell style={{ backgroundColor: "white" }} />
                  {[...Array(4).keys()].map((quarterIdx) => {
                    const isCurrentQuarter = quarterIdx + 1 === currentQuarter;
                    return (
                      <TableCell align="center" key={quarterIdx}>
                        {/* <Typography style={{ fontWeight: isCurrentQuarter ? "bold" : "normal" }}>*/}
                        <Typography>
                          Q{quarterIdx + 1} {getQuarters(fiscalYear, quarterIdx + 1)}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </>
          )}
          <TableBody className={styles.tableBody}>
            {!isSingleQuarter && (
              <>
                {getGradeStatsRow(quarterTopHits, "Top Hit")}
                {getGradeStatsRow(quarterTopMisses, "Top Miss")}
                {getGradeStatsRow(quarterGrades, "Grade")}
              </>
            )}
            {quarterRocksData.map(({ id, name, quarterStats }) => {
              return (
                <TableRow key={`${id}${name}`}>
                  <TableCell>{name}</TableCell>
                  {isSingleQuarter
                    ? Object.values(quarterStats).map(({ completed, total }, idx) => (
                        <TableCell align="center" key={idx}>
                          {getQuarterCell(completed, total)}
                        </TableCell>
                      ))
                    : [...Array(4).keys()].map((quarterIdx) => {
                        const targetStats = _.get(quarterStats, (quarterIdx + 1).toString(), {});

                        return closed || quarterIdx + 1 <= maxQuarter ? (
                          <TableCell align="center" key={quarterIdx}>
                            <div>{getQuarterCell(targetStats.completed, targetStats.total)}</div>
                          </TableCell>
                        ) : (
                          <TableCell key={quarterIdx} />
                        );
                      })}
                </TableRow>
              );
            })}
            {!isSingleQuarter &&
              [...Array(4).keys()].map((quarterIdx) => {
                return getQuarterNotes(quarterIdx);
              })}
          </TableBody>
        </Table>
      )}
      <QSNotesDialog
        editMode={editMode}
        plan={quarterNotesPlans[quarterNotesDialogIdx]}
        handleCreate={handleCreate}
        handleUpdate={handleUpdate}
        handleChange={handleChange}
        handleClose={handleClose}
        additionalProps={{
          path: `periodData.${quarterNotesDialogIdx}`,
        }}
      />
    </TableContainer>
  );
};

export default QuarterSummary;
