import { useState, useEffect } from "react";

const useInViewport = (inViewport) => {
  const [hasRendered, setHasRendered] = useState(false);

  useEffect(() => {
    if (!hasRendered && inViewport) {
      setHasRendered(true);
    }
  }, [inViewport]);

  return hasRendered;
};

export default useInViewport;
