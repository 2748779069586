import { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { startOfWeek, endOfWeek, format } from "date-fns";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { DialogContext } from "../../../context/dialogContext";
import { FetchContext } from "../../../context/fetchContext";
import { SnackbarContext } from "../../../context/snackbarContext";
import { NotesContext } from "../../../context/notesContext";
import { quarterDates, formatAs, isBetweenIgnoreYear } from "../../../utils/dates";
import { fi } from "date-fns/locale";
import { OBJECTIVE_FIELDS } from "../../../utils/fragments";

const useReview = () => {
  const { dialog, setDialog } = useContext(DialogContext);
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { notes } = useContext(NotesContext);
  const [step, setStep] = useState(0);
  const [timer, setTimer] = useState(0); //in seconds
  const [editInfo, setEditInfo] = useState({ id: null, initForm: {}, category: null });
  const [updateSuccessCriteria] = useMutation(UPDATE_SUCCESS_CRITERIA);
  const [updateObjective] = useMutation(UPDATE_OBJECTIVE);

  const nextStep = () => {
    // Start timer for introduction
    if (step === 0) {
      setTimer(0 * 60);
    }
    setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const getWeek = () => {
    const today = new Date();
    const start = format(startOfWeek(today), "MMM d");
    const end = format(endOfWeek(today), "MMM d");
    return `${start} — ${end}`;
  };

  const getQuarter = (fiscalYear) => {
    const today = new Date();

    let [startQuarter, endQuarter] = quarterDates(fiscalYear, 1);
    if (isBetweenIgnoreYear(today, startQuarter, endQuarter)) {
      return `1 (${formatAs(startQuarter, "MMM d")} — ${formatAs(endQuarter, "MMM d")})`;
    }

    [startQuarter, endQuarter] = quarterDates(fiscalYear, 2);
    if (isBetweenIgnoreYear(today, startQuarter, endQuarter)) {
      return `2 (${formatAs(startQuarter, "MMM d")} — ${formatAs(endQuarter, "MMM d")})`;
    }

    [startQuarter, endQuarter] = quarterDates(fiscalYear, 3);
    if (isBetweenIgnoreYear(today, startQuarter, endQuarter)) {
      return `3 (${formatAs(startQuarter, "MMM d")} — ${formatAs(endQuarter, "MMM d")})`;
    }

    [startQuarter, endQuarter] = quarterDates(fiscalYear, 4);
    if (isBetweenIgnoreYear(today, startQuarter, endQuarter)) {
      return `4 (${formatAs(startQuarter, "MMM d")} — ${formatAs(endQuarter, "MMM d")})`;
    }
  };

  const getUnit = (value, unit) => {
    if (!value) return null;
    if (!unit) return Number(value).toLocaleString();
    if (unit === "$") return `${unit}${value}`;
    return `${Number(value).toLocaleString()} ${unit}`;
  };

  const printTime = (time) => {
    const sec_num = parseInt(time, 10); // don't forget the second param
    const hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return minutes + ":" + seconds;
  };

  const handleUpdateSuccessCriteria = (id, done, value) => async () => {
    const ok = await updateSuccessCriteria({ variables: { id, done: !done } });

    if (ok) {
      snack(`Marked "${value}" as ${done ? "incomplete" : "complete"}`);
      requestFetch();
    }
  };

  const handleUpdateStatus = (id, status) => async () => {
    const ok = await updateObjective({ variables: { id, status } });
    if (ok.data.updateObjective) {
      snack(`Marked  as ${status}`);
    }
  };

  const handleNotes = (id, model) => () => {
    notes(id, model);
  };

  const handleAddSuccessCriteriaDialog = (id) => () => {
    setDialog({
      ...dialog,
      addSuccessCriteriaDialog: id,
    });
  };

  const handleAddRockDialog = (id, quarter) => () => {
    setDialog({ ...dialog, addRockDialog: { open: id, quarter, value: null, objective: [] } });
  };

  const handleAddObjectiveDialog = (category) => () => {
    setDialog({
      ...dialog,
      addObjectiveDialog: { open: true, category },
    });
  };

  const handleAddMetricDialog = (category) => () => {
    setDialog({
      ...dialog,
      addMetricDialog: { open: true, category },
    });
  };

  const handleEditDialog =
    ({ id, initForm, category, type }) =>
    () => {
      if (type === false) {
        setEditInfo({ id: null, initForm: {}, category: null, type: null });
      } else {
        setEditInfo({ id, initForm, category, type });
      }
    };

  useEffect(() => {
    if (timer <= 0) return;
    if (step > 1 && timer > 0) return setTimer(0);
    setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  return {
    fetch,
    step,
    snack,
    nextStep,
    prevStep,
    getWeek,
    getQuarter,
    getUnit,
    timer,
    printTime,
    editInfo,
    handleUpdateSuccessCriteria,
    handleUpdateStatus,
    handleNotes,
    handleAddSuccessCriteriaDialog,
    handleAddRockDialog,
    handleAddObjectiveDialog,
    handleAddMetricDialog,
    handleEditDialog,
  };
};

export default useReview;

const UPDATE_SUCCESS_CRITERIA = gql`
  mutation ($id: ID!, $done: Boolean) {
    updateSuccessCriteria(id: $id, done: $done)
  }
`;

const UPDATE_OBJECTIVE = gql`
  ${OBJECTIVE_FIELDS}
  mutation ($id: ID!, $value: String, $users: [ID!], $objectives: [ID!], $status: String) {
    updateObjective(id: $id, value: $value, users: $users, objectives: $objectives, status: $status) {
      objective {
        ...ObjectiveFields
      }
      objectives {
        id
        number
        objectives
      }
    }
  }
`;
