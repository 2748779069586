import { Icon } from "@material-ui/core";
import React from "react";
import styles from "./RichText.module.scss";

export const RichTextButton = React.forwardRef(({ className, active, reversed, ...props }, ref) => (
  <span
    {...props}
    ref={ref}
    className={styles.button}
    style={{ color: reversed ? (active ? "white" : "#aaa") : active ? "black" : "#ccc" }}
  />
));

export const RichTextIcon = React.forwardRef(({ className, ...props }, ref) => <Icon {...props} ref={ref} className={styles.icon} />);

export const RichTextMenu = React.forwardRef(({ className, ...props }, ref) => <span {...props} ref={ref} className={styles.menu} />);
