import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

const list = [
  { name: "Add", syntax: "v0 + v1" },
  { name: "Subtract", syntax: "v0 - v1" },
  { name: "Multiply", syntax: "v0 * v1" },
  { name: "Divide", syntax: "v0 / v1" },
  { name: "Percentage", syntax: "v0%" },
  { name: "Divide", syntax: "v0 / v1" },
  { name: "Modulus", syntax: "v0 % v1" },
  { name: "Power", syntax: "v0 ^ v1" },
  { name: "Factorial", syntax: "v0!" },
  { name: "Trigonometry", syntax: "sin(v0)" },
  { name: "Sum", syntax: "sum(v0, v1, v2)" },
  { name: "Maximum", syntax: "max(v0, v1, v2)" },
  { name: "Minimum", syntax: "min(v0, v1, v2)" },
  { name: "Average", syntax: "mean(v0, v1, v2)" },
  { name: "Square Root", syntax: "sqrt(v0)" },
  { name: "Logarithm (base 10)", syntax: "log(v0, 10)" },
  { name: "Natural Logarithm base", syntax: "e" },
  { name: "Pi", syntax: "pi" },
];

const SyntaxDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>List of Syntax</DialogTitle>
      <DialogContent>
        <Table sx={{ minWidth: 400 }} aria-label="syntax table">
          <TableHead>
            <TableCell>Name</TableCell>
            <TableCell align="right">Syntax</TableCell>
          </TableHead>
          <TableBody>
            {list.map((item, idx) => (
              <TableRow key={idx}>
                <TableCell>{item.name}</TableCell>
                <TableCell align="right" style={{ color: "orangered" }}>
                  {item.syntax}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Dismiss
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SyntaxDialog;
