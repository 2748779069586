import React, { useState, useEffect, useContext, useRef } from "react";
import styles from "./Todos.module.scss";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { UserContext } from "../../context/userContext";
import { Container, Grid, Typography, MenuItem } from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import TodosComponent from "../../components/Todos/Todos";
import Menu from "../../components/Menu/Menu";
import { formatAs, getCurrentQuarter, quarterDates, addToDate } from "../../utils/dates";
import SelectCorpYear from "../../components/SelectCorpYear/SelectCorpYear";
import useTableFilters from "../../hooks/useTableFilters";
import useCorpPlan from "../../hooks/useCorpPlan";
import { TODO_FIELDS } from "../../utils/fragments";

const Todos = ({ user, params, issues, org }) => {
  const { fetch } = useContext(FetchContext);
  const { setUser } = useContext(UserContext);
  const fiscalYear = org.fiscalYear;
  const { corpForSelectedYear, setCorpForSelectedYear, corpPlans } = useCorpPlan({ orgId: params.org, fiscalYear });

  const location = issues ? "issuesPage" : "todosPage";

  const {
    page,
    rowsPerPage,
    sort,
    searchTerm,
    debouncedSearchTerm,
    showCompleted,
    handleChangePage,
    handleRowsPerPage,
    setSort,
    setSearchTerm,
    setShowCompleted,
  } = useTableFilters({
    location,
    initialValue: {
      rowsPerPage: JSON.parse(localStorage.getItem(`${location}.rowsPerPage`)) || 10,
      showCompleted: JSON.parse(localStorage.getItem(`${location}.showCompleted`)) || false,
      sortValue: JSON.parse(localStorage.getItem(`${location}.sortValue`)) || "priorityValue",
      sortOrder: JSON.parse(localStorage.getItem(`${location}.sortOrder`)) || "asc",
    },
  });

  useEffect(() => {
    handleChangePage({}, 0);
  }, [corpForSelectedYear]);

  const doneValue = showCompleted === true ? null : false;

  const { loading, data, refetch } = useQuery(GET_TODOS_AND_PLANS, {
    variables: {
      category: issues ? "issue" : "todo",
      organization: params.org,
      sharedPlanId: user.departmentFilter.sharedPlanId,
      oneYearCorpPlan: corpForSelectedYear.id,
      page,
      rowsPerPage,
      searchTerm: debouncedSearchTerm,
      sortBy: sort.value,
      sortDir: sort.order,
      done: doneValue,
    },
    fetchPolicy: "cache-and-network",
  });

  const handleClick = (id, sharedPlanId) => () => {
    setUser({ ...user, departmentFilter: { id, sharedPlanId } });
  };

  useEffect(() => {
    refetch();
  }, [fetch]);

  if (loading) return <Loading />;
  const { todos, todosCount, plans } = data;

  const currentPlans = _.filter(plans, (plan) => plan.year === fiscalYear);
  const quarter = getCurrentQuarter(fiscalYear);
  const [start, end] = quarterDates(addToDate(fiscalYear, { days: 1 }), quarter);
  const currentQuarter = { quarter, start, end };

  const selectedDept = plans.find((plan) => plan.sharedPlanId === user.departmentFilter.sharedPlanId);

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={styles.flexBetween}>
            <div>
              {currentQuarter && (
                <Grid item xs={12}>
                  <Typography variant="h5">
                    <span className={styles.label}>Current Quarter: </span>
                    {currentQuarter.quarter} ({formatAs(currentQuarter.start, "MMM d")} - {formatAs(currentQuarter.end, "MMM d")})
                  </Typography>
                  <SelectCorpYear
                    corporatePlans={corpPlans}
                    fiscalYear={fiscalYear}
                    corpForSelectedYear={corpForSelectedYear}
                    setCorpForSelectedYear={setCorpForSelectedYear}
                  />
                </Grid>
              )}
            </div>

            <Menu button={_.get(selectedDept, "departmentName", "All Departments")}>
              <MenuItem onClick={handleClick(null, null)} disabled={user.departmentFilter.id === null}>
                All Departments
              </MenuItem>
              {currentPlans &&
                currentPlans.map(({ id, departmentName, sharedPlanId }) => {
                  return (
                    <MenuItem key={id} onClick={handleClick(id, sharedPlanId)} disabled={user.departmentFilter.id === id}>
                      {departmentName}
                    </MenuItem>
                  );
                })}
            </Menu>
          </div>
        </Grid>
        <Grid item xs={12}>
          <TodosComponent
            todos={todos}
            issues={issues}
            showAccountable
            handleChangePage={handleChangePage}
            handleRowsPerPage={handleRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            searchTerm={searchTerm}
            setSort={setSort}
            setSearchTerm={setSearchTerm}
            setShowCompleted={setShowCompleted}
            showCompleted={showCompleted}
            location={location}
            total={todosCount.total}
            totalCompleted={todosCount.totalCompleted}
            planId={corpForSelectedYear.id}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Todos;

// Query Optimization Here
const GET_TODOS_AND_PLANS = gql`
  ${TODO_FIELDS}
  query Todos_GetTodosPlans(
    $organization: ID!
    $sharedPlanId: ID
    $category: String!
    $oneYearCorpPlan: ID
    $page: Int
    $rowsPerPage: Int
    $searchTerm: String
    $sortBy: String
    $sortDir: String
    $done: Boolean
  ) {
    todos(
      organization: $organization
      category: $category
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $oneYearCorpPlan
      page: $page
      rowsPerPage: $rowsPerPage
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      done: $done
    ) {
      ...TodoFields
    }

    todosCount(
      organization: $organization
      category: $category
      sharedPlanId: $sharedPlanId
      oneYearCorpPlan: $oneYearCorpPlan
      searchTerm: $searchTerm
    ) {
      total
      totalCompleted
    }

    plans(organization: $organization, closed: false, category: "1 year") {
      id
      departmentName
      sharedPlanId
      year
    }
  }
`;
