import React, { useState } from "react";
import styles from "./OneYear.module.scss";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  DialogContentText,
} from "@material-ui/core";
import Loading from "../../components/Loading/Loading";
import { year as yearUtil } from "../../utils/dates";

const WARNINGS = ["All rocks up to date", "All 1 year objectives are up to date", "All 1 year and mult-year metrics are up to date"];

const CloseYearDialog = ({ open, handleClose, year, organizationId }) => {
  const [confirmText, setConfirmText] = useState();
  const [loading, setLoading] = useState(false);
  const [closeYear] = useMutation(CLOSE_PLAN);

  const handleChange = (e) => {
    setConfirmText(e.target.value);
  };

  const handleCloseYear = async () => {
    setLoading(true);
    const res = await closeYear({ variables: { id: organizationId } });
    if (res.data.closeCurrentPlans) {
      setLoading(false);
      window.location.reload();
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={
        loading
          ? null
          : (event, reason) => {
              if (reason !== "backdropClick") {
                handleClose();
              }
            }
      }
      fullWidth
    >
      <DialogTitle>Close Out Year</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You are about to create a new year and close out the most recent year. This will archive all metrics, objectives, and rocks for
          the most recent year; including department specific items. This cannot be undone.
        </DialogContentText>
        <List>
          {WARNINGS.map((str) => {
            return (
              <ListItem key={str}>
                <ListItemText className={styles.red}>{str}</ListItemText>
              </ListItem>
            );
          })}
        </List>
        <DialogContentText>
          Please type <code>{yearUtil(year)}</code> to proceed.
        </DialogContentText>
        <TextField variant="outlined" fullWidth value={confirmText || ""} onChange={handleChange} />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="primary" onClick={handleClose} disabled={loading}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleCloseYear} disabled={confirmText !== yearUtil(year) || loading}>
          {loading ? <Loading color="#fff" size={24} /> : "Close Year"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CloseYearDialog;

const CLOSE_PLAN = gql`
  mutation ($id: ID!) {
    closeCurrentPlans(id: $id)
  }
`;
