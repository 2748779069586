import React from "react";
import styles from "./CardTitle.module.scss";
import { Card, CardContent } from "@material-ui/core";
import {
  red,
  pink,
  purple,
  deepPurple,
  indigo,
  blue,
  lightBlue,
  cyan,
  teal,
  green,
  lightGreen,
  lime,
  yellow,
  amber,
  orange,
  deepOrange
} from "@material-ui/core/colors";

const bg = {
  red: red[400],
  pink: pink[400],
  purple: purple[400],
  deepPurple: deepPurple[400],
  indigo: indigo[400],
  blue: blue[400],
  lightBlue: lightBlue[400],
  cyan: cyan[400],
  teal: teal[400],
  green: green[400],
  lightGreen: lightGreen[400],
  lime: lime[400],
  yellow: yellow[700],
  amber: amber[400],
  orange: orange[400],
  deepOrange: deepOrange[400]
};

const CardTitle = ({ vertical, children, color, colorNum }) => {
  const bgColor = color ? bg[color] : Object.values(bg)[colorNum];
  return (
    <Card
      style={{
        backgroundColor: bgColor
      }}
      className={vertical ? styles.cardVertical : styles.card}
    >
      <CardContent className={styles.content}>{children}</CardContent>
    </Card>
  );
};

export default CardTitle;
