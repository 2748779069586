import React from "react";
import styles from "./ProgressBar.module.scss";
import _ from "lodash";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Typography } from "@material-ui/core";

const ProgressBar = ({ rocks, objectives, organization, sc, done }) => {
  const { loading, data } = useQuery(GET_OBJECTIVES, {
    variables: { organization, ids: objectives },
    skip: _.isNil(organization),
  });

  if (loading) return null;

  let percentage = 0.0;

  if (rocks) {
    let done = 0,
      total = 0;

    rocks.forEach(({ successCriterias }) => {
      successCriterias.forEach((s) => {
        total++;
        if (s.done) {
          done++;
        }
      });
    });

    percentage = (done / total) * 100;
  } else if (sc) {
    let done = 0,
      total = 0;

    sc.forEach((s) => {
      total++;
      if (s.done) {
        done++;
      }
    });

    percentage = (done / total) * 100;
  } else {
    let done = 0,
      total = 0;

    if (!_.isNil(data)) {
      data.objectives.forEach(({ rocks }) => {
        rocks.forEach(({ successCriterias }) => {
          successCriterias.forEach((s) => {
            total++;
            if (s.done) {
              done++;
            }
          });
        });
      });

      percentage = (done / total) * 100;
    }
  }

  return (
    <div className={styles.container}>
      <Typography variant="body2" className={styles.text}>
        {percentage > 99 ? "100" : parseInt(done ? 100 : percentage || "0")}%
      </Typography>
      <div className={styles.bar} style={{ width: done ? "100%" : `${percentage || 0}%` }} />
    </div>
  );
};

export default ProgressBar;

const GET_OBJECTIVES = gql`
  query($organization: ID!, $ids: [ID!]) {
    objectives(organization: $organization, ids: $ids) {
      id
      rocks {
        successCriterias {
          done
        }
      }
    }
  }
`;
