import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import styles from "./WeeklyTargets.module.scss";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import { Autocomplete, ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import gql from "graphql-tag";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  FormControlLabel,
  Switch,
  Chip,
  Tooltip,
  Typography,
} from "@material-ui/core";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import SelectUsers from "../SelectUsers/SelectUsers";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import VariableDialog from "../VariableDialog/VariableDialog";
import SyntaxDialog from "../VariableDialog/SyntaxDialog";
import { WEEKLY_TARGET_FIELDS } from "../../utils/fragments";
import SelectMetric from "../SelectMetric/SelectMetric";
import PlanPill from "../PlanPill/PlanPill";

const initErrorForm = {
  value: ["required"],
  target: [
    {
      type: "custom",
      callback: (form, value) => (form.accuracy !== "btw" && !(_.isNil(value) || _.isEmpty(value))) || form.accuracy === "btw",
      errorMessage: "This field is required",
    },
  ],
  targetMin: [
    {
      type: "custom",
      callback: (form, value) => (form.accuracy === "btw" && !(_.isNil(value) || _.isEmpty(value))) || form.accuracy !== "btw",
      errorMessage: "This field is required",
    },
  ],
  targetMax: [
    {
      type: "custom",
      callback: (form, value) => (form.accuracy === "btw" && !(_.isNil(value) || _.isEmpty(value))) || form.accuracy !== "btw",
      errorMessage: "This field is required",
    },
  ],
  formula: [
    {
      type: "custom",
      callback: (form, value) => (form.enableFormula === true && !(_.isNil(value) || _.isEmpty(value))) || form.enableFormula !== true,
      errorMessage: "This field is required",
    },
  ],
  user: ["required"],
};

const EditDialog = ({ open, handleClose, weeklyTarget, snack, requestFetch, weeklyTargets, planId }) => {
  const {
    id,
    value,
    enableFormula,
    formula,
    formulaScope,
    number,
    accuracy,
    target,
    targetMin,
    targetMax,
    unit,
    measurables,
    user,
    plans,
    oneYearMetric,
  } = weeklyTarget;
  const params = useParams();
  const { data, loading } = useQuery(GET_USERS_PLANS_AND_METRICS, {
    variables: { organization: params.org, oneYearCorpPlan: planId },
  });
  const [updateWeeklyTarget] = useMutation(UPDATE_WEEKLY_TARGET);
  const [variableDialogOpen, setVariableDialogOpen] = useState(false);
  const [formulaScopeIdx, setFormulaScopeIdx] = useState(null);

  const [inputSearchTerm, setInputSearchTerm] = useState("");
  // syntax dialog states
  const [syntaxDialogOpen, setSyntaxDialogOpen] = useState(false);

  const initForm = {
    id,
    value,
    accuracy,
    target,
    targetMin,
    targetMax,
    enableFormula,
    formula,
    formulaScope: (formulaScope || []).map((variable) => _.omit(variable, "__typename")),
    unit,
    plans: _.map(plans, "id"),
    user: _.get(user, "id"),
    oneYearMetric,
  };

  const {
    form,
    formErrors,
    resetForm,
    handleChange,
    handleChangeManual,
    handleChangeToggleButton,
    handleToggleCheckBox,
    validateForm,
    valueAsFormattedNum,
    removeFormattedNum,
  } = useForm({
    initForm,
    initErrorForm,
  });

  const weeklyTargetsById = _.keyBy(weeklyTargets, "id");

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const { value, target, targetMin, targetMax, enableFormula, formula, formulaScope, unit, accuracy, plans, user, oneYearMetric } = form;

    const ok = await updateWeeklyTarget({
      variables: {
        id,
        value,
        target: !_.isNil(target) ? removeFormattedNum(target) : target,
        targetMin: !_.isNil(targetMin) ? removeFormattedNum(targetMin) : targetMin,
        targetMax: !_.isNil(targetMax) ? removeFormattedNum(targetMax) : targetMax,
        enableFormula,
        formula,
        formulaScope,
        unit,
        accuracy,
        plans,
        user,
        oneYearMetric,
      },
    });

    if (ok.data.updateWeeklyTarget) {
      snack(`Updated "${value}" KPI`);
      // requestFetch();
      handleClose();
    }
  };

  useEffect(() => {
    resetForm(initForm);
  }, [id]);

  const handleCloseDialog = () => {
    resetForm(initForm);
    handleClose();
  };

  const numOfvariables = _.get(form, "formulaScope.length");
  const currentVariable = _.get(form, `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`, {
    varType: "reference",
    value: null,
  });

  const filteredOneYearMetrics = _.filter(_.get(data, "metrics", []), (metric) => metric.value.includes(inputSearchTerm));
  const selectedOneYearMetric = _.get(data, "metrics", []).find((item) => item.id === form.oneYearMetric);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCloseDialog();
        }
      }}
      fullWidth
    >
      <DialogTitle>Edit KPI</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label={`KPI Description`}
          name="value"
          fullWidth
          variant="outlined"
          margin="normal"
          multiline
          value={form.value || ""}
          onChange={handleChange}
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />
        <div className={styles.modelButtons}>
          <ToggleButtonGroup exclusive value={form.accuracy} onChange={handleChangeToggleButton("accuracy")}>
            <ToggleButton value="lt">
              <span className={styles.toggleText}>&#60;</span>
            </ToggleButton>
            <ToggleButton value="lte">
              <span className={styles.toggleText}>&#8804;</span>
            </ToggleButton>
            <ToggleButton value="eq">
              <span className={styles.toggleText}>=</span>
            </ToggleButton>
            <ToggleButton value="gte">
              <span className={styles.toggleText}>&#8805;</span>
            </ToggleButton>
            <ToggleButton value="gt">
              <span className={styles.toggleText}>&#62;</span>
            </ToggleButton>
            <ToggleButton value="btw">
              <span className={styles.toggleText}>a&#60;x&#60;b</span>
            </ToggleButton>
            <ToggleButton value="percent">
              <span>%</span>
            </ToggleButton>
            <ToggleButton value="delta">
              <span className={styles.toggleText}>&#916;</span>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className={styles.metricInput}>
          {form.accuracy !== "btw" ? (
            <TextField
              label="Target"
              name="target"
              fullWidth
              margin="normal"
              variant="outlined"
              value={valueAsFormattedNum(form.target)}
              onChange={handleChange}
              helperText={formErrors.target}
              error={Boolean(formErrors.target)}
            />
          ) : (
            <>
              <TextField
                label="Min"
                name="targetMin"
                fullWidth
                margin="normal"
                variant="outlined"
                value={valueAsFormattedNum(form.targetMin)}
                onChange={handleChange}
                helperText={formErrors.targetMin}
                error={Boolean(formErrors.targetMin)}
              />
              <TextField
                label="Max"
                name="targetMax"
                fullWidth
                margin="normal"
                variant="outlined"
                value={valueAsFormattedNum(form.targetMax)}
                onChange={handleChange}
                helperText={formErrors.targetMax}
                error={Boolean(formErrors.targetMax)}
                style={{ marginLeft: "8px" }}
              />
            </>
          )}
          <TextField
            label="Unit"
            name="unit"
            margin="normal"
            variant="outlined"
            value={form.unit || ""}
            onChange={handleChange}
            className={styles.unit}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <FormControlLabel
            control={<Switch size="small" name="enableFormula" checked={!!form.enableFormula} onChange={handleToggleCheckBox} />}
            label="Enable Formula"
          />
        </div>
        {form.enableFormula && (
          <div className={styles.formulaInput}>
            <TextField
              placeholder="Formula (e.g. (v0 + 100)/v2)"
              name="formula"
              fullWidth
              margin="dense"
              variant="filled"
              value={form.formula}
              onChange={handleChange}
              helperText={formErrors.formula}
              error={Boolean(formErrors.formula)}
              InputProps={{
                endAdornment: (
                  <>
                    <Tooltip title="Add Variable">
                      <IconButton onClick={() => setVariableDialogOpen(true)}>
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Help">
                      <IconButton onClick={() => setSyntaxDialogOpen(true)}>
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ),
              }}
            />
            <div>
              {form.formulaScope.map((scope, idx) => {
                const { varType, value: scopeValue } = scope;
                const displayName = varType === "number" ? scopeValue : _.get(weeklyTargetsById, `${scopeValue}.value`, "");
                return (
                  <Chip
                    key={idx}
                    label={
                      <span>
                        <span style={{ fontWeight: "bold" }}>{`v${idx}: `}</span>
                        {`${displayName}`}
                      </span>
                    }
                    variant="outlined"
                    size="small"
                    style={{ marginRight: "4px" }}
                    onDelete={() => {
                      const newScope = form.formulaScope.filter((scope, index) => index !== idx);
                      handleChange({
                        target: {
                          name: "formulaScope",
                          value: newScope,
                        },
                      });
                    }}
                    onClick={() => {
                      setVariableDialogOpen(true);
                      setFormulaScopeIdx(idx);
                    }}
                  />
                );
              })}
            </div>
            <VariableDialog
              open={variableDialogOpen}
              handleClose={() => {
                setVariableDialogOpen(false);
                setFormulaScopeIdx(null);
              }}
              handleChangeVariable={(value) =>
                handleChange({
                  target: {
                    name: `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`,
                    value,
                  },
                })
              }
              formulaScope={currentVariable}
              list={weeklyTargets}
              listName={"KPI"}
              createMode={_.isNil(formulaScopeIdx)}
              currentId={id}
            />
            <SyntaxDialog open={syntaxDialogOpen} handleClose={() => setSyntaxDialogOpen(false)} />
          </div>
        )}
        {!loading && (
          <Autocomplete
            options={filteredOneYearMetrics}
            value={selectedOneYearMetric || inputSearchTerm}
            getOptionLabel={(option) => (typeof option === "string" ? option || "" : option.value || "")}
            renderOption={(option, state) => {
              const { id, value, plan } = option;
              return (
                <div className={styles.referenceItem}>
                  <PlanPill plan={plan} key={id} />
                  <Typography className={styles.referenceItemText}>{value}</Typography>
                </div>
              );
            }}
            fullWidth
            onChange={(e, metric) => handleChangeManual({ name: "oneYearMetric", value: _.get(metric, "id", null) })}
            onInputChange={(e, newInputValue) => setInputSearchTerm(newInputValue)}
            renderInput={(params) => <TextField {...params} style={{ marginTop: "16px" }} variant="outlined" label={`One Year Metric`} />}
          />
        )}
        {/* {!loading && (
          <SelectMetric
            showAll={true}
            metrics={_.get(data, "metrics")}
            label="One Year Metric"
            name="oneYearMetric"
            handleChange={handleChange}
            value={form.oneYearMetric}
            helperText={formErrors.oneYearMetric}
            error={Boolean(formErrors.oneYearMetric)}
          />
        )} */}

        {!loading && (
          <SelectDepartment
            plans={_.get(data, "plans")}
            name="plans"
            handleChange={handleChange}
            value={form.plans}
            helperText={formErrors.plans}
            error={Boolean(formErrors.plans)}
            multiple
          />
        )}
        {!loading && (
          <SelectUsers
            name="user"
            users={_.get(data, "users")}
            handleChange={handleChange}
            values={form.user}
            helperText="Who is accountable for this KPI?"
            plan={form.plans}
            allPlans={_.get(data, "plans")}
            error={Boolean(formErrors.user)}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDialog;

const UPDATE_WEEKLY_TARGET = gql`
  ${WEEKLY_TARGET_FIELDS}
  mutation KpisEditDialog_UpdateKpi(
    $id: ID!
    $value: String
    $target: String
    $targetMax: String
    $targetMin: String
    $enableFormula: Boolean
    $formula: String
    $formulaScope: [FormulaVariableInput!]
    $accuracy: String
    $unit: String
    $plans: [ID!]
    $user: ID
    $oneYearMetric: ID
  ) {
    updateWeeklyTarget(
      id: $id
      value: $value
      accuracy: $accuracy
      target: $target
      targetMin: $targetMin
      targetMax: $targetMax
      enableFormula: $enableFormula
      formula: $formula
      formulaScope: $formulaScope
      unit: $unit
      plans: $plans
      user: $user
      oneYearMetric: $oneYearMetric
    ) {
      weeklyTarget {
        ...WeeklyTargetFields
      }
      metrics {
        id
        weeklyTarget
      }
      weeklyTargets {
        id: _id
        oneYearMetric
      }
    }
  }
`;

const GET_USERS_PLANS_AND_METRICS = gql`
  query KpisEditDialog_GetUsersPlansMetrics($organization: ID!, $oneYearCorpPlan: ID) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $oneYearCorpPlan) {
      id
      departmentName
      sharedPlanId
    }

    metrics(organization: $organization, category: "quarter", corpPlan: $oneYearCorpPlan) {
      id
      value
      plan {
        id
        color
        shortName
        departmentName
        sharedPlanId
      }
    }
  }
`;
