import React from "react";
import { Grid } from "@material-ui/core";

import Project from "./Project";

const Projects = ({ projects, handleConfirmDelete }) => {
  return (
    <Grid container spacing={3}>
      {projects &&
        projects.map((project, i) => {
          return <Project project={project} key={i} handleConfirmDelete={handleConfirmDelete} />;
        })}
    </Grid>
  );
};

export default Projects;
