import _ from "lodash";

export const permissionDictionary = {
  "ventrek admin": 1,
  "ventrek facilitator": 2,
  "company admin": 3,
  "department admin": 4,
  "department facilitator": 5,
  "department employee": 6,
};

export const isVentrekAdmin = (user) => {
  return user.auth === "ventrek admin";
};

export const isVentrekFacilitator = (user) => {
  return user.auth === "ventrek facilitator";
};

export const isCompanyAdmin = (user) => {
  return user.auth === "company admin";
};

export const isDepartmentAdmin = (user) => {
  return user.auth === "department admin";
};

export const isDepartmentFacilitator = (user) => {
  return user.auth === "department facilitator";
};

export const isDepartmentEmployee = (user) => {
  return user.auth === "department employee";
};

export const getListOfAuth = (permissionInts) => {
  const arr = [];
  const permissions = _.invert(permissionDictionary);

  const str = permissionInts.toString();
  for (let char of str) {
    arr.push(permissions[char]);
  }

  return arr;
};

export const isAuthed = (user, minPermission) => {
  const userInt = permissionDictionary[user.auth];
  const permissionInt = permissionDictionary[minPermission];

  return userInt <= permissionInt;
};
