import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import _ from "lodash";

const useCorpPlan = ({ orgId, fiscalYear }) => {
  const [corpForSelectedYear, setCorpForSelectedYear] = useState({});
  const { data } = useQuery(GET_CORP_PLANS, {
    variables: {
      organization: orgId,
    },
  });

  useEffect(() => {
    // the following sets the default to the current corporate plan (in terms of the year selected)
    if (data && _.isEmpty(corpForSelectedYear)) {
      const currentYearCorpPlan = _.find(data.corporatePlans, ({ year }) => fiscalYear === year);
      setCorpForSelectedYear(currentYearCorpPlan);
    }
  }, [data]);

  return {
    corpForSelectedYear,
    setCorpForSelectedYear,
    corpPlans: _.get(data, "corporatePlans", []),
  };
};

export default useCorpPlan;

const GET_CORP_PLANS = gql`
  query GetCorpPlans($organization: ID!) {
    corporatePlans: plans(organization: $organization, departmentName: "Corporate", category: "1 year") {
      id
      year
      closed
    }
  }
`;
