import React from "react";
import { useSelected, useFocused, useSlateStatic } from "slate-react";
import Icon from "@mdi/react";
import { IconButton } from "@material-ui/core";
import { mdiLinkOff, mdiOpenInNew } from "@mdi/js";
import { removeLink } from "./functions";
import styles from "./RichText.module.scss";

export const Link = ({ attributes, element, children }) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div className={styles.elementLink}>
      <a {...attributes} href={element.href} target="_blank">
        {children}
      </a>
      {selected && focused && (
        <div className={styles.popup} contentEditable={false}>
          <a href={element.href} rel="noreferrer" target="_blank">
            <Icon path={mdiOpenInNew} size={1} />
            {element.href}
          </a>
          <IconButton onClick={() => removeLink(editor)} style={{ padding: 0 }}>
            <Icon path={mdiLinkOff} size={1} />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export const Paragraph = ({ style, attributes, children }) => {
  return (
    <div style={style} {...attributes}>
      {children}
    </div>
  );
};
