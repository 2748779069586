import React, { useState, useContext, useEffect } from "react";
import styles from "./Users.module.scss";
import _ from "lodash";
import { useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  Container,
  Grid,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Card,
  CardContent,
  CardActions,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Hidden,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import CardTitle from "../../components/CardTitle/CardTitle";
import { useForm } from "../../hooks";
import Loading from "../../components/Loading/Loading";
import User from "./User";
import firebase from "../../utils/firebase";
import imageCompression from "browser-image-compression";
import EditDialog from "./EditDialog";
import useTableFilters from "../../hooks/useTableFilters";

const initForm = {
  firstName: null,
  lastName: null,
  email: null,
  password: null,
  position: null,
  plan: [],
  notificationSettings: {
    weeklyReminder: {
      frequency: "N/A",
      enabled: true,
    },
  },
  auth: "department employee",
  profilePicture: null,
};

const initErrorForm = {
  firstName: ["required"],
  lastName: ["required"],
  email: ["required"],
  position: ["required"],
  password: [
    {
      type: "custom",
      callback: (form, value) => {
        return (
          (_.isNil(form.id) && !_.isNil(value) && value.length >= 6) ||
          (!_.isNil(form.id) && (_.isEmpty(value) || (!_.isEmpty(value) && value.length >= 6)))
        );
      },
      errorMessage: "At least 6 characters is required",
    },
  ],
};

const Users = ({ params, org }) => {
  // const url = `${process.env.REACT_APP_URL}/signup?org=${params.org}`;
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  // dialog
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  // pagination
  const { page, rowsPerPage, searchTerm, debouncedSearchTerm, handleChangePage, handleRowsPerPage, setSearchTerm } = useTableFilters({
    initialValue: {},
  });
  const [getUsers, { data, loading, refetch }] = useLazyQuery(GET_USERS_AND_DEPARTMENTS);
  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER);
  const [deleteUser] = useMutation(DELETE_USER);

  const { form, formErrors, handleChange, resetForm, validateForm, handleToggleCheckBox } = useForm({
    initForm,
    initErrorForm,
  });

  const handleDialog =
    (open, user = null) =>
      () => {
        setSelectedUser(user);
        setOpen(open);
      };

  // const handleClick = () => {
  //   const str = document.getElementById("inputid");

  //   str.select();
  //   str.setSelectionRange(0, 99999); // For mobile

  //   document.execCommand("copy");
  // };

  const handleCreateSubmit = async (e) => {
    if (!validateForm()) return;
    e.preventDefault();

    const { email, firstName, lastName, password, position, plan, notificationSettings, profilePicture, auth } = form;
    const { data } = await createUser({
      variables: {
        organization: params.org,
        email,
        firstName,
        lastName,
        password,
        position,
        plan,
        notificationSettings,
        profilePicture,
        auth,
      },
    });

    if (data.createUser.ok) {
      snack(`Created user: ${firstName} ${lastName}`);
      resetForm();
      requestFetch();
      setOpen(false);
    } else {
      snack(data.createUser.reason, "error");
    }
  };

  const handleEditSubmit = async (e) => {
    if (!validateForm()) return;
    e.preventDefault();

    const { firstName, lastName, position, email, password, plan, id, notificationSettings, profilePicture, auth } = form;
    const ok = await updateUser({
      variables: { id, firstName, lastName, password, position, email, plan, notificationSettings, profilePicture, auth },
    });

    if (ok) {
      snack(`Updated user: ${firstName} ${lastName}`);
      requestFetch();
      setOpen(false);
    }
  };

  // const handleChangeAuth = (user) => async () => {
  //   const { id, name, auth } = user;
  //   const ok = await updateUser({ variables: { id, auth } });

  //   if (ok) {
  //     snack(`Changed ${name.first} ${name.last}'s authorization level to ${auth}`);
  //     requestFetch();
  //   }
  // };

  const deleteImage = async (profilePicture) => {
    if (profilePicture) {
      const storageRef = firebase.storage().refFromURL(profilePicture);
      await storageRef.delete();
    }
  };

  // removing existing user image
  const handleDeleteImage = async (id, profilePicture) => {
    if (profilePicture) {
      await deleteImage(profilePicture);

      await updateUser({
        variables: { id, profilePicture: "" },
      });

      snack("profile image removed");
      requestFetch();
    } else {
      snack("profile image already removed");
    }
  };

  const handleUploadImage = async (e, profilePicture) => {
    let url = "";
    if (!_.isEmpty(_.get(e, "target.files")) && _.get(e, "target.validity.valid")) {
      snack("Uploading profile picture...");

      //remove existing picture
      if (profilePicture) {
        await deleteImage(profilePicture);
      }
      const file = e.target.files[0];
      const filename = file.name;

      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`user-images/${filename}`);

      const compressionOptions = { maxSizeMB: 1 };
      const compressedFile = await imageCompression(file, compressionOptions);
      const uploadTask = await fileRef.put(compressedFile);
      if (uploadTask.state === "success") {
        url = await fileRef.getDownloadURL();
      }
    }
    return url;
  };

  const handleCreateUserCancel = (form) => {
    let notUsedPictureUrl = form.profilePicture;
    if (notUsedPictureUrl) {
      deleteImage(notUsedPictureUrl);
    }
    setSelectedUser(null);
    setOpen(false);
  };

  const handleDelete = async (user) => {
    const { id, name } = user;
    const ok = await deleteUser({ variables: { id } });

    if (ok) {
      snack(`Deleted user: ${name.first} ${name.last}`);
      requestFetch();
      setDeleteDialogOpen(false);
      setSelectedUser(null);
    }
  };

  useEffect(() => {
    getUsers({ variables: { organization: params.org, offset: page, size: rowsPerPage, search: debouncedSearchTerm } });
  }, [page, rowsPerPage, debouncedSearchTerm]);

  useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [fetch]);

  if (loading || !data) return <Loading />;
  const users = _.get(data, "users", []);
  const fiscalYear = org.fiscalYear;
  const currentYearPlans = _.get(data, "plans", []).filter(({ year }) => year === fiscalYear);

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h6" className={styles.label}>
                Manage Users
              </Typography>
            </Grid>
          </Hidden>
          <Grid item xs={12}>
            <Card className={styles.card}>
              <CardTitle vertical color="teal">
                <Typography variant="h5" className={styles.title}>
                  Users
                </Typography>
              </CardTitle>
              <CardActions className={styles.cardActions}>
                <TextField
                  variant="outlined"
                  label="Search"
                  name="search"
                  value={searchTerm || ""}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  // onKeyPress={(e) => {
                  //   if (e.key === "Enter") handleSearch();
                  // }}
                  className={styles.left}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton disabled={true}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  startIcon={<AddIcon />}
                  className={styles.leftIcon}
                  onClick={handleDialog(true)}
                  variant="contained"
                  color="primary"
                  data-test='button-new-user'
                >
                  New User
                </Button>
              </CardActions>
              <CardContent className={styles.cardContent}>
                <Table size="small" className={styles.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell>&nbsp;</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Department</TableCell>
                      <TableCell>Authorization</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users &&
                      users.map((user) => {
                        return (
                          <User
                            key={user.id}
                            user={user}
                            snack={snack}
                            handleEdit={handleDialog(true, user)}
                            handleDelete={() => {
                              setDeleteDialogOpen(true);
                              setSelectedUser(user);
                            }}
                            handleDeleteImage={() => handleDeleteImage(user.id, user.profilePicture)}
                            fiscalYear={org.fiscalYear}
                          />
                        );
                      })}
                  </TableBody>
                </Table>
                <TablePagination
                  page={page}
                  rowsPerPage={rowsPerPage}
                  count={data.usersCount}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleRowsPerPage}
                  component="div"
                />
              </CardContent>
            </Card>
          </Grid>

          {/* <Grid item xs={12}>
            <Typography variant="h6" className={styles.label}>
              Invite Users
            </Typography>
            <Typography variant="subtitle2" className={styles.delete}>
              This will be replaced with an email field that will email the invite link out to users. For now copy the url into your browser
            </Typography>
            <Button onClick={handleClick} className={styles.button}>
              {url}
            </Button>
            <input className={styles.input} defaultValue={url} id="inputid" />
          </Grid> */}
        </Grid>
      </Container>

      <EditDialog
        open={open}
        handleClose={handleDialog(false)}
        user={selectedUser}
        form={form}
        resetForm={resetForm}
        formErrors={formErrors}
        handleChange={handleChange}
        handleToggleCheckBox={handleToggleCheckBox}
        handleSubmit={selectedUser ? handleEditSubmit : handleCreateSubmit}
        handleUploadImage={handleUploadImage}
        handleCreateUserCancel={handleCreateUserCancel}
        plans={currentYearPlans}
      />

      {/* Delete Dialog */}
      {selectedUser && (
        <Dialog
          open={deleteDialogOpen}
          onClose={(event, reason) => {
            if (reason !== "backdropClick") {
              setDeleteDialogOpen(false);
            }
          }}
        >
          <DialogTitle>Delete User</DialogTitle>
          <DialogContent>
            Are you sure you want to delete {selectedUser.name.first} {selectedUser.name.last}?
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={() => {
                setDeleteDialogOpen(false);
                setSelectedUser(null);
              }}
            >
              Cancel
            </Button>
            <Button color="primary" onClick={() => handleDelete(selectedUser)} variant="contained">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleDialog(false)();
          }
        }}
      >
        <DialogTitle>Register User</DialogTitle>
        <DialogContent>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="First Name"
            name="name.first"
            autoFocus
            value={form.name.first || ""}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Last Name"
            name="name.last"
            value={form.name.last || ""}
            onChange={handleChange}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Email Address"
            name="email"
            value={form.email || ""}
            onChange={handleChange}
            helperText={formErrors.email}
            error={Boolean(formErrors.email)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label="Title"
            name="position"
            value={form.position || ""}
            onChange={handleChange}
            helperText={formErrors.position}
            error={Boolean(formErrors.position)}
          />
          <SelectDepartment
            plans={_.get(data, "plans")}
            name="plan"
            handleChange={handleChange}
            value={form.plan}
            helperText="Which department does this user belong to?"
            multiple
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialog(false)}>Cancel</Button>
          <Button color="primary" onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default Users;

const GET_USERS_AND_DEPARTMENTS = gql`
  query Users_GetUsersDepts($organization: ID!, $offset: Int, $size: Int, $search: String) {
    users(organization: $organization, offset: $offset, size: $size, search: $search) {
      id
      name {
        first
        last
      }
      plan {
        id
        departmentName
        closed
        year
      }
      profilePicture
      position
      email
      auth
      notificationSettings {
        weeklyReminder {
          frequency
          enabled
        }
      }
    }

    plans(organization: $organization, category: "1 year", closed: false) {
      id
      departmentName
      year
    }

    usersCount(organization: $organization, search: $search)
  }
`;

const CREATE_USER = gql`
  mutation Users_CreateUser(
    $organization: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $position: String!
    $plan: [ID!]
    $auth: String
    $profilePicture: String
    $notificationSettings: NotificationsInput
  ) {
    createUser(
      organization: $organization
      firstName: $firstName
      lastName: $lastName
      email: $email
      position: $position
      plan: $plan
      password: $password
      auth: $auth
      profilePicture: $profilePicture
      notificationSettings: $notificationSettings
    ) {
      ok
      reason
    }
  }
`;

const UPDATE_USER = gql`
  mutation (
    $id: ID!
    $firstName: String
    $lastName: String
    $position: String
    $email: String
    $password: String
    $auth: String
    $profilePicture: String
    $plan: [ID!]
    $notificationSettings: NotificationsInput
  ) {
    updateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      position: $position
      email: $email
      password: $password
      auth: $auth
      profilePicture: $profilePicture
      plan: $plan
      notificationSettings: $notificationSettings
    )
  }
`;

const DELETE_USER = gql`
  mutation ($id: ID!) {
    deleteUser(id: $id)
  }
`;
