import React, { useState, useContext, useEffect } from "react";
import useForm from "../../hooks/useForm";
import _ from "lodash";
import styles from "./TheVision.module.scss";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  IconButton,
  InputAdornment,
  useMediaQuery,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SelectDepartment from "../../components/SelectDepartment/SelectDepartment";

const initForm = {
  plan: null,
  purpose: [],
  value: [],
  process: [],
  market: [],
  offering: [],
  valueProposition: [],
  differentiation: [],
  competency: [],
};

const AddCoreDialog = ({ id, open, handleClose, plans, departmentplanid }) => {

  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [coreCounter, setCoreCounter] = useState({
    purpose: 1,
    value: 1,
    process: 1,
    market: 1,
    offering: 1,
    valueProposition: 1,
    differentiation: 1,
    competency: 1,
  });
  const [createCore] = useMutation(CREATE_CORE);

  useEffect(() => {
    initForm.plan = departmentplanid;
  }, [departmentplanid]);


  const { form, handleChange, resetForm } = useForm({
    initForm,
  });

  const fs = useMediaQuery("(max-width: 600px)");

  const handleDeleteEntry = (name, value) => () => {
    const clonedForm = _.cloneDeep(form);
    const index = clonedForm[name].indexOf(value);

    if (index > -1) {
      clonedForm[name].splice(index, 1);
    }

    resetForm(clonedForm);

    if (coreCounter[name] > 1) {
      setCoreCounter({ ...coreCounter, [name]: coreCounter[name] - 1 });
    }
  };

  const handleSubmit = async () => {
    const ok = await createCore({ variables: { id, core: form } });
    if (ok.data.createCore) {
      snack("Created new department core");
      requestFetch();
      resetForm();
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      fullWidth
      fullScreen={fs}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
    >
      <DialogTitle>Create New Department Core</DialogTitle>
      <DialogContent>
        <SelectDepartment
          plans={plans}
          name="plan"
          handleChange={handleChange}
          value={form.plan}
          helperText="Which department does this core belong to?"
          showAll
        />
        {Array.from(Array(coreCounter.purpose), (_, i) => (
          <TextField
            key={i}
            label={`Core Purpose #${i + 1}`}
            name={`purpose[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.purpose[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.purpose - 1 ? "Why we exist" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("purpose", form.purpose[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton onClick={() => setCoreCounter({ ...coreCounter, purpose: coreCounter.purpose + 1 })}>
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.value), (_, i) => (
          <TextField
            key={i}
            label={`Core Value #${i + 1}`}
            name={`value[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.value[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.value - 1 ? "What we stand for" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("value", form.value[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton onClick={() => setCoreCounter({ ...coreCounter, value: coreCounter.value + 1 })}>
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.competency), (_, i) => (
          <TextField
            key={i}
            label={`Core Competency #${i + 1}`}
            name={`competency[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.competency[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.competency - 1 ? "What we do best" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("competency", form.competency[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton
          onClick={() =>
            setCoreCounter({
              ...coreCounter,
              competency: coreCounter.competency + 1,
            })
          }
        >
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.market), (_, i) => (
          <TextField
            key={i}
            label={`Core Market #${i + 1}`}
            name={`market[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.market[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.market - 1 ? "Who we serve" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("market", form.market[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton onClick={() => setCoreCounter({ ...coreCounter, market: coreCounter.market + 1 })}>
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.offering), (_, i) => (
          <TextField
            key={i}
            label={`Core Offering #${i + 1}`}
            name={`offering[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.offering[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.offering - 1 ? "What we offer" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("offering", form.offering[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton
          onClick={() =>
            setCoreCounter({
              ...coreCounter,
              offering: coreCounter.offering + 1,
            })
          }
        >
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.valueProposition), (_, i) => (
          <TextField
            key={i}
            label={`Core Value Proposition #${i + 1}`}
            name={`valueProposition[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.valueProposition[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.valueProposition - 1 ? "What our customers get" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("valueProposition", form.valueProposition[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton
          onClick={() =>
            setCoreCounter({
              ...coreCounter,
              valueProposition: coreCounter.valueProposition + 1,
            })
          }
        >
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.differentiation), (_, i) => (
          <TextField
            key={i}
            label={`Core Differentiators #${i + 1}`}
            name={`differentiation[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.differentiation[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.differentiation - 1 ? "What makes us unique" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("differentiation", form.differentiation[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton
          onClick={() =>
            setCoreCounter({
              ...coreCounter,
              differentiation: coreCounter.differentiation + 1,
            })
          }
        >
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.process), (_, i) => (
          <TextField
            key={i}
            label={`Core Process #${i + 1}`}
            name={`process[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.process[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.process - 1 ? "How we serve our customers" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("process", form.process[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton onClick={() => setCoreCounter({ ...coreCounter, process: coreCounter.process + 1 })}>
          <AddIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCoreDialog;

const CREATE_CORE = gql`
  mutation($id: ID!, $core: CoreInput) {
    createCore(id: $id, core: $core)
  }
`;
