import { MenuItem, Typography } from "@material-ui/core";
import Menu from "../../components/Menu/Menu";
import React from "react";
import styles from "./SelectCorpYear.module.scss";
import { setYear, year as yearUtil, fullDate, fiscalYearStart } from "../../utils/dates";
import _ from "lodash";

const SelectCorpYear = ({ corporatePlans, fiscalYear, corpForSelectedYear, setCorpForSelectedYear }) => {
  return (
    <div className={styles.flex}>
      <Typography variant="subtitle1">
        <span className={styles.label}>
          Year:{" "}
          {corpForSelectedYear.year &&
            `${fullDate(fiscalYearStart(setYear(fiscalYear, yearUtil(corpForSelectedYear.year))))} - ${fullDate(
              setYear(fiscalYear, yearUtil(corpForSelectedYear.year))
            )}`}
        </span>
      </Typography>

      <Menu icon="arrow">
        {_.sortBy(corporatePlans, (p) => parseInt(-p.year)).map((plan) => (
          <MenuItem
            key={plan.id}
            onClick={() => setCorpForSelectedYear(plan)}
            disabled={plan.id === corpForSelectedYear.id}
            selected={plan.id === corpForSelectedYear.id}
          >
            {fullDate(fiscalYearStart(setYear(fiscalYear, yearUtil(plan.year))))} - {fullDate(setYear(fiscalYear, yearUtil(plan.year)))}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default SelectCorpYear;
