import React, { useContext, useState } from "react";
import { Button, Container, TextField, Typography } from "@material-ui/core";

import styles from "./PasswordReset.module.scss";
import { useForm } from "../../hooks";
import { Link, useParams } from "react-router-dom";
import gql from "graphql-tag";
import { useMutation } from "@apollo/client";
import { SnackbarContext } from "../../context/snackbarContext";

const initForm = {
  newPassword: null,
  confirmNewPassword: null,
};
const initErrorForm = {
  newPassword: ["required", { type: "gte", target: 6 }],
  confirmNewPassword: ["required", { type: "gte", target: 6 }],
};

const PasswordReset = (props) => {
  const { id, token } = useParams();
  const [success, setSucess] = useState(false);
  const [linkInvalid, setLinkInvalid] = useState(false);

  const { snack } = useContext(SnackbarContext);

  const [resetPassword, { loading }] = useMutation(SET_PASSWORD);
  const [sendPasswordResetEmail, { loading: sendEmailLoading }] = useMutation(SEND_PASSWORD_RESET_EMAIL);

  const { form, formErrors, handleChange, validateForm } = useForm({
    initForm,
    initErrorForm,
  });

  const handleSubmit = async () => {
    try {
      if (!validateForm) {
        return;
      }
      const res = await resetPassword({
        variables: {
          id,
          token,
          password: form.confirmNewPassword,
        },
      });
      if (res.data.setUserPassword.ok) {
        setSucess(true);
        setLinkInvalid(false);
        snack("password is successfully updated");
      } else {
        setLinkInvalid(true);
        snack("Link used more than once or Link expired", "info");
      }
    } catch (e) {
      snack("Something went wrong, please try again later", "error");
    }
  };

  const handleResend = async () => {
    try {
      const ok = await sendPasswordResetEmail({ variables: { _id: id } });
      if (ok) {
        snack(`Updated password reset link sent to account email`);
      }
    } catch (e) {
      snack("Something went wrong, please try again later", "error");
    }
  };

  return (
    <Container maxWidth="sm">
      <div className={styles.logoContainer}>
        <img className={styles.logo} src="https://ventrek.com/img/brand/svg/logo-horizontal-colour.svg" alt="logo" />
      </div>

      {success ? (
        <Typography variant="subtitle1" color="textSecondary" align="center">
          Your password has been successfully changed! You can sign into your account with your new password.
        </Typography>
      ) : (
        <>
          <Typography variant="h6">Change password</Typography>
          <TextField
            label="New Password"
            name="newPassword"
            fullWidth
            type="password"
            variant="outlined"
            margin="normal"
            value={form.newPassword || ""}
            onChange={handleChange}
            helperText={formErrors.newPassword}
            error={Boolean(formErrors.newPassword)}
          />
          <TextField
            label="Repeat New Password"
            name="confirmNewPassword"
            fullWidth
            type="password"
            variant="outlined"
            margin="normal"
            value={form.confirmNewPassword || ""}
            onChange={handleChange}
            helperText={formErrors.confirmNewPassword}
            error={Boolean(formErrors.confirmNewPassword)}
          />
          <Button
            variant="contained"
            color="primary"
            disabled={
              form.newPassword !== form.confirmNewPassword ||
              (form.newPassword || "").length < 6 ||
              (form.confirmNewPassword || "").length < 6 ||
              loading
            }
            onClick={handleSubmit}
            className={styles.button}
          >
            Change
          </Button>
          {(form.newPassword || "").length >= 6 &&
            (form.confirmNewPassword || "").length >= 6 &&
            !loading &&
            form.newPassword !== form.confirmNewPassword && (
              <Typography component="span" className={styles.warning}>
                Passwords do not match
              </Typography>
            )}

          {linkInvalid && (
            <Button onClick={handleResend} disabled={sendEmailLoading} className={styles.button} style={{ marginLeft: "16px" }}>
              Resend password reset link
            </Button>
          )}
        </>
      )}
      <Typography component={Link} to="/" variant="subtitle1" color="primary" className={styles.link}>
        Back to Sign In
      </Typography>
    </Container>
  );
};

export default PasswordReset;

const SET_PASSWORD = gql`
  mutation ($id: ID!, $password: String!, $token: String) {
    setUserPassword(id: $id, password: $password, token: $token) {
      ok
      reason
    }
  }
`;

const SEND_PASSWORD_RESET_EMAIL = gql`
  mutation ($email: String, $_id: ID) {
    sendPasswordResetEmail(email: $email, _id: $_id)
  }
`;
